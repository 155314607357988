import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import projectBillingNoData from "../../../assets/agency/img/project-billing.jpg";
import AgencyModal from "../../../Components/Modals/AgencyModal";
import ProjectInvoiceType from "../../../Components/BillingsModule/ProjectInvoiceType";
import ProjectInvoiceSelect from "../../../Components/BillingsModule/ProjectInvoiceSelect";
import AuthUser from "../../../Services/AuthUser";
import ProjectInvoiceItem from "../../../Components/BillingsModule/ProjectInvoiceItem";
import Skeleton from "react-loading-skeleton";
import AgencySmModal from "../../../Components/Modals/AgencySmModal";
import questionIcon from '../../../assets/agency/img/question-icon.svg'
import BtnSpinner from "../../../Components/Spinners/BtnSpinner";
import successCheck from "../../../assets/agency/img/success-check.svg";
import { toast } from "react-toastify";
import { Button, Paper, Stack, Typography } from "@mui/material";
import FilterBilling from "../../../Components/BillingsModule/FilterBilling";
import moment from "moment";
import { InrSeprator ,UsdCadSeprator } from "../../../Config/Helper";
import InvoiceDueDate from "../../../Components/BillingsModule/InvoiceDueDate";
import AddBillingCustomerDetails from "../../../Components/Projects/AddBillingCustomerDetails";
import FilterInvoice from "../../../Components/BillingsModule/FilterInvoice";
import NoRecordsFound from "../../../Components/Pages/NoRecordsFound";
import {Box} from "@mui/material";

const ProjectsBilling = () => {
  const { http2, http } = AuthUser();
  let user = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(false);
  const [isKpiLoading, setIsKpiLoading] = useState(true);
  const [kpiData, setKpiData] = useState({
    current_month_billings_status:false,
    total_previous_month_billing:0,
    total_pending_billing:0,
    total_this_month_billing:0,
    total_this_month_billing_cad:0,
    total_this_month_billing_dollar:0,
    total_this_month_billing_rupees:0,
    total_pending_billing_cad:0,
    total_pending_billing_dollar:0,
    total_pending_billing_rupees:0,
    total_previous_month_billing_cad:0,
    total_previous_month_billing_dollar:0,
    total_previous_month_billing_rupees:0
  });
  const [reload, setReload] = useState(false);
  const [projectInvoices, setProjectInvoices] = useState([]);
  const [pendingInvoices, setPendingInvoices] = useState([]);
  const [paidInvoices, setPaidInvoices] = useState([]);
  const [selectedTab, setSelectedTab] = useState("All");
  const [projects, setProjects] = useState([]);
  const [selectedClientInfo, setSelectedClientInfo] = useState({
    openDueDate: false,
    duration: {},
    clientId: {}})
    const [serviceProjectData, setServiceProjectData] = useState({open:false,id:""});

  const [projectType, setProjectType] = useState(false);
  const openCreateInvoiceType = () => {
    setProjectType(true);
  };
  const [selectInvoiceProject, setSelectInvoiceProject] = useState(false);
  const openSelectInvoiceProject = () => {
    setProjectType(false);
    setSelectInvoiceProject(true);
  };

  // get project billings
  const getMyProjectBilling = (payload) => {
    setIsLoading(true);

    if(payload){
      // payload.month = parseInt(moment(payload.month).format("MM"));
      payload.due_date = payload?.due_date ? moment(payload.due_date).format("DD-MM-YYYYT00:00:00.000+00:00"):"";
      
      // if(payload.client_id.split("-")[1] == "yes"){
      //   payload.company_id = payload.client_id.split("-")[0];
      // }else{
      //   payload.project_id = payload.client_id.split("-")[0];
      // }
      
      // delete payload.client_id;
    }

    http2.post(`/project/invoice/list`,payload).then((res) => {
      let result = res.data.data;
      console.log(result.invoice_list);
      
      setProjectInvoices(result);
      const pendingInvoices = result.invoice_list.filter(invoice => invoice.status === "Pending");
      setPendingInvoices(pendingInvoices);
      const paidInvoices = result.invoice_list.filter(invoice => invoice.status === "Paid");
      setPaidInvoices(paidInvoices)
      setIsLoading(false); // stop loading spinner
    });

    http.post(`/invoices`)
        .then((response) => {
          let data = response.data.my_billings;
          setKpiData({
            current_month_billings_status: data?.current_month_billings_status,
            total_previous_month_billing: data?.total_previous_month_billing,
            total_pending_billing: data?.total_pending_billing,
            total_this_month_billing: data?.total_this_month_billing,
            total_this_month_billing_cad: data?.total_this_month_billing_cad || " -NA",
            total_this_month_billing_dollar: data?.total_this_month_billing_dollar || " -NA",
            total_this_month_billing_rupees: data?.total_this_month_billing_rupees || " -NA",
            total_pending_billing_cad: data?.total_pending_billing_cad || " -NA",
            total_pending_billing_dollar: data?.total_pending_billing_dollar || " -NA",
            total_pending_billing_rupees: data?.total_pending_billing_rupees || " -NA",
            total_previous_month_billing_cad: data?.total_previous_month_billing_cad || " -NA",
            total_previous_month_billing_dollar: data?.total_previous_month_billing_dollar || " -NA",
            total_previous_month_billing_rupees: data?.total_previous_month_billing_rupees || " -NA"
          })
          setIsKpiLoading(false)
        })
        .catch((error) => {
          let res = error.response.data;
          if (res.status === false) {
            toast.error(res.message);
          }
          else {
            toast.error("Internal Server Error");
          }
        });
  };

  const getMyProjects = () => {
    
    http2.get(`/get-project-client-list`).then((res) => {
      let result = res.data.data;
      setProjects(result);
      console.log(result,"resultres........................")
    });
  }

  const [openChangeBilling, setOpenchangeBilling] = useState(false);
  const [statusId, setStatusId] = useState(false);
  const changeStatusBilling = (statusItem) => {
    setOpenchangeBilling(true)
    setStatusId(statusItem)
  }
  const cancelStatusModal = () => {
    setOpenchangeBilling(false)
  }

  const [openSuccessBilling, setOpenSuccessBilling] = useState(false);
  const cancelSuccessModal = () => {
    setOpenSuccessBilling(false)
  }

  const changeTab = (tabName) => {
    setSelectedTab(tabName);
  }

  const changePaidStatus = () => {
    try {
      setIsLoading(true);
      setOpenchangeBilling(false)
      http2.post(`/change/project-billing/status/${statusId}`)
        .then((response) => {
          let result = response.data;
          if (result.success) {
            // toast.success(result.message);
            setIsLoading(false);
            setReload(true)
            setOpenSuccessBilling(true)
          }
          if (result.success === false) {
            toast.error(result.data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          let res = error.response.data;
          if (res.status === false) {
            toast.error(res.message);
          }
          else {
            toast.error("Internal Server Error");
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getMyProjects();
    getMyProjectBilling();
  }, [reload]);

  const clearFilter=()=>{
    setReload(false);
    getMyProjectBilling();
  }

  const servicesforInvoice = (values) => {
    console.log(values);
    let clientIdObj = {};
    if(values.client_id.split("-")[1] == "no"){
      clientIdObj = {
        project_id: values.client_id.split("-")[0]
      }
    }else{
      clientIdObj = {
        company_id: values.client_id.split("-")[0]
      }
    }

    setProjectType(false);
    setSelectedClientInfo({
      openDueDate: true,
      duration: {
        from_month: values.from_month,
        to_month: values.to_month
      },
      clientId: clientIdObj
    });
  };

  return (
    <>
      <Layout>
       

          <>
          
          {
          !isKpiLoading && ( kpiData?.current_month_billings_status === true ? (
            <>
              <Stack spacing="24px" pb="22px" direction="row" justifyContent="space-between">
                <Stack flex={1} sx={{ backgroundColor: "teritiary.teritiary5", p: "16px", borderRadius: "8px" }}>
                  <Typography variant="size3MediumCommonWhite">Previous Month's Billing</Typography>
                  <Typography textAlign="center" mb="12px" mt="12px" variant="size7_5BoldCommonWhite" className="dash-md-title dash-white-text fw-600">
                    {kpiData?.total_previous_month_billing ? (user.currency == "INR" ? "INR ₹" : (user.currency == "USD" ? "USD $" : "CAD $")) : ""}
                    {user.currency === "INR" 
                      ? (kpiData?.total_previous_month_billing ? InrSeprator(Math.round(kpiData.total_previous_month_billing)) : "NA")
                      : user.currency === "USD" || user.currency === "CAD"
                      ? (kpiData?.total_previous_month_billing ? UsdCadSeprator(Math.round(kpiData.total_previous_month_billing)) : "NA")
                      : "-"}
                  </Typography>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="size1_5SemiboldCommonWhite">USD ${kpiData.total_previous_month_billing_dollar || " -NA"}</Typography>
                    <Typography variant="size1_5SemiboldCommonWhite">CAD ${kpiData.total_previous_month_billing_cad || " -NA"}</Typography>
                    <Typography variant="size1_5SemiboldCommonWhite">INR ₹{kpiData.total_previous_month_billing_rupees || " -NA"}</Typography>
                  </Stack>
                </Stack>
                {/* This Month's Billing Stack */}
                <Stack flex={1} sx={{ backgroundColor: "teritiary.teritiary16", p: "16px", borderRadius: "8px" }}>
                  <Typography variant="size3MediumCommonWhite">This Month's Billing</Typography>
                  <Typography textAlign="center" mb="12px" mt="12px" variant="size7_5BoldCommonWhite">
                    {kpiData?.total_this_month_billing ? (user.currency == "INR" ? "INR ₹" : (user.currency == "USD" ? "USD $" : "CAD $")) : ""}
                    {user.currency === "INR" 
                      ? (kpiData?.total_this_month_billing ? InrSeprator(Math.round(kpiData.total_this_month_billing)) : "NA")
                      : user.currency === "USD" || user.currency === "CAD"
                      ? (kpiData?.total_this_month_billing ? UsdCadSeprator(Math.round(kpiData.total_this_month_billing)) : "NA")
                      : "-"}
                  </Typography>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="size1_5SemiboldCommonWhite">USD ${kpiData.total_this_month_billing_dollar || " -NA"}</Typography>
                    <Typography variant="size1_5SemiboldCommonWhite">CAD ${kpiData.total_this_month_billing_cad || " -NA"}</Typography>
                    <Typography variant="size1_5SemiboldCommonWhite">INR ₹{kpiData.total_this_month_billing_rupees || " -NA"}</Typography>
                  </Stack>
                </Stack>
                {/* Total Pending Billings Stack */}
                <Stack flex={1} sx={{ backgroundColor: "teritiary.teritiary17", p: "16px", borderRadius: "8px" }}>
                  <Typography variant="size3MediumCommonWhite">Total Pending Billings</Typography>
                  <Typography textAlign="center" mb="12px" mt="12px" variant="size7_5BoldCommonWhite" className="dash-md-title dash-white-text fw-600">
                    {kpiData?.total_pending_billing ? (user.currency == "INR" ? "INR ₹" : (user.currency == "USD" ? "USD $" : "CAD $")) : ""}
                    {user.currency === "INR" 
                      ? (kpiData?.total_pending_billing ? InrSeprator(Math.round(kpiData.total_pending_billing)) : "NA")
                      : user.currency === "USD" || user.currency === "CAD"
                      ? (kpiData?.total_pending_billing ? UsdCadSeprator(Math.round(kpiData.total_pending_billing)) : "NA")
                      : "-"}
                  </Typography>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="size1_5SemiboldCommonWhite">USD ${kpiData.total_pending_billing_dollar || " -NA"}</Typography>
                    <Typography variant="size1_5SemiboldCommonWhite">CAD ${kpiData.total_pending_billing_cad || " -NA"}</Typography>
                    <Typography variant="size1_5SemiboldCommonWhite">INR ₹{kpiData.total_pending_billing_rupees || " -NA"}</Typography>
                  </Stack>
                </Stack>
              </Stack>

              <div className="d-flex align-items-center justify-content-between adts-header">
                <div className="d-flex align-items-center ad-xxs-mb1">
                  <div style={{ display: "flex", marginTop: '14px', gap: '10px' }}>
                    <Button 
                      variant={selectedTab === "All" ? "Selected" : "NotSelected"} 
                      sx={{ textTransform: 'capitalize' }} 
                      onClick={() => changeTab("All")}
                    >
                      All ({projectInvoices?.invoice_count})
                    </Button>
                    <Button 
                      variant={selectedTab === "Pending" ? "Selected" : "NotSelected"} 
                      sx={{ textTransform: 'capitalize' }} 
                      onClick={() => changeTab("Pending")}
                    >
                      Pending ({pendingInvoices.length})
                    </Button>
                    <Button 
                      variant={selectedTab === "Paid" ? "Selected" : "NotSelected"} 
                      sx={{ textTransform: 'capitalize' }} 
                      onClick={() => changeTab("Paid")}
                    >
                      Paid ({paidInvoices.length})
                    </Button>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <>
                    <FilterInvoice
                      projects={projects}
                      isBilling={false}
                      filterData={getMyProjectBilling}
                      clearFilter={clearFilter}
                    />
                    <div>
                      <button className="btn ad-dropdown-btn" type="button" onClick={openCreateInvoiceType}>
                        + &nbsp;Create Invoice
                      </button>
                    </div>
                  </>
                </div>
              </div>

              {projectInvoices.invoice_count > 0 ? (
                <div>
                  {isLoading && <Skeleton containerClassName="cs-skeleton" count={5} height={80} />}
                  {projectInvoices?.invoice_count > 0 ? (
                    <div className="ad-team-flex mt-3">
                      <div className="ad-team-summary">
                        <div className="adts-body">
                          <div className="adp-list">
                            <div className="adp-list-width">
                              {selectedTab === "All" && projectInvoices.invoice_list.map((item) => (
                                <ProjectInvoiceItem key={item.id} item={item} changeStatusBilling={changeStatusBilling} />
                              ))}
                              {selectedTab === "Pending" && pendingInvoices.map((item) => (
                                <ProjectInvoiceItem key={item.id} item={item} changeStatusBilling={changeStatusBilling} />
                              ))}
                              {selectedTab === "Paid" && paidInvoices.map((item) => (
                                <ProjectInvoiceItem key={item.id} item={item} changeStatusBilling={changeStatusBilling} />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : ""}
                </div>
              ) : (!isLoading && <NoRecordsFound />)}
              
            </>
          ) : (
            <>
              <div className="no-data-billing position-relative">
                <img src={projectBillingNoData} alt="no data" />
                <div className="fade-bg">
                  <div className="ad-white-card ndb-box px-3 py-5">
                    <div className="content ad-gray-text text-center mb-4">
                      <p>Start invoicing your clients by clicking the button below</p>
                    </div>
                    <div className="text-center">
                      <button className="ad-add-team ad-theme-btn ad-theme-btn ad-xs-btn" type="button" onClick={openCreateInvoiceType}>
                        + &nbsp;Create Invoice
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}


          </>
 
        <AgencyModal open={projectType} clsSm={true}>
          <ProjectInvoiceType
            setProjectType={setProjectType}
            clientsObj={projects}
            openSelectInvoiceProject={openSelectInvoiceProject}
            setServicesData={(values)=>{servicesforInvoice(values)}}
            setProjectBillingData={(id)=>{setServiceProjectData({open:true,id:id})}}
          />
        </AgencyModal>
        <AgencyModal open={selectInvoiceProject}>
          <ProjectInvoiceSelect
            setSelectInvoiceProject={setSelectInvoiceProject}
          />
        </AgencyModal>
        <AgencySmModal open={openChangeBilling} close={cancelStatusModal}>
          <div className="text-center">
            <img src={questionIcon} alt="question" />
          </div>
          <div className="mt-3 ad-md-title text-center">Are you sure?</div>
          <div className="mt-2 content text-center">
            <p className="ad-lgray-text">You want to change status to paid</p>
          </div>
          <div className="mt-4 text-center row">
            <div className="col-6">
              <button type="button" className="ad-gray-btn ad-sm-btn w-100" onClick={cancelStatusModal}>Cancel</button>
            </div>
            <div className="col-6">
              {isLoading ?
                <BtnSpinner className="ad-theme-btn ad-sm-btn w-100" /> :
                <button type="button" className="ad-theme-btn ad-sm-btn w-100" onClick={changePaidStatus}>Yes</button>
              }
            </div>
          </div>
        </AgencySmModal>
        <AgencySmModal open={openSuccessBilling} close={cancelSuccessModal}>
          <div className="text-center">
            <img src={successCheck} alt="question" />
          </div>
          <div className="mt-3 ad-md-title text-center">Success!</div>
          <div className="mt-1 content text-center mb-3">
            <p>The Invoice status updated successfully.</p>
          </div>
          <div className="text-center">
            <button type="button" className="ad-theme-btn ad-sm-btn" onClick={cancelSuccessModal}>Ok, got it!</button>
          </div>
        </AgencySmModal>
        {console.log(selectedClientInfo.openInvoiceDue)}
        <AgencyModal open={selectedClientInfo.openDueDate} clsSm={true}>
          <InvoiceDueDate
            setOpenInvoiceDue={(status) => {
              setSelectedClientInfo({
                ...selectedClientInfo,
                openDueDate: status
              })
            }}
            selectedTalents={[]}
            selectedClientId={selectedClientInfo.clientId}
            invoice_type="services"
            month={undefined}
            selectedClient={selectedClientInfo.duration}
          />
        </AgencyModal>
        <AgencyModal open={serviceProjectData.open}>
          <AddBillingCustomerDetails
            projectId={serviceProjectData.id}
            setBillingCustomerInfo={(type)=>{setServiceProjectData({...serviceProjectData,open:type})}}
            setReload={() => { setReload(true) }}
          />
        </AgencyModal>
      </Layout>
    </>
  );
};

export default ProjectsBilling;
