import { useEffect, useState } from "react";
import invoiceLogo from "../../../assets/dashboard/img/weteaminvoice-logo.png";
import { useParams } from "react-router-dom";
import AuthUser from "../../../Services/AuthUser";
import paidImg from "../../../assets/dashboard/img/paid-tag.svg";
import { ToastContainer } from "react-toastify";
import RaiseIssueModal from "../../../Components/Billings/RaiseIssueModal";
import Currency from "../../../Components/Currency/Currency";
import SassCurrency from "../../../Components/Currency/SassCurrency";
import locationIcon from '../../../assets/agency/img/maps-and-flags.png'
import MyIcon from "../../../assets/dashboard/img/weteamLogo.png";
import { Box } from "@mui/material";
import {Typography} from "@mui/material";
let base_URL = process.env.REACT_APP_base_URL;

const ViewProjectBilling = () => {
    const params = useParams();
  const { id } = params;
  const [myInvoice, setMyInvoice] = useState({});
  const [subscriber, setSubscriber] = useState({});
  const { http2,http } = AuthUser();

  let user = localStorage.getItem("user");
  user = JSON.parse(user);
  let { currency } = user;


  let subscriberCompany = subscriber?.company

  const capitalize = (arr) => {
    //return str.charAt(0).toUpperCase() + str.slice(1);
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr;
  };
  const getMyInvoice = () => {
    //setIsLoading(true);
    const payload = id
    http2.get(`/my-project-invoice/${payload}`).then((res) => {
      let result = res.data.data;
      console.log(result);
      setMyInvoice(result);
      //setIsLoading(false); // stop loading spinner
    });
  };
  const me = () => {
    //setIsLoading(true);
    http.get(`/me`).then((res) => {
      let result = res.data.data;
      setSubscriber(result);
      //setIsLoading(false); // stop loading spinner
    });
  };

  useEffect(() => {
    me();
    getMyInvoice();
  }, []);

  var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
  var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];


  function inWords(num) {
    console.log("num",num);
    if ((num = num.toString()).length > 9) return 'overflow';
    let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
    return str;
}

  return (
    <>
      <ToastContainer limit={1} hideProgressBar={true} />
      <div className="pdf-view-wrap">
        <div className="container">
          <div className="pdf-view-responsive">
            <div className="dashwhite-box position-relative">
              {myInvoice.status === "Paid" ? (
                <>
                  <div className="bill-paid-overflow"></div>
                  <div className="bill-paid">
                    <img src={paidImg} alt="paid tag" />
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="d-flex align-items-center invoice-block-top">
                <div className="ibt-left">
                  <img src={myInvoice?.sender_company_logo || ""} alt="invoiceLogo" />
                </div>
                <div className="ibt-right ml-auto">
                  <div className="dash-md-title fw-500 dash-dgray-text dash-font-1em">
                    
                    <div className="d-flex">
                      <div className="inprev-mnw-address">
                        <img
                          className="inprev-info-icon inprev-location-icon"
                          src={locationIcon}
                          alt="address"
                        /> &nbsp;&nbsp;&nbsp;
                      </div>
                      <div>{myInvoice?.client_bank_beneficiary_address || "-"}</div>
                    </div>
                  </div>
                  <div className="mt-1 d-flex ibt-info align-items-center flex-wrap">
                    <div className="w-50 pr-15">
                      <a href="#">
                        <span className="env-icon"></span> {myInvoice.subscriber_billing_email || '-'}
                      </a>
                    </div>
                    <div style={{padding:"15px", marginLeft:"40px"}}>
                    <div className="w-50">
                      <a href="#">
                        <span className="phn-icon"></span> {myInvoice.client_phone_no || '-'}
                      </a>
                    </div>
                    </div>
                    <div className="w-100 mt-0">
                      <a href="#">
                        <span className="web-icon"></span>{" "}
                        {myInvoice.client_website || '-'}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="invoice-block-mid mt-20">
                {
                  (subscriberCompany?.gst_no || myInvoice?.sender_gst) &&
                  <div className="text-center">
                  <div className="dash-lblue-bg gstin-text pd-12-48 d-inline-block">
                    GSTIN: { myInvoice?.sender_gst || '-'}
                  </div>
                </div>
                }

                <div className="dash-lblue-bg pd-12-48 text-center mt-10">
                  <div className="fw-700 dash-dpurple-text font-1em">
                  
                    {myInvoice.project_currency === "INR"
                      ? "INVOICE"
                      : "EXPORT (SERVICES) INVOICE"}
                    <div>{myInvoice?.lut_no && `LUT Number : ${myInvoice.lut_no}`}</div>
                  </div>
                  {myInvoice.project_currency === "INR" ? (
                    ""
                  ) : (
                    <div className="mt-0 fw-600 dash-lpurple-text dash-font-0-9em">
                      Services Meant For Export Letter of Undertaking Without
                      Payment Of Intergated Tax(IGST){" "}
                    </div>
                  )}
                </div>
              </div>
              <div className="invoice-block-info mt-10">
                <div className="row">
                  <div className="col-md-7">
                    <div className="dash-sm-title fw-600 text-uppercase">
                      Customer Details
                    </div>
                    <div className="invoice-info-table mt-10">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <th>Customer Name</th>
                            <td>{myInvoice.project_billing_customer_name ?? '-'}</td>
                          </tr>
                          <tr>
                            <th className="align-top">Address</th>
                            <td className="align-top">
                              {myInvoice.project_billing_address || ''}
                              <br />
                              {myInvoice.full_address &&
                                (myInvoice.full_address
                                  .authority_company_address_line2 || "") +
                                  " " +
                                  (myInvoice.city_name || "")}
                            </td>
                          </tr>
                          <tr>
                            <th>State</th>
                            <td>
                              {(myInvoice.project_billing_state && myInvoice.project_billing_state) ||
                                ""}
                            </td>
                          </tr>
                          <tr>
                            <th>Country</th>
                            <td>{myInvoice.project_billing_country || ""}</td>
                          </tr>
                          {myInvoice.gst_no ? (
                            <>
                              <tr>
                                <th>GST Number</th>
                                <td>{myInvoice.gst_no || ""}</td>
                              </tr>
                            </>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="dash-sm-title fw-600 text-uppercase">
                      Invoice Details
                    </div>
                    <div className="invoice-info-table mt-10">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <th>Invoice number:</th>
                            <td>{myInvoice.invoice_number}</td>
                          </tr>
                          <tr>
                            <th>Invoice Date:</th>
                            <td>{myInvoice.invoice_date}</td>
                          </tr>
                          <tr>
                            <th>Due Date:</th>
                            <td>{myInvoice.due_date}</td>
                          </tr>
                          <tr>
                            <th>Reverse charge (Y/N):</th>
                            <td>NO</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="invoice-dtl-table invoice-responsive-table mt-10">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th className="text-center text-nowrap">S. No</th>
                      <th width={"25%"}>Description</th>
                      <th>SAC Code</th>
                      <th>Utilized Hours</th>
                      <th>Rate/Hr</th>
                      <th className="text-end">
                        Amount ({myInvoice.project_currency})
                      </th>
                      <th className="text-end">Taxable Value</th>
                      <th className="text-end">Tax Rate</th>
                      <th className="text-end">GST</th>
                      <th className="text-end">
                        TOTAL ({myInvoice.project_currency})
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {myInvoice.billing_talents &&
                      myInvoice.billing_talents.map((talent, index) => (
                        <tr key={index + 1}>
                          <td className="font-1em text-center">{index + 1}</td>
                          <td>
                            {talent.name}{" "}
                            {talent.role ? "(" + talent.role + ")" : ""}
                          </td>
                          <td>{talent.sac_code || '998313'}</td>
                          <td>
                            {talent.kickoff
                              ? talent.reserved_hours || "-"
                              : talent.reserved_hours || "-"}
                          </td>
                          <td>{talent.rate_per_hour || "-"}</td>
                          <td className="text-end">
                          {myInvoice?.project_currency === "INR" ? ' ₹' : ' $'}
                           {talent?.actual_billing || '-'}
                          </td>
                          <td className="text-end">
                          {myInvoice?.project_currency === "INR" ? ' ₹' : ' $'}
                           {talent?.actual_billing || '-'}
                          </td>
                          <td className="text-end">
                            {" "}
                            {myInvoice?.project_currency === "INR" ? "18%" : 0}
                          </td>
                          <td className="text-end text-nowrap">
                            {myInvoice?.project_currency !== "INR" ? (
                              "--"
                            ) : 
                              myInvoice?.project_currency === "INR" ?   `₹ ${talent?.gst || '-'}` : '--'
                              
                            }
                          </td>
                          <td className="text-end">
                          {myInvoice?.project_currency === "INR" ? ' ₹' : ' $'}
                          {myInvoice?.project_currency === "INR" ? talent.total_billing : talent.actual_billing}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                      {/* {console.log("myInvoice",myInvoice.billing_talents.reduce((sum,val)=>{
                        console.log(sum,val);
                    return {
                      actual_billing:val.actual_billing + sum.actual_billing,
                      taxable_value:val.actual_billing + sum.actual_billing,
                      gst: val.gst + sum.gst,
                      total_billing:parseFloat(val.total_billing) + parseFloat(sum.total_billing),
                      
                    }
                  },{
                    actual_billing:0,
                    taxable_value:0,
                    gst: 0,
                    total_billing:0,
                    
                  }))} */}
                  {
                  myInvoice.billing_talents &&
                  [myInvoice.billing_talents.reduce((sum,val)=>{
                    return {
                      actual_billing:val.actual_billing + sum.actual_billing,
                      taxable_value:val.actual_billing + sum.actual_billing,
                      gst: val.gst + sum.gst,
                      total_billing:parseFloat(val.total_billing) + parseFloat(sum.total_billing),
                      
                    }
                  },{
                    actual_billing:0,
                    taxable_value:0,
                    gst: 0,
                    total_billing:0,
                    
                  })].map((talent)=>{
                    return (
                      <tbody>
                    <tr className="idt-total">
                      <td className="font-1em">&nbsp;</td>
                      <td className="dash-font-0-9em fw-600 text-start">
                        Total
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td className="text-end dash-font-0-9em fw-600">
                      {myInvoice?.project_currency === "INR" ? ' ₹' : ' $'}
                          {talent?.actual_billing || '-'}
                      </td>
                      <td className="text-end dash-font-0-9em fw-600">
                          {myInvoice?.project_currency === "INR" ? ' ₹' : ' $'}
                            {talent?.actual_billing || '-'}
                      </td>
                      <td className="text-end dash-font-0-9em fw-600"></td>
                      <td className="text-end dash-font-0-9em fw-600 text-nowrap">
                        {currency !== "INR" ? (
                          ""
                        ) : (
                          <Currency
                            removeCurrencyType={true}
                            amount={myInvoice?.project_currency === "INR" ?   `${(talent?.gst).toFixed(2) || '-'}` : '--'}
                          />
                        )}
                      </td>
                      <td className="text-end dash-font-0-9em fw-600">
                          {myInvoice?.project_currency === "INR" ? ' ₹' : ' $'}
                          {Math.round(myInvoice?.total_taxable_amount)}
                      </td>
                    </tr>
                  </tbody>
                    )
                  })
                         
                }

                  {/* {myInvoice.billing_talents &&
                      myInvoice.billing_talents.map((talent, index) => (
                        <tbody>
                    <tr className="idt-total">
                      <td className="font-1em">&nbsp;</td>
                      <td className="dash-font-0-9em fw-600 text-start">
                        Total
                      </td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td className="text-end dash-font-0-9em fw-600">
                      {myInvoice?.project_currency === "INR" ? ' ₹' : ' $'}
                          {talent?.actual_billing || '-'}
                      </td>
                      <td className="text-end dash-font-0-9em fw-600">
                          {myInvoice?.project_currency === "INR" ? ' ₹' : ' $'}
                            {talent?.actual_billing || '-'}
                      </td>
                      <td className="text-end dash-font-0-9em fw-600"></td>
                      <td className="text-end dash-font-0-9em fw-600 text-nowrap">
                        {currency !== "INR" ? (
                          ""
                        ) : (
                          <Currency
                            removeCurrencyType={true}
                            amount={myInvoice?.project_currency === "INR" ?   `${talent?.gst || '-'}` : '--'}
                          />
                        )}
                      </td>
                      <td className="text-end dash-font-0-9em fw-600">
                          {myInvoice?.project_currency === "INR" ? ' ₹' : ' $'}
                          {myInvoice?.total_amount}
                      </td>
                    </tr>
                  </tbody>
                        
                      ))} */}
                  
                </table>
              </div>

              {/* {myInvoice?.project_currency === "INR" &&
                // myInvoice.billing_talents.map((talent, index) => (
                <div className="invoice-dtl-table invoice-responsive-table gst-table">
                  <table className="w-100">
                    <thead>
                      <tr>
                        <th className="text-center" style={{ width: "30%" }}>
                          SAC
                        </th>
                        <th className="text-center" style={{ width: "20%" }}>
                          Taxable Value <br />({myInvoice?.project_currency})
                        </th>
                        <th style={{ padding: "0px", width: "35%" }}>
                          <table className="w-100">
                            <thead>
                              <tr>
                                <th
                                  style={{ width: "100%" }}
                                  colSpan="3"
                                  className="text-center gst-border-b"
                                >
                                  GST ({myInvoice?.project_currency})
                                </th>
                              </tr>
                              <tr>
                                <th
                                  className="text-center"
                                  style={{ width: "33.33%" }}
                                >
                                  SGST
                                </th>
                                <th
                                  className="text-center"
                                  style={{ width: "33.33%" }}
                                >
                                  CGST
                                </th>
                                <th
                                  className="text-center"
                                  style={{ width: "33.33%" }}
                                >
                                  IGST
                                </th>
                              </tr>
                            </thead>
                          </table>
                        </th>
                        <th className="text-end" style={{ width: "15%" }}>
                          Total <br />
                          Tax Value
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {myInvoice.billing_talents &&
                        myInvoice.billing_talents.map((gstdtl, index) => (
                          <tr key={gstdtl.talent_id}>
                            <td
                              className="text-center"
                              style={{ width: "30%" }}
                            >
                              {gstdtl.sac_code || '998313'}
                            </td>
                            <td
                              className="text-center"
                              style={{ width: "20%" }}
                            >
                              ₹{gstdtl?.actual_billing || '-'}
                            </td>
                            <td style={{ padding: "0px", width: "35%" }}>
                              <table className="w-100">
                                <tbody>
                                  <tr>
                                    <td
                                      style={{ width: "33.33%" }}
                                      className="text-center"
                                    >
                                      {console.log("gstdtl?.project_billing_state",myInvoice?.project_billing_state,subscriberCompany?.state?.name)}
                                      {myInvoice?.project_billing_state == subscriberCompany?.state?.name ? '9%' : '--'}
                                    </td>
                                    <td
                                      style={{ width: "33.33%" }}
                                      className="text-center"
                                    >
                                      {myInvoice?.project_billing_state == subscriberCompany?.state?.name ? '9%' : '--'}
                                    </td>
                                    <td
                                      style={{ width: "33.33%" }}
                                      className="text-center"
                                    >
                                     {myInvoice?.project_billing_state != subscriberCompany?.state?.name ? '18%' : '--'}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td className="text-end" style={{ width: "15%" }}>
                           
                            {myInvoice?.project_currency === "INR" ?   ` ₹ ${gstdtl?.gst || '-'}` : '--'}
                            </td>
                          </tr>
                        ))}
                      <tr className="gst-total">
                        <th className="text-end">Total</th>
                        <td className="text-center">
                        {myInvoice?.project_currency === "INR" ? ' ₹' : `${myInvoice?.project_currency}`+' $'}
                            {myInvoice?.total_amount}
                        </td>
                        <td style={{ padding: "0px" }}></td>
                        <td className="text-end">
                          {myInvoice?.project_currency === "INR" ? ' ₹' : `${myInvoice?.project_currency}` + ' $'}
                          {myInvoice?.billing_talents ?
                            myInvoice.billing_talents.reduce((sum, val) => {
                              return sum + val.gst;
                            }, 0).toFixed(2)
                            :
                            0
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              // ))
              }  */}

              <div className="invoice-block-info dash-total-amount invoice-border-b mt-20">
                <div className="row">
                  <div className="col-md-7">
                    <div className="dash-sm-title fw-600 text-uppercase dash-gray-text">
                      Total invoice amount in words
                    </div>
                    <div style={{textTransform:"capitalize"}}className="dash-sm-title fw-600 dash-black-text mt-10 dash-font-0-9em">
                      {
                        myInvoice.total_taxable_amount && (inWords(Math.round((parseFloat(myInvoice.total_taxable_amount)))).includes("only")?inWords(Math.round((parseFloat(myInvoice.total_taxable_amount)))):inWords(Math.round((parseFloat(myInvoice.total_taxable_amount))))+" only")
                        // numberToEnglish(myInvoice.total_amount).replace(
                        //   /(^\w{1})|(\s+\w{1})/g,
                        //   (letter) => letter.toUpperCase()
                      }
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="invoice-dtl-table">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <th className="fw-600 w-65 text-center">
                              Total amount before TAX
                            </th>
                            <td className="fw-600 w-35 text-end">
                            {myInvoice?.project_currency === "INR" ? ' ₹' : `${myInvoice?.project_currency}`+' $'}
                              {myInvoice?.billing_talents ?
                                myInvoice.billing_talents.reduce((sum, val) => {
                                  return sum + val.actual_billing;
                                }, 0).toFixed(2)
                                :
                                0
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="invoice-dtl-table mt-10">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <th className="fw-600 w-65 text-center">
                              Total Payable Amount
                            </th>
                            <td className="fw-600 w-35 text-end">

                            {myInvoice?.project_currency === "INR" ? ' ₹' : `${myInvoice?.project_currency}`+' $'}
                            {myInvoice?.total_taxable_amount ? Math.round(myInvoice.total_taxable_amount):"-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="invoice-block-info dash-bank-dtl-block invoice-border-b mt-10 pb-2">
                <div className="row">
                  <div className="col-md-5 order-md-2">
                    <div className="invoice-dtl-table">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <th className="fw-600 w-65 text-center">
                              GST on reverse charge
                            </th>
                            <td className="fw-600 w-35 text-end">
                              {myInvoice?.project_currency === "INR" ? ' ₹' : ' $'} 0
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="text-center fw-400 dash-font-0-8em dash-gray-text mt-6">
                      Ceritified that the particulars given above are true and
                      correct
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="dash-sm-title fw-600 text-uppercase">
                      Bank Details
                    </div>
                    <div className="invoice-info-table invoice-bank-info-table mt-10">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <th className="w-40">Beneficiary Name</th>
                            <td className="w-60">{myInvoice.client_bank_beneficiary_name || '-'}</td>
                          </tr>
                          <tr>
                            <th className="w-40 pt-1 align-top">
                              Beneficiary Address
                            </th>
                            <td className="w-60 pb-1 align-top">
                            {myInvoice?.client_bank_beneficiary_address || "-"}
                            </td>
                          </tr>
                          <tr>
                            <th className="w-40">Beneficiary Account #</th>
                            <td className="w-60">{myInvoice.client_bank_beneficiary_account || '-'}</td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <th className="w-40">Beneficiary Bank Name</th>
                            <td className="w-60">{myInvoice.client_bank_beneficiary_name || '-'}</td>
                          </tr>
                          <tr>
                            <th className="w-40">
                              Beneficiary Bank ID / SWIFT Code
                            </th>
                            <td className="w-60">{myInvoice.client_bank_beneficiary_bank_id || '-'}</td>
                          </tr>
                          <tr>
                            <th className="w-40">Beneficiary Bank IFSC Code</th>
                            <td className="w-60">{myInvoice.client_bank_beneficiary_ifsc_code || '-'}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="invoice-block-bottom mt-20 pb-2">
                <div className="fw-600 dash-font-0-9em dash-dgray-text mt-6 text-end">
                  *This is a computer generated invoice. Signature is not
                  required.
                </div>

                {/* <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '13px', fontWeight: 600, marginRight: '7px', position: 'relative' }}>
                  Powered By
                </Typography>
                <div>
                  <img src={MyIcon} style={{ height: '44px', width: '175px' }} alt="Icon" />
                </div>
              </Box> */}

                <Box sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 600, position:"absolute" , marginTop:"-2px", marginRight:"10px" }}>
                  Powered By
                </Typography>
                <div>
                  <img src={MyIcon} style={{ height: '57px', width: '218px' }} alt="Icon" />
                </div>
              </Box>



                {/* <div className="row">
              <div className="col-md-6">
                <div className="ibb-blank-box"></div>
                <div className="text-start fw-600 dash-font-0-9em dash-dgray-text mt-6 text-uppercase">
                  Common Seal
                </div>
              </div>
              <div className="col-md-6">
                <div className="ibb-blank-box">
                  <img src={authSignatory} alt="auth Signatory" />
                </div>
                <div className="text-start fw-600 dash-font-0-9em dash-dgray-text mt-6 text-uppercase">
                  Authorised Signatory (For weteams pvt. ltd.{" "}
                </div>
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewProjectBilling