import { BrowserRouter } from "react-router-dom";
import "../src/assets/css/style.css";
import "../src/assets/css/custom.css";
import "../src/assets/css/responsive.css";
import AppRoutes from "./Routes/AppRoutes";
// import DashboardRoutes from './Routes/DashboardRoutes'
import { PermissionProvider } from "./Contexts/PermissionContext";
/** MUI Theme provider import for the complete application */
import { ThemeProvider } from '@mui/material/styles';
/**  MUI theme object import */
import theme from './assets/mui/theme';
import CssBaseline from '@mui/material/CssBaseline';
import Layout from "./Pages/Dashboard/NewLayout";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="App">
      {/** MUI theme provider with theme config object*/}
      <ThemeProvider theme={theme}>
      <ToastContainer hideProgressBar={true} />
        {/* <CssBaseline /> */}
        <BrowserRouter>
          <PermissionProvider>
            {/* <Layout> */}
              <AppRoutes />
            {/* </Layout> */}
          </PermissionProvider>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
