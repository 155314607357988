import React, { useEffect, useState } from 'react'
import Layout from '../Layout'
import { Link, useParams } from 'react-router-dom';
import AuthUser from '../../../Services/AuthUser';
import DetailOverview from '../../../Components/Leads/DetailOverview';
import LeadMyTeamFilter from '../../../Components/Leads/LeadMyTeamFilter';
import LeadMatchTalent from '../../../Components/Leads/LeadMatchTalent';

const LeadDetails = () => {
    const params = useParams();
    const { id } = params;
    const { http2 } = AuthUser();
    const [reload, setReload] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [leadDetailItem, setLeadDetailItem] = useState("");
    const [leadDetailData, setLeadDetailData] = useState("");
    const [goodMatch, setGoodMatch] = useState("");
    const [mydata,setMydata] = useState("");
    const [topMatch, setTopMatch] = useState("");
    const [clearVal , setclearVal]  = useState(false);

    const [searchBy, setSearchBy] = useState("top-match");
    // console.log(searchBy)
    const [is_search, setIsSearch] = useState(false);
    const [filterData, setFilterData] = useState("");

    
    const getSearchData = (serarchItem) => {
        setFilterData(serarchItem);
        getLeadList(serarchItem);
    }
    const getLeadList = (filter) => {
        setIsLoading(true);
        let params = { 
            hiring_id: id, 
            search_type: searchBy,
            is_search: filter ? true:false,
            keyword :filter?.keyword || "",
            experience :filter?.experience || "",
            role_id :filter?.role_id || "",
            skills :filter?.skills || "",
            is_clear: clearVal ,
        };
        http2.post(`/leads-details`, params).then((res) => {
            try {
                let result = res.data.data;
                setMydata(result.data.matched_talent_rates);
                setGoodMatch(result.good_match)
                setTopMatch(result.top_match)
                console.log(result);
                setLeadDetailData(result.data);
                // setLeadDetailItem(result);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
            
        });
    };

    // Role 
    
    const [roleData, setRoleData] = useState("")
    const rolesList = () => {
      // setIsLoading(true);
      http2.get(`/roles/list`).then((res) => {
        let result = res.data.data;
        setRoleData(result);
      });
    };
    useEffect(() => {
        getLeadList();
        setReload();
        rolesList()
    }, [reload])
    return (
        <>
            <Layout>
                <div className='ad-white-card p-0'>
                    <DetailOverview 
                        leadDetailData={leadDetailData}
                        isLoading={isLoading}
                    />
                    <div className='lead-my-team-box p-3'>
                        <div className='ad-md-title dash-font-1em ad-gray-text mb-3'>My Team</div>
                        <LeadMyTeamFilter 
                            roleData={roleData}
                            setclearVal={setclearVal}
                            clearVal={clearVal}
                            leadDetailData={leadDetailData}
                            // setIsSearch={setIsSearch}
                            getSearchData={(val)=>{
                                getSearchData(val);
                            }}
                            isLoading={isLoading}
                        />
                       <LeadMatchTalent
                            key={(filterData?.keyword || "emptyfilter")+String(Math.random()+Math.random())}
                            updated_data={mydata}
                            goodMatch={goodMatch}
                            topMatch={topMatch}
                            setSearchBy={setSearchBy}
                            required_skill_ids={leadDetailData?.required_skill_ids}
                            isLoading={isLoading}
                            setReload={setReload}
                       />
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default LeadDetails