import React, { useState, useCallback } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Avatar,
  IconButton,
  InputAdornment,
  SvgIcon,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  useTheme,
  Stack,
} from "@mui/material";
import Wordfileicon from "../../assets/dashboard/img/WordFile.svg";
import Pdffileicon from "../../assets/dashboard/img/Document.svg";
import { ReactComponent as CurrencyRupeeIcon } from "../../assets/dashboard/img/Paisa.svg";
import Cross from "../../assets/dashboard/img/FixFrame.svg";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import Threedots from "../../assets/dashboard/img/dotsvertical.svg";
import { ReactComponent as Frame } from "../../assets/dashboard/img/Frame.svg";
import Download from "../../assets/dashboard/img/download_gray.svg";
import { ReactComponent as Eye } from "../../assets/dashboard/img/newEye.svg";
import { ReactComponent as Close } from "../../assets/dashboard/img/close-tab.svg";
import { useApiService } from "../../Services/ApiInstance";
import { ReactComponent as CalenderIcon } from "../../assets/dashboard/img/calender-dynamic.svg";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Currency from "../Currency/Currency";
import moment from "moment";
import { useParams } from "react-router-dom";
import AgencySmModal from "../Modals/AgencySmModal";
import exclamation from "../../assets/agency/img/exclamation-icon.svg";
import Downarraow from "../../assets/dashboard/img/down_arrow_icon.svg";
import {ReactComponent as Deleteicon} from "../../assets/dashboard/img/delete_icon_dynamic.svg";
import BtnSpinner from "../Spinners/BtnSpinner";

const ContractCreation = ({ handleCLose, datePrefill, success, hiringSide,engagementType, contractStatus, contractDuration, talent_rate }) => {

  const params = useParams();

  let {
    role,
    hiring_id,
    contract_details,
    color_code
  } = datePrefill;




  let work_start_date = datePrefill?.talent_reserved_hours_details ?
            datePrefill?.talent_reserved_hours_details.work_start_date
            :
            datePrefill.work_started_at;
  let company_name = datePrefill?.company_name ? datePrefill.company_name:"-";   
  
  let user = JSON.parse(localStorage.getItem("user"));
  let rate_per_hour = datePrefill?.contract_details?.[0]? datePrefill.contract_details[0].rate_per_hour : datePrefill.rate_per_hour;
  let client_reserved_hours = datePrefill?.contract_details?.[0]? datePrefill.contract_details[0].contribute_hours : engagementType;
  // let total_estimated_billing = datePrefill?.total_estimated_billing? datePrefill.total_estimated_billing : datePrefill.salary_per_month;
  let total_estimated_billing = rate_per_hour * client_reserved_hours;
  let name = datePrefill?.name ? datePrefill.name: `${datePrefill?.first_name?datePrefill.first_name:"-"} ${datePrefill?.last_name?datePrefill.last_name[0]:""}`
  let experience = datePrefill?.experience ? datePrefill.experience : datePrefill?.experience_in_year ? (datePrefill?.experience_in_month ? datePrefill?.experience_in_year + "+ yrs":datePrefill?.experience_in_year + " yrs"):(datePrefill?.experience_in_month?datePrefill?.experience_in_month + " months":"-")
  let id = datePrefill?.id ? datePrefill.id : datePrefill._id;
  let avatar = datePrefill?.avatar?datePrefill.avatar:datePrefill.image;


  let result = null;

    if (Array.isArray(talent_rate)) {
      result = talent_rate.find(item => item.talent_id.includes(id));
    }

  const talentNetworkCost = result ? result.talent_network_cost : null;

  const {postRequest, getPostRequestNonBaseNoType,SAVE_CONTRACT_DETAIL,UPDATE_CONTRACT_DETAIL,DOWNLOAD_CONTRACT} = useApiService();
  // const [anchorEl, setAnchorEl] = useState(null);
  const [draftHistory, setDraftHistory] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [startDateOpen, setStartDateOpen] = useState(false);
  const [endDateOpen, setEndDateOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const [secondWarningModal, setSecondWarningModal] = useState(false);
  const [pdffile, setPdfFile] = useState("");
  const [docfile, setDocFile] = useState("");
  const [contributionHours, setContributionHours] = useState(
    client_reserved_hours ? client_reserved_hours : ""
  );
  const [rateperHour, setrateperHour] = useState(
    talentNetworkCost ? talentNetworkCost : ""
  );
  let [startDate, setStartDate] = useState(contract_details?.[0]?.start_date?contract_details[0].start_date:"");
  let [endDate, setEndDate] = useState(contract_details?.[0]?.end_date?contract_details[0].end_date:"");

  const [contributionHoursError, setContributionHoursError] = useState(false);
  const [rateperHourError, setrateperHourError] = useState(false);
  const [totalEstimatedBilling, setTotalEstimatedBilling] = useState(rate_per_hour && client_reserved_hours?rate_per_hour*client_reserved_hours:"");
  let [startDateError, setStartDateError] = useState(false);
  let [endDateError, setEndDateError] = useState(false);

  const onDropDoc = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && !file.name.toLowerCase().endsWith(".doc") && !file.name.toLowerCase().endsWith(".docx")) {
      toast.error("Please upload a DOC or DOCX file.");
      return;
    }
    setPdfFile("");
    console.log(file);
    let docFileNo = contract_details?.[0]?.contract_documents? contract_details[0].contract_documents.filter((v)=>v.document_original_name.split(".")[1] == "docx" || v.document_original_name.split(".")[1] == "doc").length+1:1;
    let fileName = `${name.split(" ")[0]} ${name.split(" ")?.[1] && name.split(" ")[1][0]}_${hiringSide ? company_name : user.company.company_name}_to_${hiringSide ? user.company.company_name : company_name}_${docFileNo}`;
    let myNewFile = new File([file],  fileName+"."+file.name.split(".")[1], {type: file.type});
    
    setDocFile(myNewFile);
  }, []);

  const onDropPdf = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && !file.name.toLowerCase().endsWith(".pdf")) {
      toast.error("Please upload a PDF file.");
      return;
    }
    console.log(file);
    let fileName = `${name.split(" ")[0]} ${name.split(" ")?.[1] && name.split(" ")[1][0]}_${hiringSide ? company_name : user.company.company_name}_to_${hiringSide ? user.company.company_name : company_name}_final`;
    const myNewFile = new File([file], fileName+".pdf", {type: file.type});

    setDocFile("");
    setPdfFile(myNewFile);
  }, []);

  const { getRootProps: getRootPropsDoc, getInputProps: getInputPropsDoc } =
    useDropzone({
      onDrop: onDropDoc,
      accept: ".doc,.docx",
      multiple: false,
    });

  const { getRootProps: getRootPropsPdf, getInputProps: getInputPropsPdf } =
    useDropzone({
      onDrop: onDropPdf,
      accept: ".pdf",
      multiple: false,
    });

  const handleStartDateChange = (value) => {
    if(value){
      console.log(moment(value).valueOf() , moment(endDate).valueOf(),moment(value).toDate());
      if(endDate && (moment(value).valueOf() > moment(endDate).valueOf())){
        setStartDate(value);
        setEndDate(moment(value).add(1,"M").toDate());
      }else{
        setStartDate(value);
      }
  }};

  const handleEndDateChange = (value) => {
    if(value){
      setEndDate(value);
    }
  };

  const handleContribution = (event) => {
    if(rateperHour && event.target.value){
      setTotalEstimatedBilling(Number(event.target.value) * Number(rateperHour));
    }else{
      setTotalEstimatedBilling(0);
    }
    setContributionHours(event.target.value);
  };

  const handleRateperHour = (event) => {
    if(contributionHours && event.target.value){
      setTotalEstimatedBilling(Number(event.target.value) * Number(contributionHours));
    }else{
      setTotalEstimatedBilling(0);
    }
    setrateperHour(event.target.value);
  };

  const handleSend = (event) => {
    let errors = false;

    if (!startDate || startDate == "Invalid Date") {
      errors=true;
      setStartDateError(true);
    }else{
      setStartDateError(false);
    }

    if (!endDate || endDate == "Invalid Date") {
      errors=true;
      setEndDateError(true);
    }else{
      setEndDateError(false);
    }

    if (!rateperHour) {
      errors=true;
      setrateperHourError(true);
    }else{
      setrateperHourError(false);
    }

    if (!contributionHours) {
      errors=true;
      setContributionHoursError(true);
    }else{
      setContributionHoursError(false);
    }

    if(errors){
      return;
    }

    setIsLoading(true);
    setStartDateError(false);
    setEndDateError(false);
    setrateperHourError(false);
    setContributionHoursError(false);
    // if (docfile && pdffile) {
    //   errors.upload = "Please upload only one file, either DOC or PDF";
    // } else if (!docfile && !pdffile) {
    //   errors.upload = "Please upload a DOC or PDF file";
    // }

    // if (Object.keys(errors).length) {
    //   toast.error(errors.fields || errors.upload);
    // } else {
    //   event.stopPropagation();
    //   toast.success("Submitted Successfully!!!");
    //   handleCLose();
    // }

    let isFinalized = contract_details[0]?.contract_documents?.[0]?.document_original_name.split(".")?.[contract_details[0].contract_documents[0].document_original_name.split(".").length - 1] == "pdf" &&
    pdffile &&
    contract_details[0]?.contract_documents?.[0]?.uploaded_by != name?true:false;

    let data = new FormData();
    data.append("start_date",moment(startDate).format("DD-MMM-yyyy"));
    data.append("end_date",moment(endDate).format("DD-MMM-yyyy"));
    data.append("contribute_hours",contributionHours);
    data.append("rate_per_hour",rateperHour);
    data.append("estimated_billing",total_estimated_billing);
    data.append("talent_id",id);
    data.append("hiring_id",hiring_id?hiring_id:params.id);
    data.append("contract_document",docfile?docfile:pdffile?pdffile:"");
    data.append("document_original_name",docfile?docfile.name:pdffile?pdffile.name:"");
    data.append("contract_status",isFinalized?"finalized":"review");
    let urlCall = ""
    if(contract_details && contract_details.length !=0 ){
      urlCall = UPDATE_CONTRACT_DETAIL+contract_details[0]._id;
    }else{
      urlCall = SAVE_CONTRACT_DETAIL;
    }

    postRequest(urlCall,data)
    .then((res)=>{
      setIsLoading(false);
      console.log(res);
      if(
        isFinalized
      ){
        success("completed");
      }else{
        success();
      }
      
    })
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return `${day}-${monthNames[monthIndex]}-${year}`;
  };

  const handleDownload = (contractId,doc,docName) => {
    postRequest(
      DOWNLOAD_CONTRACT+contractId,
      {contract_document:doc},
      {responseType: 'blob'}
    )
    .then((res)=>{
      console.log(res.data,res.headers.get('Content-Disposition'));
      let myUrl = window.URL.createObjectURL(res.data)
      const link = document.createElement("a");
      link.href = myUrl;
      link.setAttribute("download", docName+setDownloadExtension(res.data.type));
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
  }

  const setDownloadExtension = (fileType) => {

    switch (fileType) {
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return ".docx";
      case "application/msword":
        return ".doc";
      case "application/pdf":
        return ".pdf";
      default:
        return ".pdf";
    }
  }

  const decideWarningModal = (type) => {
    if(contract_details && contract_details.length != 0){
      if(contract_details[0]?.contract_documents && contract_details[0]?.contract_documents.length != 0){
        
        if(contract_details[0]?.contract_documents.length>1){
          var document2 = contract_details[0]?.contract_documents[1];
          var document = contract_details[0]?.contract_documents[0];
        }else{
          var document = contract_details[0]?.contract_documents[0];
          var document2 = "";
        }
        
        if(pdffile && type != "tableCheck"){

          if(document?.document_original_name?.split(".")?.[1] == "pdf" && document?.uploaded_by != name){
            return "second";
          }

          if(document?.document_original_name?.split(".")?.[1] == "pdf"){
            return "first";
          }
        }

        if(type == "tableCheck"){
          if(document?.document_original_name.split(".")?.[1] != "pdf"){
            return false;
          }
  
          if(document2 && document2?.document_original_name.split(".")?.[1] == "pdf"){
            return "second";
          }

          if(document?.document_original_name.split(".")?.[1] == "pdf"){
            return "first";
          }
        }

        return false;

      }else{
        return false;
      }
    }else{
      if(pdffile && type != "tableCheck"){
        return "first";
      }
      return false;
    }
    
  };

  const IsolatedMenu = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    return(
    <React.Fragment>
      <IconButton
        sx={{
          color: "#6A0DAD",
          "&:hover": {
            backgroundColor: "transparent",
            color: "#6A0DAD",
          },
          p:0
        }}
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        <img src={Threedots} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          sx: {
            padding: 0,
            margin: 0,
          },
        }}
        sx={{ borderRadius: "15px" }}
        disableScrollLock
      >
        <MenuItem
          sx={{ border: "0px, 1px, 1px, 1px" }}
          onClick={()=>{
            handleDownload(contract_details[0]._id,props.documentName);
            setAnchorEl(null);}}
        >
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "12px", mr: 1 }}>
              Download
            </Typography>
            <Frame />
          </Box>
        </MenuItem>
      </Menu>
  
    </React.Fragment>
    )
  }

  const checkContractSigning = () => {
    if(contract_details && contract_details.length !=0){
      if(contract_details[0]?.contract_documents.length != 0){

        if(
          contract_details[0]?.contract_documents?.[0]?.document_original_name.split(".")[contract_details[0].contract_documents[0].document_original_name.split(".").length - 1] == "pdf" &&
          contract_details[0]?.contract_documents?.[1]?.document_original_name.split(".")[contract_details[0].contract_documents[1].document_original_name.split(".").length - 1] == "pdf" &&
          contract_details[0]?.contract_documents?.[1]?.uploaded_by != contract_details[0]?.contract_documents?.[0]?.uploaded_by
        ){
          return true;
        }else{
          return false;
        }
      }else{
        return false;
      }
    }else{
      return false;
    }
  }

  return (
    <>
      <Box className="modal-body" sx={{"&.modal-body":{p:0,width:"780px"}}}>
        <Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "20px", backgroundColor: "#673ab7", position: "relative", borderTopLeftRadius:"6px", borderTopRightRadius:"6px" }}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ fontSize: "18px", fontWeight: 700, color: "white" }}
        >
          {contractStatus}
        </Typography>
        <Box sx={{ position: "absolute", right: "20px", top: "15px" }}>
          <IconButton
            size="small"
            onClick={() => {
                  setStartDateError(false);
                  setEndDateError(false);
                  setContributionHoursError(false);
                  setrateperHourError(false);
                  if(!datePrefill.contract_details || datePrefill.contract_details.length==0){
                    setStartDate("");
                    setEndDate("");
                    setPdfFile("");
                    setDocFile("");
                  }
                  handleCLose();
            }}
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <Box component="img" sx={{ width: "30px" }} src={Cross} />
          </IconButton>
        </Box>
      </Box>


          {/* Header */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              marginTop:"1px",
              backgroundColor: "#673ab7",
              padding: "16px",
            }}
          >
            {console.log(name,`${name.split(" ")[0][0]}${name.split(" ")[1][0]}`)}
            <Avatar
              alt={`${name.split(" ")[0][0]} ${name.split(" ")[1][0]}`}
              src={avatar}
              sx={{ width: 68, height: 68, border: `1px solid ${color_code}`, backgroundColor:color_code }}
            >{`${name.split(" ")[0][0]} ${name.split(" ")[1][0]}`}</Avatar>
            <Box sx={{ ml: 2 }}>
              <Typography
                component="div"
                sx={{ color: "white", fontWeight: "700", fontSize: "14px" }}
              >
                {name || "-"}
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  color: "white",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                {role || "-"}
              </Typography>
              <Typography
                sx={{ color: "white", fontWeight: "500", fontSize: "14px" }}
              >
                {experience || "-"}
              </Typography>
            </Box>
            <Box sx={{ marginLeft: "auto", textAlign: "right" }}>
              <Typography
                variant="caption"
                sx={{ color: "white", fontWeight: "500", fontSize: "14px" }}
              >
                {hiringSide?"Leads":"Hiring"} Agency: <strong>{company_name}</strong>
              </Typography>
            </Box>
          </Box>

          {/* Form Fields */}
          <Box sx={{ p: 3 }}>
          <LocalizationProvider localeText={{
              fieldMonthPlaceholder: (params) =>
                  params.contentType === "letter" ? "MMM" : "MM",
          }} dateAdapter={AdapterDateFns}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {console.log("endDateError",startDateError,endDateError,startDate,moment().format("DD-MM-YYYY"))}
              <DatePicker
                  className='customOutlinedTextFieldVariant'
                  label="Start Date"
                  format="dd-MMM-yyyy"
                  open={startDateOpen}
                  onOpen={() => setStartDateOpen(true)}
                  onClose={() => setStartDateOpen(false)}
                  minDate={startDate? moment(startDate).format("DD-MMM-YYYY"):moment().format("DD-MMM-YYYY")}
                  disabled={checkContractSigning()}
                  slotProps={{
                      textField: {
                          fullWidth: true,
                          readOnly: true,
                          onClick:()=>{setStartDateOpen(true)},
                          required: checkContractSigning()?false:true,
                          placeholder:"DD-MM-YYYY",
                          helperText: startDateError? <Box color="error.main">This field is required</Box>:"",
                      }
                  }}
                  slots={{
                      openPickerIcon: CalenderIcon
                  }}
                  value={startDate}
                  onChange={(val)=>{handleStartDateChange(val)}}
              />
              </Grid>
              <Grid item xs={12} sm={6}>
              <DatePicker
                  className='customOutlinedTextFieldVariant'
                  label="End Date"
                  format="dd-MMM-yyyy"
                  open={endDateOpen}
                  disableOpenPicker={startDate ? false:true}
                  onOpen={() => {
                    console.log("--------+++",startDate);
                    if(startDate){
                      setEndDateOpen(true);
                    }
                  }}
                  onClose={() => setEndDateOpen(false)}
                  disabled={checkContractSigning()}
                  minDate={!startDate?
                    moment().format("DD-MMM-YYYY")
                    :
                    moment(startDate).add(1,"M").format("DD-MMM-YYYY")}
                  slotProps={{
                      textField: {
                          fullWidth: true,
                          readOnly: true,
                          onClick:()=>{
                            if(startDate){
                              setEndDateOpen(true);
                            }
                          },
                          required: checkContractSigning()?false:true,
                          helperText: endDateError? <Box color="error.main">This field is required</Box>:"",
                      }
                  }}
                  slots={{
                      openPickerIcon: CalenderIcon
                  }}
                  value={endDate}
                  onChange={(val)=>{handleEndDateChange(val)}}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Contribution Hours"
                  fullWidth
                  type="number"
                  required={checkContractSigning()?false:true}
                  disabled={checkContractSigning()}
                  value={contributionHours}
                  onChange={handleContribution}
                  helperText={contributionHoursError? "This field is required":""}
                  FormHelperTextProps={{ style: { color: theme.palette.error.main }}}
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      borderRadius: "8px",
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#CED1DA",
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={`Rate Per Hour (${user.currency})`}
                  disabled={hiringSide || checkContractSigning()}
                  fullWidth
                  type="number"
                  required={checkContractSigning()?false:(hiringSide?false:true)}
                  value={rateperHour}
                  onChange={handleRateperHour}
                  helperText={rateperHourError? "This field is required":""}
                  FormHelperTextProps={{ style: { color: theme.palette.error.main }}}
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      borderRadius: "8px",
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#CED1DA",
                        },
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Currency />
                      </InputAdornment>
                    ),
                  }}
                />
                {
                  totalEstimatedBilling >-1 &&
                  <Typography
                    variant="caption"
                    sx={{ mt: 1, display: "block", color: "#666" }}
                  >
                    Estimated Monthly Billing: {<Currency amount={totalEstimatedBilling}/>}
                  </Typography>
                }
                
              </Grid>
              {
                checkContractSigning() && contractDuration &&
                <Grid item xs={12} sm={6}>
                <TextField
                  label="Contract duration"
                  fullWidth
                  // required
                  disabled={checkContractSigning()}
                  value={contractDuration + (parseInt(contractDuration) > 1 ? " months":" month")}
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      borderRadius: "8px",
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#CED1DA",
                        },
                      },
                    },
                  }}
                />
              </Grid>
              }
              {
                contract_details?.[0]?.contract_documents && contract_details?.[0].contract_documents.length !=0 && !checkContractSigning() &&
                <Grid item xs={12} sx={{border:`1px solid ${theme.palette.teritiary.teritiary6}`,"&.MuiGrid-item":{p:0},m:"16px 0 16px 16px",borderRadius:"8px"}}>
                <TableContainer sx={{ maxHeight: "300px" }}>
                  <Table
                    stickyHeader
                    sx={{
                      minWidth: 650,
                      borderCollapse: "collapse",
                      borderSpacing: 0,
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            backgroundColor: "#673ab7",
                            color: "#fff",
                            fontWeight: "bold",
                            textAlign: "center",
                            borderRight: "1px solid #fff",
                            border: "1px, 0.81px, 0.81px, 0px",
                            borderTopLeftRadius: "8px",
                          }}
                        >
                          Document Name
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#673ab7",
                            color: "#fff",
                            fontWeight: "bold",
                            textAlign: "center",
                            borderRight: "1px solid #fff",
                            border: "1px, 0.81px, 0.81px, 0px",
                          }}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#673ab7",
                            color: "#fff",
                            fontWeight: "bold",
                            textAlign: "center",
                            border: "1px, 0.81px, 0.81px, 0px",
                            borderRight: "1px solid #fff",
                          }}
                        >
                          Uploaded By
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#673ab7",
                            color: "#fff",
                            fontWeight: "bold",
                            textAlign: "center",
                            border: "1px, 0.81px, 0.81px, 0px",
                            borderRight: "1px solid #fff",
                            borderTopRightRadius: "8px",
                          }}
                        >
                          Status
                        </TableCell>
                        {/* <TableCell
                          sx={{
                            backgroundColor: "#673ab7",
                            color: "#fff",
                            fontWeight: "bold",
                            textAlign: "center",
                            border: "1px, 0.81px, 0.81px, 0px",
                            borderTopRightRadius: "8px",
                            maxWidth:"40px"
                          }}
                        >
                          
                        </TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        contract_details[0].contract_documents.map((contractDoc)=>{
                          return (
                            <TableRow
                        sx={{
                          "&:nth-of-type(odd)": {
                            backgroundColor: "white",
                          },
                          "&:nth-of-type(even)": {
                            backgroundColor: "white",
                          },
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <TableCell
                          sx={{
                            color: "#000",
                            fontWeight: 500,
                            padding: "10px",
                            borderBottom: "none",
                            borderBottomLeftRadius: "8px",
                          }}
                        >
                          <Stack direction="row" alignItems="center">
                          {(contractDoc?.document_original_name.split(".")?.[contractDoc.document_original_name.split(".").length-1] == "doc" || contractDoc?.document_original_name.split(".")?.[contractDoc.document_original_name.split(".").length-1] == "docx") ? (
                            <img src={Wordfileicon}/>
                          ) : (
                            ""
                          )}
                          {contractDoc?.document_original_name.split(".")?.[contractDoc.document_original_name.split(".").length-1] == "pdf" ? (
                            <img
                            src={Pdffileicon}
                          />
                          ) : (
                            ""
                          )}
                          <Typography
                            sx={{
                              marginLeft: "9px",
                              color: "#000",
                              fontWeight: 500,
                              fontSize: "14px",
                            }}
                          >
                            {contractDoc?.document_original_name ? contractDoc.document_original_name : "-"}
                          </Typography>
                          </Stack>
                          
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontWeight: 500,
                            padding: "10px",
                            borderBottom: "none",
                            textAlign: "center",
                            marginBottom: "5px",
                            whiteSpace:"nowrap"
                          }}
                        >
                          {contractDoc?.upload_date?formatDate(contractDoc.upload_date):"-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontWeight: 500,
                            padding: "10px",
                            borderBottom: "none",
                            textAlign: "center",
                          }}
                        >
                          {contractDoc?.uploaded_by?contractDoc.uploaded_by:"-"}
                          </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontWeight: 500,
                            padding: "10px",
                            borderBottom: "none",
                            // textAlign: "center",
                          }}
                        >
                          <Box sx={{
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between"
                          }}>
                          <Box pr="5px">{contractDoc?.status ? contractDoc.status: "-"}</Box>
                          <Box component="img" sx={{cursor:"pointer"}} onClick={()=>{handleDownload(contract_details[0]._id,contractDoc.document_name,contractDoc.document_original_name);}} src={Download} />
                          </Box>
                        </TableCell>
                        {/* <TableCell
                          sx={{
                            color: "#000",
                            fontWeight: 500,
                            padding: "10px",
                            borderBottom: "none",
                            alignItems: "center",
                            borderBottomRightRadius: "8px",
                            maxWidth:"40px"
                          }}
                        > */}
                          {/* <Box component="img" sx={{cursor:"pointer"}} onClick={()=>{handleDownload(contract_details[0]._id,contractDoc.document_name,contractDoc.document_original_name);}} src={Download} /> */}
                          {/* <IsolatedMenu documentName={contractDoc.document_name}/> */}
                        {/* </TableCell> */}
                      </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              }

              

              {!docfile && !pdffile && !checkContractSigning() && (
                <Grid item xs={12}>
                  <Box sx={{ position: "relative"}}>
                    <Typography
                      sx={{
                        position: "absolute",
                        top: "-10px",
                        left: "12px",
                        fontSize: "12px",
                        fontWeight: 400,
                        backgroundColor: "white",
                        px: 1,
                        color: "#666",
                      }}
                    >
                      Upload Agreement
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        border: "1px solid #dcdcdc",
                        borderRadius: 2,
                        backgroundColor: "white",
                        p: 2,
                        pt: 2,
                      }}
                    >
                      <Box
                        {...getRootPropsDoc()}
                        sx={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          cursor: "pointer",
                          textAlign: "center",
                          p: 1,
                        }}
                      >
                        <input {...getInputPropsDoc()} />
                        <img src={Wordfileicon}/>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 600,
                            fontSize: "14px",
                            color: "#1A1A1A",
                          }}
                        >
                          Upload Draft / Revised Agreement
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: docfile ? 600 : 500,
                            fontSize: "14px",
                            color: docfile ? "#1A1A1A" : "#898989",
                          }}
                        >
                          {docfile ? docfile.name : "Or drag and drop it here"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ width: "1px", backgroundColor: "#dcdcdc", mx: 2 }}
                      />
                      <Box
                        {...getRootPropsPdf()}
                        sx={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          cursor: "pointer",
                          textAlign: "center",
                          p: 1,
                        }}
                      >
                        <input {...getInputPropsPdf()} />
                        
                        <img
                            src={Pdffileicon}
                          />
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 600,
                            fontSize: "14px",
                            color: "#1A1A1A",
                          }}
                        >
                          Upload Final Contract for Signing / Signed{" "}
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: pdffile ? 600 : 500,
                            fontSize: "14px",
                            color: pdffile ? "#1A1A1A" : "#898989",
                          }}
                        >
                          {pdffile ? pdffile.name : "Or drag and drop it here"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )}

              {(docfile || pdffile) && (
                <Grid item xs={12}>
                  <Box sx={{ position: "relative", mt: 1 }}>
                    <Typography
                      sx={{
                        position: "absolute",
                        top: "-10px",
                        left: "12px",
                        fontSize: "12px",
                        fontWeight: 400,
                        backgroundColor: "white",
                        px: 1,
                        color: "#666",
                      }}
                    >
                      Upload Agreement
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        border: "1px solid #dcdcdc",
                        borderRadius: 2,
                        backgroundColor: "white",
                        p: 2,
                        pt: 2,
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <Box>
                          {docfile ? (
                            <img src={Wordfileicon}/>
                          ) : (
                            ""
                          )}{" "}
                          {pdffile ? (
                            <img
                            src={Pdffileicon}
                          />
                          ) : (
                            ""
                          )}
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              ml: 1,
                              fontWeight: 600,
                              fontSize: "14px",
                              color: "#898989",
                            }}
                          >
                            {docfile ? docfile?.name : ""}{" "}
                            {pdffile ? pdffile?.name : ""}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <SvgIcon
                          sx={{
                            height: "18px",
                            width: "18px",
                            color: "black",
                            marginLeft: "15px",
                            cursor:"pointer"
                          }}
                          component={Deleteicon}
                          inheritViewBox
                          onClick={()=>{
                            setPdfFile("");
                            setDocFile("");
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )}
              {console.log(decideWarningModal("tableCheck"))}
              {
                checkContractSigning() &&
                <>
                <Grid item xs={12}>
                  <Box sx={{ position: "relative", mt: 1 }}>
                    <Typography
                      sx={{
                        position: "absolute",
                        top: "-10px",
                        left: "12px",
                        fontSize: "12px",
                        fontWeight: 400,
                        backgroundColor: "white",
                        px: 1,
                        color: "#666",
                      }}
                    >
                      Final Contract
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        border: "1px solid #dcdcdc",
                        borderRadius: 2,
                        backgroundColor: "white",
                        p: 2,
                        pt: 2,
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <Box>
                        <img
                            src={Pdffileicon}
                          />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              ml: 1,
                              fontWeight: 600,
                              fontSize: "14px",
                              color: "#898989",
                            }}
                          >
                            {contract_details?.[0]?.contract_documents?.[0]?.document_original_name|| "-"}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                      <Box component="img" sx={{cursor:"pointer"}} onClick={()=>{handleDownload(
                        contract_details[0]._id,
                        contract_details?.[0]?.contract_documents[0].document_name,
                        contract_details?.[0]?.contract_documents[0].document_original_name
                        );
                        }} src={Download} />
                      {/* <IsolatedMenu documentName={contract_details?.[0]?.contract_documents[0].document_name}/> */}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Typography
                  display="flex"
                  variant="size3MediumTerTer8"
                  onClick={()=>{setDraftHistory(!draftHistory)}}
                  sx={{cursor:"pointer",pl:"16px",pt:"16px"}}
                ><Box pr="5px">Draft History</Box> <img src={Downarraow} style={{...(draftHistory && {transform:"rotate(180deg)"})}}/></Typography>
                {
                  draftHistory && 
                  <Grid item xs={12} sx={{border:`1px solid ${theme.palette.teritiary.teritiary6}`,"&.MuiGrid-item":{p:0},m:"16px 0 16px 16px",borderRadius:"8px"}}>
                  <TableContainer  sx={{ maxHeight: "300px" }}>
                    <Table
                      stickyHeader
                      sx={{
                        minWidth: 650,
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              backgroundColor: "#673ab7",
                              color: "#fff",
                              fontWeight: "bold",
                              textAlign: "center",
                              borderRight: "1px solid #fff",
                              border: "1px, 0.81px, 0.81px, 0px",
                              borderTopLeftRadius: "8px",
                            }}
                          >
                            Document Name
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "#673ab7",
                              color: "#fff",
                              fontWeight: "bold",
                              textAlign: "center",
                              borderRight: "1px solid #fff",
                              border: "1px, 0.81px, 0.81px, 0px",
                            }}
                          >
                            Date
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "#673ab7",
                              color: "#fff",
                              fontWeight: "bold",
                              textAlign: "center",
                              border: "1px, 0.81px, 0.81px, 0px",
                              borderRight: "1px solid #fff",
                            }}
                          >
                            Uploaded By
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "#673ab7",
                              color: "#fff",
                              fontWeight: "bold",
                              textAlign: "center",
                              border: "1px, 0.81px, 0.81px, 0px",
                              borderRight: "1px solid #fff",
                              borderTopRightRadius: "8px",
                            }}
                          >
                            Status
                          </TableCell>
                          {/* <TableCell
                            sx={{
                              backgroundColor: "#673ab7",
                              color: "#fff",
                              fontWeight: "bold",
                              textAlign: "center",
                              border: "1px, 0.81px, 0.81px, 0px",
                              borderTopRightRadius: "8px",
                            }}
                          >
                            
                          </TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          contract_details[0].contract_documents.map((contractDoc)=>{
                            return (
                              <TableRow
                          sx={{
                            "&:nth-of-type(odd)": {
                              backgroundColor: "white",
                            },
                            "&:nth-of-type(even)": {
                              backgroundColor: "white",
                            },
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                          <TableCell
                            sx={{
                              color: "#000",
                              fontWeight: 500,
                              padding: "10px",
                              borderBottom: "none",
                              borderBottomLeftRadius: "8px",
                            }}
                          >
                            <Stack direction="row" alignItems="center">
                            {(contractDoc?.document_original_name.split(".")?.[contractDoc.document_original_name.split(".").length-1] == "doc" || contractDoc?.document_original_name.split(".")?.[contractDoc.document_original_name.split(".").length-1] == "docx") ? (
                              <img src={Wordfileicon}/>
                            ) : (
                              ""
                            )}
                            {contractDoc?.document_original_name.split(".")?.[contractDoc.document_original_name.split(".").length-1] == "pdf" ? (
                              <img
                              src={Pdffileicon}
                            />
                            ) : (
                              ""
                            )}
                            <Typography
                              sx={{
                                marginLeft: "9px",
                                color: "#000",
                                fontWeight: 500,
                                fontSize: "14px",
                              }}
                            >
                              {contractDoc?.document_original_name ? contractDoc.document_original_name : "-"}
                            </Typography>
                            </Stack>
                            
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontWeight: 500,
                              padding: "10px",
                              borderBottom: "none",
                              textAlign: "center",
                              marginBottom: "5px",
                              whiteSpace:"nowrap"
                            }}
                          >
                            {contractDoc?.upload_date?formatDate(contractDoc.upload_date):"-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontWeight: 500,
                              padding: "10px",
                              borderBottom: "none",
                              textAlign: "center",
                            }}
                          >
                            {contractDoc?.uploaded_by?contractDoc.uploaded_by:"-"}
                            </TableCell>
                          <TableCell
                            sx={{
                              color: "#000",
                              fontWeight: 500,
                              padding: "10px",
                              borderBottom: "none",
                              textAlign: "center",
                            }}
                          >
                             <Box sx={{
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between"
                          }}>
                          <Box pr="5px">{contractDoc?.status ? contractDoc.status: "-"}</Box>
                          <Box component="img" sx={{cursor:"pointer"}} onClick={()=>{handleDownload(contract_details[0]._id,contractDoc.document_name,contractDoc.document_original_name);}} src={Download} />
                          </Box>
                          </TableCell>
                          {/* <TableCell
                            sx={{
                              color: "#000",
                              fontWeight: 500,
                              padding: "10px",
                              borderBottom: "none",
                              alignItems: "center",
                              borderBottomRightRadius: "8px",
                            }}
                          > */}
                           
                            {/* <Box component="img" sx={{cursor:"pointer"}} onClick={()=>{handleDownload(contract_details[0]._id,contractDoc.document_name,contractDoc.document_original_name);}} src={Download} /> */}
                            {/* <IsolatedMenu documentName={contractDoc.document_original_name}/> */}
                          {/* </TableCell> */}
                        </TableRow>
                            )
                          })
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>   
                }            
                </>
              }
            </Grid>
          </LocalizationProvider>
            
          </Box>

              {
                !checkContractSigning() &&
          <Box
            sx={{
              p: 2,
              textAlign: "center",
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
            }}
          >
            {isLoading?
            <BtnSpinner
                cls="ad-theme-btn w-100"
                loader="true"
            />
            :
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              sx={{
                textTransform: "none",
                backgroundColor: "#5e35b1",
                borderRadius: 2,
                height: "50px",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#5e35b1",
                  borderColor: "#5e35b1",
                  borderWidth: "2px",
                  borderStyle: "solid",
                },
              }}
              onClick={()=>{
                let modalType = decideWarningModal();
                if(modalType == "first"){
                  setWarningModal(true);
                  return;
                }

                if(modalType == "second"){
                  setSecondWarningModal(true);
                  return;
                }

                handleSend();
              }}
            >
              Send
            </Button>}
          </Box>
              }
        </Box>
      </Box>
      <AgencySmModal
        open={warningModal}
        close={() => {
          setWarningModal(false);
        }}
      >
        <div className="text-center">
          <img src={exclamation} alt="question" />
        </div>
        {/* <div className="mt-3 ad-md-title text-center">Are you sure?</div> */}
        <div className="mt-2 content text-center">
          <p className="ad-lgray-text">
          This Contract will be considered as final, once signed by the other agency.
          </p>
        </div>
        <div className="mt-3 text-center row px-3">
          <div className="col-6">
            <button
              type="button"
              className="ad-gray-btn ad-sm-btn w-100"
              onClick={() => {
                setWarningModal(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="col-6">
            {
              isLoading?
              <BtnSpinner
                cls="ad-theme-btn w-100"
                loader="true"
            />
              :
              <button
              type="button"
              className="ad-theme-btn ad-sm-btn w-100 d-block"
              onClick={() => {
                handleSend();
              }}
            >
              Okay
            </button>
            }
          </div>
        </div>
      </AgencySmModal>
      <AgencySmModal
        open={secondWarningModal}
        close={() => {
          setSecondWarningModal(false);
        }}
      >
        <div className="text-center">
          <img src={exclamation} alt="question" />
        </div>
        {/* <div className="mt-3 ad-md-title text-center">Are you sure?</div> */}
        <div className="mt-2 content text-center">
          <p className="ad-lgray-text">
          Once the document is Sent, it will be attached to the contract.
          </p>
        </div>
        <div className="mt-3 text-center row px-3">
          <div className="col-6">
            <button
              type="button"
              className="ad-gray-btn ad-sm-btn w-100"
              onClick={() => {
                setSecondWarningModal(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="col-6">
          {
              isLoading?
              <BtnSpinner
                cls="ad-theme-btn w-100"
                loader="true"
            />
              :
            <button
              type="button"
              className="ad-theme-btn ad-sm-btn w-100 d-block"
              onClick={() => {
                handleSend();
              }}
            >
              Okay
            </button>
            }
          </div>
        </div>
      </AgencySmModal>
    </>
  );
};

export default ContractCreation;
