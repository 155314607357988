import React, { useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import userCircleIcon from "../../assets/agency/img/user-circle-icon.svg";
import onboardingUploadIcon from "../../assets/agency/img/onboarding-upload.svg";
import BtnSpinner from "../Spinners/BtnSpinner";
import teamImg from "../../assets/agency/img/team-1.jpg";
import { useState } from "react";
import Currency from "../Currency/Currency";
import * as yup from "yup";
import ValidationError from "../Errors/ValidationError";
import PreviewImage from "../Form/PreviewImage";
import FormikDatePicker from "../Form/FormikDatePicker";
import axios from "axios";
import AuthUser from "../../Services/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SelectField from "../Form/SelectField";
import SelectCountry from "../Form/SelectCountry";
import SelectState from "../Form/SelectState";
import SelectCity from "../Form/SelectCity";
import apiCall from "../../Services/ApiInstance";
// import AvatarWithUpload from "../../../Components/Form/AvatarWIthUpload";
import AvatarWithUpload from "../../Components/Form/AvatarWIthUpload";
// import AddLeadModal from "./AddLeadModal";
import SvgIcon from "@mui/material/SvgIcon";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as EditIcon } from "../../assets/dashboard/img/edit_2_dynamic.svg";

let base_URL = process.env.REACT_APP_base_URL;
import { ReactComponent as CloseIcon } from "../../assets/agency/img/close-icon.svg";
import { utilities } from "../../Config/Helper";
import { Avatar, Box, Stack, Tooltip, useTheme } from "@mui/material";
import Select from "react-select";
import { ReactComponent as CloseIcon2 } from "../../assets/agency/img/close-icon-dynamic.svg";

const validationSchemaTeam = yup.object({
  company_name: yup
    .string()
    // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .min(2, "This field must be at least 2 characters")
    .max(30, "This field must not be greater than 30 characters")
    .required("This field is required!").matches(/^\s*\S[\s\S]*$/, 'This field is required!'),
  client_poc_name: yup
    .string()
    // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")//
    .min(2, "This field must be at least 2 characters")
    .max(30, "This field must not be greater than 30 characters")
    .required("This field is required!").matches(/^\s*\S[\s\S]*$/, 'This field is required!'),
  designation_of_poc: yup
    .string()
    .min(2, "This field must be at least 2 characters")
    .max(30, "This field must not be greater than 30 characters")
    .required("This field is required!"),
  client_poc_email: yup
    .string()
    .email("Invalid email format")
    .required("This field is required!").matches(/^\s*\S[\s\S]*$/, 'This field is required!'),
  company_address_line_1: yup.string().required("This field is required!").matches(/^\s*\S[\s\S]*$/, 'This field is required!'),
  // project_end_date: yup.string().required("This field is required!"),
  authority_company_country: yup.string().required("This field is required!"),
  authority_company_state: yup.string().required("This field is required!"),
  authority_company_city: yup.string().required("This field is required!"),
  pin_code: yup.string().required("This field is required!").matches(/^\s*\S[\s\S]*$/, 'This field is required!'),
 
});
const ViewClientModal = ({ setReload, reload, setModalOpen, clientEditItem,getAdminUser }) => {
  const { http2, token } = AuthUser();
  const navigate = useNavigate();
  const selectRef = useRef(null);
  const theme = useTheme();
  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  const [addTeamOnProject, setAddTeamOnProject] = useState(false);
  const [isProjectLoading, setIsProjectLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [countries, setCountries] = useState([]);
  const [inputValue, setInputValue] = useState("");
  // const [pinCode, setPinCode] = useState('');
  const [error, setError] = useState('');
  const [imgError, setImgError] = useState("");
  const [avatarUrl, setAvatarUrl] = useState(clientEditItem?.client_logo|| clientEditItem?.company_logo_url || userCircleIcon); 
  const currencyList = [
    "USD",
    "CAD",
    "INR"
  ];
  const [roleData, setRoleData] = useState("");
  const currencyType = currencyList.map((item) => ({
    key: item,
    value: item,
  }));
  console.log("clientEditItem",clientEditItem);
  const inputRef = useRef();
  const initialValues = {
    company_name: clientEditItem?.client_company_name || clientEditItem?.company_name || "",
    client_poc_name: clientEditItem?.client_name || clientEditItem?.first_name || "",
    designation_of_poc: clientEditItem?.poc_designation || clientEditItem?.company_role || "",
    client_poc_email: clientEditItem?.client_email || clientEditItem?.email || "",
    company_address_line_1: clientEditItem?.address_line_1 || clientEditItem?.address_line1 || "",
    company_address_line_2: clientEditItem?.address_line_2 || clientEditItem?.address_line2 || "",
    authority_company_country: clientEditItem?.country || "",
    authority_company_state: clientEditItem?.state || "",
    authority_company_city: clientEditItem?.city || "",
    pin_code: clientEditItem?.pin_code || clientEditItem?.zip_code || "",
    billing_email: clientEditItem?.billing_email ? clientEditItem.billing_email.length > 0 ? clientEditItem.billing_email.split(",").map((v, i) => { return { value: i, label: v } }) : [{ value: i, label: clientEditItem.billing_email }] : [],
    gst_no: clientEditItem?.gst_no || ""
  };

  // Add New Project
  const addProjectValues = (fields) => {
    console.log(fields);
    let data = new FormData();
    data.append(`client_company_name`, fields.company_name);
    data.append(`client_name`, fields.client_poc_name);
    data.append(`poc_designation`, fields.designation_of_poc);
    data.append(`client_email`, fields.client_poc_email);
    data.append(`address_line_1`, fields.company_address_line_1);
    data.append(`address_line_2`, fields.company_address_line_2);
    data.append(`state`, fields.authority_company_state);
    data.append(`country`, fields.authority_company_country);
    data.append(`city`, fields.authority_company_city);
    data.append(`pin_code`, fields.pin_code);
    data.append(`gst_no`, fields.gst_no);
    data.append(`billing_email`, fields.billing_email.map((v) => v.label));

    try {
      setIsProjectLoading(true);
      apiCall({
        method: "post",
        url: `${base_URL}/api/v2/edit-client/${clientEditItem._id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
        .then((response) => {
          let result = response.data;
          if (result.success) {
            toast.success(result.message);
            setEditMode(false);
            setModalOpen(false);
            setReload(false);
            setIsProjectLoading(false);
          }
          if (result.success == false) {
            toast.error(result.message);
            setModalOpen(false);
            setIsProjectLoading(false);
          }
          setModalOpen(false);
          setIsProjectLoading(false);
        })
        .catch((error) => {
          const err = error.response.data;
          if (error.response.status) {
            toast.error(err.message);
            // setIsTeamMemberLoading(false);
          }
          setIsProjectLoading(false);
        });
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
      setIsProjectLoading(false);
    }
  };


  const handleChange = (e) => {
    const value = e.target.value.toUpperCase(); 
    let formattedValue = value.replace(/[^A-Z0-9]/g, ''); 
  
    if (formattedValue.length > 0 && formattedValue[0] === '0') {
      setError('Pin code should not start with zero');
      return;
    }
  
    if (formattedValue.length > 7) {
      setError('Pin code should be at most 7 characters including space');
      return;
    }
  
    setError('');
    setPinCode(formattedValue);
  };
  

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarUrl(reader.result);
      };
      reader.readAsDataURL(file);
      
      let data = new FormData();
      data.append("client_logo", file);
  
      try {
        setIsProjectLoading(true);
        apiCall({
          method: "post",
          url: `${base_URL}/api/v2/edit-client/${clientEditItem._id}`,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: data,
        })
          .then((response) => {
            let result = response.data;
            if (result.success) {
              toast.success(result.message);
            } else {
              toast.error(result.message);
            }
            setIsProjectLoading(false);
          })
          .catch((error) => {
            const err = error.response.data;
            if (error.response.status) {
              toast.error(err.message);
            }
            setIsProjectLoading(false);
          });
      } catch (error) {
        toast.error(error.response.data.message);
        console.log(error);
        setIsProjectLoading(false);
      }
    }
  };

  const rolesList = async () => {
    // setIsLoading(true);
    // http2.get(`/roles/list`).then((res) => {
    //   let result = res.data.data;
    //   setRoleData(result);
    // });
    let role = await utilities("role");
    setRoleData(role);
  };

  useEffect(() => {
    (async () => {
      let countries = await utilities('countries');
      setCountries(countries);
      rolesList();
    })();

  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaTeam}
        onSubmit={(values, { resetForm, setFieldValue }) => {
          addProjectValues(values);
          // setImgAfterCrop("");
          resetForm();
          // setProjectTeamList([]);
          setAddTeamOnProject(false);
          // avatarFieldValue = ""
        }}
      >
        {({ values, setFieldValue, field, form, resetForm, formik,handleSubmit }) => (
          <>
            {/* {console.log(values.month_budget)} */}
            {/* <div className="modal-header">
              <Stack direction="row" alignItems="center">
                <Box>
                  <AvatarWithUpload
                    children={
                      <Box sx={{ textTransform: "uppercase" }}>
                        {clientEditItem?.client_company_name
                          ? clientEditItem.client_company_name.split(" ")
                              .length > 1
                            ? clientEditItem.client_company_name.split(
                                " "
                              )[0][0] +
                              clientEditItem.client_company_name.split(" ")[
                                clientEditItem.client_company_name.split(" ")
                                  .length - 1
                              ][0]
                            : `${
                                clientEditItem.client_company_name.split(
                                  " "
                                )[0][0]
                              }${
                                clientEditItem.client_company_name.split(
                                  " "
                                )[0][1]
                              }`
                          : clientEditItem?.company_name
                          ? clientEditItem.company_name.split(" ").length > 1
                            ? clientEditItem.company_name.split(" ")[0][0] +
                              clientEditItem.company_name.split(" ")[
                                clientEditItem.company_name.split(" ").length -
                                  1
                              ][0]
                            : `${clientEditItem.company_name.split(" ")[0][0]}${
                                clientEditItem.company_name.split(" ")[0][1]
                              }`
                          : "-"}
                      </Box>
                    }
                    onChange={handleAvatarChange}
                    noEdit={clientEditItem?.client_type == "external"}
                    src={avatarUrl}
                    sx={{
                      width: 44,
                      height: 44,
                      backgroundColor: theme.palette.common.white,
                      border: `1px solid ${theme.palette.primary.main}`,
                      color: theme.palette.primary.main,
                      mr: "12px",
                      textTransform: "uppercase",
                    }}
                    slotProps={{
                      img: {
                        sx: {
                          width: 32,
                          height: 32,
                          objectFit: "contain",
                        },
                      },
                    }}
                  />
                  {imgError ? (
                    <div>
                      <label className="error">{imgError}</label>
                    </div>
                  ) : (
                    " "
                  )}
                </Box>

                <h5 className="modal-title2" id="commonSMModalLabel">
                  {clientEditItem?.client_company_name ||
                    clientEditItem?.company_name ||
                    "-"}
                </h5>
              </Stack>

              <button
                style={{ marginRight: "6px" }}
                type="button"
                className="btn-close"
                onClick={() => {
                  setModalOpen(false);
                  resetForm({ values: initialValues });
                  getAdminUser();
                }}
              ></button>
            </div> */}

                  <div
                    className="modal-header"
                    style={{
                    
                    backgroundColor: "#552FBA",
                    position: "relative",
                    borderTopLeftRadius: "5px", 
                    borderTopRightRadius: "5px", 
                    }}
                >
                     <Stack direction="row" alignItems="center">
                <Box>
                  <AvatarWithUpload
                    children={
                      <Box sx={{ textTransform: "uppercase" }}>
                        {clientEditItem?.client_company_name
                          ? clientEditItem.client_company_name.split(" ")
                              .length > 1
                            ? clientEditItem.client_company_name.split(
                                " "
                              )[0][0] +
                              clientEditItem.client_company_name.split(" ")[
                                clientEditItem.client_company_name.split(" ")
                                  .length - 1
                              ][0]
                            : `${
                                clientEditItem.client_company_name.split(
                                  " "
                                )[0][0]
                              }${
                                clientEditItem.client_company_name.split(
                                  " "
                                )[0][1]
                              }`
                          : clientEditItem?.company_name
                          ? clientEditItem.company_name.split(" ").length > 1
                            ? clientEditItem.company_name.split(" ")[0][0] +
                              clientEditItem.company_name.split(" ")[
                                clientEditItem.company_name.split(" ").length -
                                  1
                              ][0]
                            : `${clientEditItem.company_name.split(" ")[0][0]}${
                                clientEditItem.company_name.split(" ")[0][1]
                              }`
                          : "-"}
                      </Box>
                    }
                    onChange={handleAvatarChange}
                    noEdit={clientEditItem?.client_type == "external"}
                    src={avatarUrl}
                    sx={{
                      width: 44,
                      height: 44,
                      backgroundColor: theme.palette.common.white,
                      border: `1px solid ${theme.palette.primary.main}`,
                      color: theme.palette.primary.main,
                      mr: "12px",
                      textTransform: "uppercase",
                    }}
                    slotProps={{
                      img: {
                        sx: {
                          width: 32,
                          height: 32,
                          objectFit: "contain",
                        },
                      },
                    }}
                  />
                  {imgError ? (
                    <div>
                      <label className="error">{imgError}</label>
                    </div>
                  ) : (
                    " "
                  )}
                </Box>

                <h5 className="modal-title2" id="commonSMModalLabel" style={{color:"white"}}>
                  {clientEditItem?.client_company_name ||
                    clientEditItem?.company_name ||
                    "-"}
                </h5>
              </Stack>
                    <Box sx={{ position: "absolute", right: 0 }}>
                    <IconButton
                    size="small"
                    onClick={() => {
                      setModalOpen(false);
                      resetForm({ values: initialValues });
                      getAdminUser();
                    }}
                    sx={{ color: 'black', '&:hover': { backgroundColor: '#552FBA' }, marginBottom: "4px",  display:"flex", justifyContent:"center", marginRight:"10px"}}
                    >
                    <SvgIcon
                        
                        component={CloseIcon2}
                        inheritViewBox
                    />
                    </IconButton>
                </Box>
                </div>

            
            <Box className="modal-body">
              <Form onSubmit={handleSubmit}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }}}
              autoComplete="off">
                <div>
                  <Stack
                    mb={1}
                    direction="row"
                    className="col-12"
                    justifyContent="space-between"
                  >
                    <div className="md-title dash-font-1-1em ad-gray-text mt-2 mb-3">
                      Company details
                    </div>
                    {
                      clientEditItem?.client_type != "external" &&
                      <Tooltip
                      slotProps={{ popper: { className: "tooltipPrimMain" } }}
                      title="Edit fields"
                    >
                      <IconButton
                        onClick={() => {
                          setEditMode(!editMode);
                        }}
                      >
                        <SvgIcon
                          className="ad-gray-text"
                          component={EditIcon}
                          inheritViewBox
                        />
                      </IconButton>
                    </Tooltip>
                    }
                  </Stack>
                  <Tooltip
                    title={editMode || clientEditItem?.client_type == "external"? "" : "Edit mode disabled"}
                    slotProps={{ popper: { className: "tooltipPrimMain" } }}
                    followCursor
                  >
                    <Box
                      className="custom-form ad-custom-form ad-form-theme2 row gx-3"
                      sx={{ opacity: editMode || clientEditItem?.client_type == "external"? 1 : 0.5, width: "100%" }}
                    >
                      <div className="col-md-6 col-12 form-group">
                        <div className="position-relative">
                          <label className="label-pos">Company name{clientEditItem?.client_type == "external"?"":"*"}</label>
                          <Field
                            type="text"
                            className="form-control"
                            name="company_name"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            disabled={editMode ? false : true}
                          />
                          <ValidationError name="company_name" />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 form-group">
                        <div className="position-relative">
                          <label className="label-pos">Client POC name{clientEditItem?.client_type == "external"?"":"*"}</label>
                          <Field
                            type="text"
                            className="form-control"
                            name="client_poc_name"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            disabled={editMode ? false : true}
                          />
                          <ValidationError name="client_poc_name" />
                        </div>
                      </div>
                      <div className="col-md-6 col-12 form-group">
                      <div className="position-relative">
                        <label className="label-pos">
                          Designation of POC{clientEditItem?.client_type == "external" ? "" : "*"}
                        </label>
                        <Field
                          name="designation_of_poc"
                          className="form-control"
                          as="select"
                          disabled={!editMode} 
                          onChange={(e) => {
                            setFieldValue("designation_of_poc", e.target.value);
                          }}
                        >
                          <option value="">Select Value</option>
                          {roleData &&
                            roleData.map((item, ind) => (
                              <option key={ind} value={item}>
                                {item}
                              </option>
                            ))}
                        </Field>
                        <ValidationError name="designation_of_poc" />
                      </div>
                    </div>


                      <div className="col-md-6 col-12 form-group">
                        <div className="position-relative">
                          <label className="label-pos">Client POC email{clientEditItem?.client_type == "external"?"":"*"}</label>
                          <Field
                            type="text"
                            className="form-control"
                            name="client_poc_email"
                            disabled
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                          <ValidationError name="client_poc_email" />
                        </div>
                      </div>
                    </Box>
                  </Tooltip>

                  <div className="col-12">
                    <div className="md-title ad-billing-border-top dash-font-1-1em ad-gray-text mt-2 mb-3">
                      Billing address
                    </div>
                  </div>
                  <Tooltip
                    title={editMode || clientEditItem?.client_type == "external" ? "" : "Edit mode disabled"}
                    slotProps={{ popper: { className: "tooltipPrimMain" } }}
                    followCursor
                  >
                    <Box
                      className="custom-form ad-custom-form ad-form-theme2 row gx-3"
                      sx={{ opacity: editMode || clientEditItem?.client_type == "external" ? 1 : 0.5, width: "100%" }}
                    >
                      <div className="col-md-12 col-12 form-group">
                        <div className="position-relative">
                          <label className="label-pos">
                            Company address line 1{clientEditItem?.client_type == "external"?"":"*"}
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            name="company_address_line_1"
                            disabled={editMode ? false : true}
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                          <ValidationError name="company_address_line_1" />
                        </div>
                      </div>
                      <div className="col-md-12 col-12 form-group">
                        <div className="position-relative">
                          <label className="label-pos">
                            Company address line 2
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            name="company_address_line_2"
                            disabled={editMode ? false : true}
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                          <ValidationError name="company_address_line_2" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="position-relative">
                            <label className="label-pos">Country{clientEditItem?.client_type == "external"?"":"*"}</label>
                            <SelectCountry
                              placeholder="Select Country"
                              name="authority_company_country"
                              data={countries}
                              disabled={editMode ? false : true}
                            />
                            {/* <ValidationError name="authority_company_country" /> */}
                          </div>
                        </div>
                      </div>
                      {/* Authority’s Company State */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="position-relative">
                            <label className="label-pos">State{clientEditItem?.client_type == "external"?"":"*"}</label>
                            <SelectState
                              name="authority_company_state"
                              placeholder="Select state"
                              component={SelectState}
                              disabled={editMode ? false : true}
                            />
                            <ValidationError name="authority_company_state" />
                          </div>
                        </div>
                      </div>
                      {/* Authority’s Company City */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="position-relative">
                            <label className="label-pos">City{clientEditItem?.client_type == "external"?"":"*"}</label>
                            <SelectCity
                              name="authority_company_city"
                              placeholder="Select city"
                              disabled={editMode ? false : true}
                            />
                            <ValidationError name="authority_company_city" />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-12 form-group">
                        <div className="position-relative">
                          <label className="label-pos">Pin code{clientEditItem?.client_type == "external"?"":"*"}</label>
                          <Field
                            type="text"
                            className="form-control"
                            name="pin_code"
                            disabled={!editMode}
                            // value={pinCode}
                            onChange={handleChange}
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                          {error && <div style={{ color: "red" }}>{error}</div>}
                        </div>
                      </div>
                      <div className="col-md-6 col-12 form-group">
                        <div className="position-relative">
                          <label className="label-pos">GST {clientEditItem?.client_type == "external"?"":"(Optional)"}</label>
                          <Field
                            type="text"
                            className="form-control"
                            name="gst_no"
                            disabled={editMode ? false : true}
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          />
                          {/* <ValidationError name="gst_no" /> */}
                        </div>
                      </div>
                      {
                        clientEditItem?.client_type != "external" &&
                        <div className="col-md-6 col-12 form-group">
                        <div className="position-relative">
                          <label className="label-pos">Billing email(s)</label>
                          <Field name="billing_email">
                            {({ field, form }) => (
                              <>
                                <Select
                                  ref={selectRef}
                                  isDisabled={!editMode}
                                  className="custom-select"
                                  placeholder="Type and enter to add emails"
                                  value={field.value}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      const email = e.target.value.trim();
                                      // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                                      if (email !== "") {
                                        const newEmail = {
                                          value:
                                            field.value.length > 0
                                              ? field.value[field.value.length - 1].value + 1
                                              : 0,
                                          label: email,
                                        };

                                        form.setFieldValue(
                                          "billing_email",
                                          field.value ? [...field.value, newEmail] : [newEmail]
                                        );
                                        setInputValue("");
                                        e.preventDefault();
                                      }
                                    }
                                  }}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      minHeight: "50px",
                                      background: theme.palette.common.white,
                                      border: `1px solid ${theme.palette.teritiary.teritiary15}`,
                                      borderRadius: "8px",
                                    }),
                                  }}
                                  inputValue={inputValue}
                                  onInputChange={(value, action) => {
                                    if (action.action === "input-change") setInputValue(value);
                                  }}
                                  components={{
                                    Menu: () => null,
                                    MenuList: () => null,
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                  }}
                                  isMulti
                                  onChange={(e) => {
                                    form.setFieldValue("billing_email", e);
                                  }}
                                />
                              </>
                            )}
                          </Field>
                          {/* <ErrorMessage
                            name="billing_email"
                            render={(msg) => (
                              <label htmlFor="billing_email" className="error">
                                {typeof msg === "object" ? "Invalid email format" : msg}
                              </label>
                            )}
                          /> */}
                        </div>
                      </div>

                      }
                      

                      {
                        clientEditItem?.client_type != "external" &&
                        <div className="mt-2 col-12">
                        {isProjectLoading ? (
                          <BtnSpinner cls="ad-theme-btn w-100" loader="true" />
                        ) : (
                          <button
                            className="ad-theme-btn w-100 ad-create-btn"
                            type="submit"
                            disabled={editMode ? false : true}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                      }
                      
                    </Box>
                  </Tooltip>
                </div>
                {/* <AddLeadModal
                  open={openAddProjectLead}
                  onClose={() => {
                    setOpenAddProjectLead(false);
                  }}
                  PaperProps={
                    {
                      sx: {
                        width: "448px",
                        maxWidth: "1108px",
                      }
                    }
                  }
                /> */}
              </Form>
            </Box>
          </>
        )}
      </Formik>
    </>
  );
};

export default ViewClientModal;
