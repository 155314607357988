import React from 'react'
import { Box } from '@mui/material';
import { Avatar } from '@mui/material';
import { Typography } from '@mui/material';
import {IconButton} from '@mui/material';
import {SvgIcon} from '@mui/material';
import { ReactComponent as Cross } from "../../assets/dashboard/img/cross-left.svg";

const UserInfo = ({ leftitem,onClose}) => {
  const {
    id,
    full_name,
    short_name,
    designation,
    experience,
  } = leftitem;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, backgroundColor: "#5F32BE", padding: "16px" , borderTopLeftRadius:"8px", borderTopRightRadius:"8px"}}>
      <Avatar sx={{ bgcolor: "red", width: 62, height: 62, border: '2px solid white' }}>{short_name}</Avatar>
      <Box sx={{ ml: 2 }}>
        <Typography component="div" sx={{ color: "white", fontWeight: "700", fontSize: "14px" }}>{full_name}</Typography>
        <Typography sx={{ color: "white", fontWeight: "500", fontSize: "14px" }}>{designation}</Typography>
        <Typography sx={{ color: "white", fontWeight: "500", fontSize: "14px" }}>{experience}</Typography>
      </Box>
      <Box sx={{marginLeft:'auto'}}>
            <IconButton
                size="small"
                onClick={onClose}
                sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
            >
                <SvgIcon
                    sx={{height:"29px",width:"29px",color:"white", pointerEvents: 'none'}}
                    component={Cross}
                    inheritViewBox
                    />
            </IconButton>
       </Box>
    </Box>
  )
}

export default UserInfo
