import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, SvgIcon, TextField } from "@mui/material";
import { Formik, Field, Form } from "formik";
import { ReactComponent as CloseIcon } from "../../assets/agency/img/close-icon-dynamic.svg";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import ValidationError from "../Errors/ValidationError";
import Select, { components, MultiValueGenericProps } from "react-select";
import { useRef } from "react";
import { useState } from "react";
import { useTheme } from "@mui/material";


const validationSchemaTeam = yup.object({
    to_email: yup
        .string()
        .email("Invalid email address")
        .required("Receiver email address is required"),
    cc_email: yup
        .string()
        .email("Invalid email address"),
});

const SendEmailModal = ({ onSubmit, billing_email,sendEmail, ...props }) => {
    const selectRef = useRef(null);
    const [inputValue, setInputValue] = useState(""); 
       
    
    const theme = useTheme();
    return (
        <div>
            <ToastContainer limit={1} hideProgressBar={true} />
            <Dialog {...props}>
                <Box sx={{backgroundColor:'#552FBA'}}>
                <DialogTitle sx={{color:'white', display:'flex',alignContent:'center',justifyContent:'center'}}>Email Invoice</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { props.onClose() }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <SvgIcon
                        color={theme.palette.common.white}
                        component={CloseIcon}
                    />
                </IconButton>
                </Box>
                <DialogContent sx={{ p: "20px", display: "flex", flexDirection: "column" }} dividers>
                    <Formik
                        enableReinitialize={true}
                        validationSchema={validationSchemaTeam}
                        initialValues={{
                            to_email: props.client_email || "",
                            billing_emails: billing_email || "",
                            cc_email: "" 
                        }}
                        

                        onSubmit={(values, { resetForm }) => {
                            const { to_email, cc_email, billing_emails, client_name } = values;
                            const emailList = billing_emails.split(',').map(email => email.trim());
                            const ccEmails = (typeof cc_email === 'string' ? cc_email.split(',').map(email => email.trim()) : cc_email) || [];
                            
                            const filteredCcEmails = ccEmails.filter(item => item !== null && item.trim() !== '');
                            if (filteredCcEmails.length > 0) {
                                emailList.push(...filteredCcEmails);
                            }
                        
                            const uniqueEmails = [...new Set(emailList)];
                        
                            console.log(uniqueEmails, 'hi there baby');
                            if (uniqueEmails.length > 0) {
                                sendEmail(to_email, uniqueEmails, client_name);
                                toast.success("Email Sent Successfully!");
                            } else {
                                toast.error("No email addresses provided.");
                            }
                        
                            resetForm();
                        }}
                        
                        
                        
                    >
                        {({ values, handleChange, handleBlur, touched, errors, handleSubmit }) => {
                            return (
                                <Form autoComplete="off">
                                    <Stack spacing="20px" className="custom-form ad-custom-form ad-form-theme2 row">
                                    <Box>
                                        <TextField
                                            fullWidth
                                            required
                                            disabled
                                            name="to_email"
                                            id="to_email"
                                            className='customOutlinedTextFieldVariant'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Mail to"
                                            value={values.to_email}
                                            error={touched.to_email && Boolean(errors.to_email)}
                                            helperText={touched.to_email && errors.to_email}
                                            InputProps={{
                                                placeholder: "Email"
                                            }}
                                        />
                                    </Box>


                                    {billing_email.length > 0 && (
                                        <>
                                        <Box>
                                            <TextField
                                            fullWidth
                                            required
                                            name="billing_emails"
                                            id="billing_emails"
                                            className='customOutlinedTextFieldVariant'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="CC Emails"
                                            value={values.billing_emails.split(',').join('\n')}
                                            InputProps={{
                                                placeholder: "Billing Emails"
                                            }}
                                            multiline 
                                            rows={4} 
                                            />
                                        </Box>

                                        
                                        </>
                                        )}

                                    {/* <Box>
                                        <TextField
                                            fullWidth
                                            multiline
                                            name="cc_email"
                                            id="cc_email"
                                            className='customOutlinedTextFieldVariant'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Add more emails"
                                            value={values.cc_email} 
                                            InputProps={{
                                                placeholder: ""
                                            }}
                                        />
                                    </Box> */}

                                    <Box>
                                        <div className="position-relative">
                                        <label className="label-pos">Add more emails</label>
                                        <Field name="cc_email">
                                            {({ field, form }) => (
                                            <>
                                                <Select
                                                as="Textfield"
                                                ref={selectRef}
                                                className="custom-select"
                                                placeholder=""
                                                // closeMenuOnSelect={true}
                                                value={values.cc_email}
                                                onKeyDown={(e) => {
                                                    console.log(e);
                                                    if (e.key === 'Enter') {
                                                    if (e.target.value != "") {
                                                        form.setFieldValue("cc_email", field.value == "" || !field?.value ? [{ value: 0, label: e.target.value }] : field.value.concat([{ value: field.value[field.value.length - 1].value + 1, label: e.target.value }]));
                                                    }
                                                    setInputValue("");
                                                    e.preventDefault();
                                                    }
                                                }}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    minHeight: "50px",
                                                    background: theme.palette.common.white,
                                                    border: `1px solid ${theme.palette.teritiary.teritiary15}`,
                                                    borderRadius: "8px",
                                                    }),
                                                }}
                                                inputValue={inputValue}
                                                onInputChange={(value, action) => {
                                                    if (action.action === "input-change") setInputValue(value);
                                                }}
                                                components={{
                                                    Menu: () => null,               
                                                    MenuList: () => null,           
                                                    DropdownIndicator: () => null, 
                                                    IndicatorSeparator: () => null,  
                                                  
                                                }}
                                                isMulti
                                                // options={skillsData}
                                                onChange={(e) => {
                                                    form.setFieldValue("cc_email", e);
                                                }}
                                                />
                                            </>
                                            )}
                                        </Field>
                                        <ValidationError name="cc_email" />
                                        </div>
                                        </Box>

                                    <Button fullWidth onClick={handleSubmit} variant='customButtonFilled1' autoFocus>
                                        Confirm
                                    </Button>
                                </Stack>
                                </Form>
                                
                            )
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default SendEmailModal;
