import NoDataFound from "../../assets/dashboard/img/gif/no_applicants_found.svg"
const NoRecordsFound = () => {
  return (
    <>
      <div className="nodata-found-block text-center">
        <div className="nodata-found-img">
          <img style={{width:"320px"}} src={NoDataFound} alt="NoDataFound"/>
        </div>
        {/* <div className="nodata-found-title">No Applicants Found</div> */}
      </div>
    </>
  )
}

export default NoRecordsFound