import { useState } from "react";
import AuthUser from "../../Services/AuthUser";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";

const AddressesList = ({ isAddressLoading, addressList, addressDeleteAlertModal, selectAddress, openEditAddress }) => {
    return (
        <>
            <div className="ad-md-title dash-font-1em mb-0 ad-gray-text ad-billing-border-top">Select address</div>
            <ul>
                {isAddressLoading ?
                    <Skeleton
                        containerClassName="cs-skeleton"
                        count={2}
                        height={60}
                    />
                    : addressList.length > 0 ?
                        addressList.map((item, index) =>
                            <li className="d-flex align-items-center" key={item.id}>
                                <div className="custom-radio ad-custom-radio">
                                    <input type="radio" id={index} name="address-group" onClick={()=>selectAddress(item)} />
                                    <label htmlFor={index}>
                                        <div>{item.address_line_1}, {item.address_line_2 ? `${item.address_line_2},` : ""} {item.city}, {item.state}, <br />{item.country}, {item.pin_code}</div>
                                    </label>
                                </div>
                                <div className="ms-auto ad-address-action-list">
                                    <button type="button" className="ad-edit-btn me-3" onClick={() => openEditAddress(item)}>
                                        <span className="ad-edit-icon"></span> 
                                    </button>
                                    {/* <button type="button" className="ad-delete-btn" onClick={() => addressDeleteAlertModal(item.id)}>
                                        <span className="ad-delete-icon"></span> Delete
                                    </button> */}
                                </div>
                            </li>
                        ) : ""
                }
            </ul>
        </>
    )
}

export default AddressesList