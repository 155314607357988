import { Field, Form, Formik } from "formik";
import AuthUser from "../../Services/AuthUser";
import { useRef } from "react";
import { Box } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FilterBilling = ({ filterData, isBilling, filterBilling, myFilter }) => {
    const { http2 } = AuthUser();
    let user = JSON.parse(localStorage.getItem("user"));

    const formikRef = useRef();
    const dropdownRef = useRef(); 

    const initialValues = {
        client_id: "",
        due_date: "",
        is_sort: "",
        start_date: "",
        end_date: "",
    };

    const validate = (values) => {
        const errors = {};
        if (values.start_date && !values.end_date) {
            errors.end_date = "Please select end date";
        }
        if (!values.start_date && values.end_date) {
            errors.start_date = "Please select start date";
        }
        return errors;
    };

    const getClients = (obj) => {
        let projects = obj?.projects ? obj.projects.map((val) => {
            return {
                ...val,
                isLeadsClient: "no"
            }
        }) : [];
        let clients = obj?.clients ? obj.clients.map((val) => {
            return {
                isLeadsClient: "yes",
                _id: val?._id || null,
                company_name: val?.company?.company_name || null
            }
        }) : [];

        return projects.concat(clients);
    };

    return (
        <>
            <div className="btn-group ad-custom-dropdown me-2 filter-block" style={{ marginLeft: "auto" }}>
                <button 
                    style={{ borderRadius: '5px' }} 
                    type="button" 
                    className="btn ad-gray-btn dropdown-toggle"
                    data-bs-toggle="dropdown" 
                    data-bs-auto-close="outside" 
                    aria-expanded="false" 
                    ref={dropdownRef} 
                >
                    <span className="ad-filter-icon"></span> Filters
                </button>
                <ul className="dropdown-menu dropdown-menu-end filter-box">
                    <Formik
                        innerRef={formikRef}
                        initialValues={initialValues}
                        validate={validate}
                        validateOnChange={false}  
                        validateOnBlur={false} 
                        onSubmit={(values, { resetForm }) => {
                            filterData(values);
                            dropdownRef.current.click(); 
                        }}
                    >
                        {({ values, setFieldValue, resetForm, errors, touched }) => (
                            <Form autoComplete="off">
                                <div className="custom-form ad-form-theme2">
                                    <div className="form-group">
                                        {user.is_invite_client !== "true" &&
                                            <Field
                                                as="select"
                                                name="client_id"
                                                className="form-control"
                                            >
                                                <option value="" disabled="disabled">
                                                    Select Client
                                                </option>
                                                {isBilling ? <option></option> :
                                                    getClients(cleintOptions).map((client) => (
                                                        <option key={client._id} value={`${client._id}-${client.isLeadsClient}`}>
                                                            {client?.project_name || client?.company_name || ""}
                                                        </option>
                                                    ))}
                                            </Field>
                                        }
                                    </div>
                                    
                                    {/* Start Date Picker */}
                                    <Box className="dash-calendar-icon">
                                        <Box sx={{
                                            "& .react-datepicker-wrapper": {
                                                width: "100%"
                                            }
                                        }} className="form-group">
                                            <DatePicker
                                                id="start_date"
                                                name="start_date"
                                                placeholderText="Start Date"
                                                className="form-control"
                                                selected={values.start_date}
                                                selectsStart
                                                onChange={(date) => setFieldValue("start_date", date)}
                                            />
                                            {errors.start_date && touched.start_date && (
                                                <label className="error">{errors.start_date}</label>
                                            )}
                                        </Box>
                                    </Box>

                                    {/* End Date Picker */}
                                    <Box className="dash-calendar-icon">
                                        <Box sx={{
                                            "& .react-datepicker-wrapper": {
                                                width: "100%"
                                            }
                                        }} className="form-group">
                                            <DatePicker
                                                id="end_date"
                                                name="end_date"
                                                placeholderText="End Date"
                                                className="form-control"
                                                selected={values.end_date}
                                                selectsEnd
                                                onChange={(date) => setFieldValue("end_date", date)}
                                            />
                                            {errors.end_date && touched.end_date && (
                                                <label className="error">{errors.end_date}</label>
                                            )}
                                        </Box>
                                    </Box>

                                    {/* Due Date Picker */}
                                    <Box className="dash-calendar-icon">
                                        <Box sx={{
                                            "& .react-datepicker-wrapper": {
                                                width: "100%"
                                            }
                                        }} className="form-group">
                                            <DatePicker
                                                id="due_date"
                                                name="due_date"
                                                placeholderText="Due date"
                                                className="form-control"
                                                selected={values.due_date}
                                                onChange={(date) => setFieldValue("due_date", date)}
                                            />
                                        </Box>
                                    </Box>
                                    
                                    <div className="form-group">
                                        <Field
                                            as="select"
                                            name="is_sort"
                                            className="form-control"
                                        >
                                            <option value="">Sort</option>
                                            <option value="newest">Newest</option>
                                            <option value="oldest">Oldest</option>
                                            <option value="A-Z">A-Z</option>
                                            <option value="Z-A">Z-A</option>
                                        </Field>
                                    </div>
                                    
                                    <Box sx={{display:"flex", gap:"5px"}}>
                                        <button className="ad-theme-btn ad-sm-btn w-100" type="submit">Apply</button>
                                        <button 
                                            style={{ padding: '3px 5px', fontSize: '13px', fontWeight: '500', borderRadius: '8px' }} 
                                            className="btn ad-gray-btn" 
                                            onClick={() => { resetForm(); myFilter(); }}
                                        >
                                            <span className="ad-clear-filter-icon"></span>
                                        </button>
                                    </Box>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ul>
            </div>
        </>
    );
};

export default FilterBilling;
