import axios from "axios";
import urlJson from "./ApiUrls";


export const useApiService = () => {

    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    let base_URL = process.env.REACT_APP_base_URL;
    let apiUrlJson = urlJson;

    const apiCall = axios.create({
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
        }
    });

    const apiCallNoType = axios.create({
        headers: {
            Authorization: `Bearer ${userToken}`,
        }
    });

    const signOutCall = axios.create({
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
        }
    });


    apiCall.interceptors.response.use((response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        console.log("interceptor not working");
        return response;
    }, (error) => {
        console.log("interceptor working");

        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);

        const { status } = error.response;

        if (status === 401) {
            signOutCall({
                method: "post",
                url: `/sign-out`,
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
            }).then(() => {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                localStorage.removeItem("hiringTalent");
                localStorage.removeItem("onboardingSignupData");
            }).catch(() => { });
        }

        return Promise.reject(error);
    });

    const postRequest = async (url, payload, config) => {

        try {
            const res = await apiCall.post(`${base_URL}${url}`, payload, config)

            return res;

        } catch (error) {

            console.log(error);
            return {
                isError: true,
                error: error
            };
        }
    };

    const deleteRequest = async (url, payload, config) => {

        try {
            const res = await apiCall.delete(`${base_URL}${url}`, payload, config)

            return res;

        } catch (error) {

            console.log(error);
            return {
                isError: true,
                error: error
            };
        }
    };

    const getRequest = async (url, config) => {

        try {
            const res = await apiCall.get(`${base_URL}${url}`, config)

            return res;

        } catch (error) {

            console.log(error);
            return error;
        }
    };

    const getRequestNonBaseNoType = async (url, config) => {

        try {
            const res = await apiCallNoType.get(`${url}`, config)

            return res;

        } catch (error) {

            console.log(error);
            return error;
        }
    };

    const getPostRequestNonBaseNoType = async (url, config) => {

        try {
            const res = await apiCallNoType.post(`${url}`, config)

            return res;

        } catch (error) {

            console.log(error);
            return error;
        }
    };

    return { getRequest, postRequest, deleteRequest ,getRequestNonBaseNoType, getPostRequestNonBaseNoType,...apiUrlJson };
}

const apiCall = axios.create();
const signOutCall = axios.create();

const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken;
};

apiCall.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log("interceptor not working");
    return response;
}, (error) => {
    console.log("interceptor working");

    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);

    const { status } = error.response;

    if (status === 401) {
        signOutCall({
            method: "post",
            url: `/sign-out`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${userToken}`,
            },
        }).then(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("hiringTalent");
            localStorage.removeItem("onboardingSignupData");
        }).catch(() => { });
    }

    return Promise.reject(error);
});

export default apiCall;