import { Field, Form, Formik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import ValidationError from "../Errors/ValidationError";
import { Box, NativeSelect, SvgIcon, Typography,IconButton } from "@mui/material";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { ReactComponent as Jira } from "../../assets/dashboard/img/jira_icon.svg";
import { ReactComponent as Weteams } from "../../assets/dashboard/img/weteams-svg.svg";
import AuthUser from "../../Services/AuthUser";
import { useEffect } from "react";
import { useState } from "react";
import {ReactComponent as asanaLogo} from "../../assets/dashboard/img/asana_inline_logo_svg.svg";
import {ReactComponent as jiraLogo} from "../../assets/dashboard/img/jira_inline_logo_svg.svg";
import { ReactComponent as DownArrow } from "../../assets/dashboard/img/down-arrow-2-dynamic.svg";
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as CloseIcon2 } from "../../assets/agency/img/close-icon-dynamic.svg";

const JiraStatusMap = ({ setModalOpen, project, syncRequest, type,currentId, projectPage }) => {
    const { http2 } = AuthUser();
    const [initialValues, setInitialValues] = useState({});
    const [dataLength, setDataLength] = useState(0);
    const [loader, setLoader] = useState(false);
    const [asanaDropdown, setAsanaDropdown] = useState([]);
    const [asanaCurrent, setAsanaCurrent] = useState("");

    const fetchJiraStatus = async (project_id) => {
        try {
            setLoader(true);
            let response = "";
            if(type == "jira"){
                response = await http2.get(`/get-jira-status/${project}`);
            }else{
                response = await http2.post(`/get-asana-status/${project}`,{project_id:project_id});
            }
            let data = response.data;
            let dataFirst = "";
            let dataSec = "";
            if(type == "jira"){
                dataFirst = data.data.jira_status; 
                dataSec = (Object.keys(data.data.db_Status).length != 0 ? data.data.db_Status : "");
            }else{
                dataFirst = data
            }
            const values = {};
            setLoader(false);
            console.log(data,dataSec);

            let newData = [type == "jira"?"To Do":"To do","In Progress","Done"];

            for(var val of dataFirst){
                if(val != "To do" && val != "To Do" && val != 'In Progress' && val != 'Done'){
                    newData.push(val);
                }
            }
            console.log(newData);
            newData.forEach((status, index) => {
                if(status == "To Do" || status == "To do"){
                    values[status] = status;
                    values["status-"+status] = "Pending";   
                }else if(status == "In Progress"){
                    values[status] = status;
                    values["status-"+status] = "In Progress";
                }else if(status == "Done"){
                    values[status] = status;
                    values["status-"+status] = "Completed";
                }else{
                    values[status] = status;
                    if(type == "jira"){
                        console.log("jira--------",dataSec[status],status,dataSec);
                        if(dataSec[status] == "To Do"){
                            values["status-"+status] = "Pending";
                        }else if(dataSec[status] == "In Progress"){
                            values["status-"+status] = "In Progress";
                        }else if(dataSec[status] == "Done"){
                            values["status-"+status] = "Completed";
                        }else{
                            values["status-"+status] = "";
                        }
                    }else{
                        values["status-"+status] = "";
                    }
                }
            });
            console.log(values);
            setInitialValues(values);
            setDataLength(newData.length);
        } catch (error) {
            console.error('Error fetching Jira status:', error);
        }
    };

    const fetchMiddleware = async () => {
        console.log("projectprojectprojectproject",project);
        let asanaDropdown;
        if(type == "asana"){
            setLoader(true);
            asanaDropdown = await http2.post(`/get-asana-project-dropdown/${project}`);
            setAsanaDropdown(asanaDropdown.data.data);
            setAsanaCurrent(asanaDropdown?.data?.data?.[0]?.gid);
        }

        if(project){
            fetchJiraStatus(type == "asana" ? asanaDropdown?.data?.data?.[0]?.gid : "");
        }
    }

    useEffect(() => {
        if(type == "jira" || type == "asana"){
            fetchMiddleware();
        }
    }, [project]);

    const getValidationSchema = (initVal) => {
        const validationSchema = {};
        for (let i in initVal) {
            if(i.includes("status")){
                validationSchema[i] = Yup.string().required("This Field is required!");
            }
        }
        return Yup.object().shape(validationSchema);
    };

    const handleSubmit = async (values, { resetForm }) => {
        const result = {};
        console.log("values",values);
        for (let i in values) {
            if(!i.includes("status")){
                if(values[`status-${i}`] == "Pending"){
                    result[i] = "To Do";
                }else if(values[`status-${i}`] == "In Progress"){
                    result[i] = "In Progress" ;
                }else if(values[`status-${i}`] == "Completed"){
                    result[i] = "Done" ;
                }
            }
        }

        let data = new FormData();
        data.append("status", JSON.stringify(result));

        try {
            const response = await http2.post(type == "jira"?`/map-jira-status/${project}`:`map-asana-status/${project}`, data);
            let resultData = response.data;
            if (resultData.success) {
                toast.success(resultData.message);
                resetForm();
                setModalOpen(false);
                
            } else {
                toast.error("Something went wrong");
            }
        } catch (error) {
            console.error('Error mapping Jira status:', error);
        }
    };

    return (
        <>
            {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
            

                    <div
                    className="modal-header"
                    style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#552FBA",
                    position: "relative",
                    borderTopLeftRadius: "5px", 
                    borderTopRightRadius: "5px", 
                    }}
                >
                    <h5
                    className="modal-title"
                    id="commonSMModalLabel"
                    style={{
                        flex: 1,
                        textAlign: "center",
                        color: "white",
                        margin: 0,
                    }}
                    >
                   Map {type=="asana"?"Asana":"Jira"} Status
                    </h5>
                    <Box sx={{ position: "absolute", right: 0 }}>
                    <IconButton
                    size="small"
                    onClick={() => {
                        setModalOpen(false);
                    }}
                    sx={{ color: 'black', '&:hover': { backgroundColor: '#552FBA' }, marginBottom: "4px",  display:"flex", justifyContent:"center", marginRight:"10px"}}
                    >
                    <SvgIcon
                        
                        component={CloseIcon2}
                        inheritViewBox
                    />
                    </IconButton>
                </Box>
                </div>


            {
                type == "asana" && projectPage &&
                <Box width="201px" pt="36px" pl="36px">
                <NativeSelect
                    sx={{
                        width: "201px",
                        border: "1px solid",
                        borderColor: "primary.main",
                        borderRadius: "4px",
                        backgroundColor: "primary.lighter",
                        padding: "8px 12px",
                        "&.MuiInputBase-root.MuiInput-root::before": {
                            border: "0px"
                        },
                        "&.MuiInputBase-root.MuiInput-root::after": {
                            border: "0px"
                        },
                        "& .MuiNativeSelect-icon": {
                            top: "calc(50% - 0.8em)",
                            mr: "12px",
                            color: "primary.main"
                        }
                    }}
                    inputProps={{
                        sx: {
                            height: "18px",
                            padding: "0px",
                            fontSize: "14px",
                            lineHeight: "18px",
                            color: "primary.main",
                            "&:focus": {
                                backgroundColor: "transparent"
                            }
                        }
                    }}
                    value={asanaCurrent}
                    onChange={(e)=>{
                        fetchJiraStatus(e.target.value);
                    }}
                    IconComponent={DownArrow}
                >
                    {
                        asanaDropdown && asanaDropdown?.length > 0 && asanaDropdown.map((sprint,index)=>{
                            return (
                                <option value={sprint.gid}>{sprint.name}</option>
                            );
                        })
                    }
                </NativeSelect>
            </Box>
            }
            
            <div className="modal-body">
                <Formik
                    enableReinitialize={true}
                    validationSchema={getValidationSchema(initialValues)}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form autoComplete="off">
                            <div className="custom-form ad-custom-form ad-form-theme2 row">
                                <div className="col-6 form-group">
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <SvgIcon sx={{width:"100px"}} component={type == "jira"?jiraLogo:asanaLogo} inheritViewBox />
                                        {/* <Typography sx={{ ml: "16px" }} variant="size5MediumCommonBlack">{type == "jira"?"Jira":"Asana"}</Typography> */}
                                    </Box>
                                </div>
                                <div className="col-6 form-group">
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <SvgIcon component={Weteams} inheritViewBox />
                                        <Typography sx={{ ml: "16px" }} variant="size5MediumCommonBlack">Weteams</Typography>
                                    </Box>
                                </div>
                                {
                                    loader && 
                                    <Box display="flex" justifyContent="center" p="15px">
                                        <CircularProgress color='primary'/>
                                    </Box>
                                }
                                {Object.keys(initialValues).filter((v)=>{return !v.includes("status")}).map((val, index) => {
                                    const isFixedStatus = ['To do','To Do', 'In Progress', 'Done'].includes(val);
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="col-6 form-group">
                                                <div className="position-relative">
                                                    <div className="position-relative">
                                                        <Field
                                                            type="text"
                                                            name={val}
                                                            className="form-control"
                                                            disabled
                                                        />
                                                    </div>
                                                    <ValidationError name={val} />
                                                </div>
                                            </div>
                                            <div className="col-6 form-group">
                                                <div className="position-relative">
                                                    <Field
                                                        as="select"
                                                        className="form-control"
                                                        name={`status-${val}`}
                                                        value={values[`status-${val}`]} 
                                                        disabled={isFixedStatus}
                                                        style={{ backgroundColor: isFixedStatus ? '#e9ecef' : 'white' }}
                                                        onChange={(e) => setFieldValue(`status-${val}`, e.target.value)} 
                                                    >
                                                        <option value=""></option>
                                                        <option value="In Progress">In Progress</option>
                                                        <option value="Pending">Pending</option>
                                                        <option value="Completed">Completed</option>
                                                    </Field>
                                                    <ValidationError name={`status-${val}`} />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                                <div className="mt-2 col-12">
                                    <Typography variant="size2MediumTextText1">Note: Generic statuses, TO DO, In Progress and Done are by default mapped to Pending, In Progress and Completed by default</Typography>
                                </div>
                                <div className="mt-2 col-12">
                                    <button className="ad-theme-btn w-100" type="submit">
                                        Update
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}

export default JiraStatusMap;
