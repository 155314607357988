import { createTheme } from '@mui/material/styles';
// import "@fontsource/poppins";
import Fade from '@mui/material/Fade';

/** MUI theme variables */
let variables = createTheme({
    palette: {
        primary: {
            main: "#552FBA",
            lighter: "#EBE0FE",
            morelighter: "#F5F5F5",
            extremelighter: "#F8F4FE",
        },
        secondary: {
            main: "#B5179E",
        },
        error: {
            main: "#ED3342"
        },
        teritiary: {
            teritiary1: "#219653",
            teritiary2: '#2196530d',
            teritiary3: "#56CCF2",
            teritiary4: "#56ccf21a",
            teritiary5: "#24BFA8",
            teritiary6: "#CED1DA",
            teritiary7: "#6e4ec4",
            teritiary8: "#828282",
            teritiary9: "#00000099",
            teritiary10: "#24BFA8",
            teritiary11: "#F5F5F5",
            teritiary12: "#EF529B",
            teritiary13: "rgba(0, 0, 0, 0.05)",
            teritiary14: "#F7F7FD",
            teritiary15: "#C5C0DB",
            teritiary16: "#2D9CDB",
            teritiary17: "#F2994A",
            teritiary18: "#303030",
            teritiary19: "#464646",
            teritiary20: "#878787",
            teritiary21: "#F2F2F2",
            teritiary22: "#CFBEF3",
            teritiary23: "#EDB62E",
            teritiary24: "#2F80ED",
            teritiary25: "#FFBF00",
            teritiary26: "#d3f2ee",
            teritiary27: "#5757571a",
            teritiary28: "#C233DC",
            teritiary29: "#FF7684",
            teritiary30: "#7653d6",
            teritiary31: "#101808",
            teritiary32: "#EAECF0",
            teritiary33: "#6941C6",
            teritiary34: "#667085",
            teritiary35: "#9E77ED",
            teritiary36: "#344054",
            teritiary37: "#605DEC",
            teritiary38: "#E0E0E0",
            teritiary39: "#00000040"
        },
        text: {
            text1: "#575757",
            text2: "#898989",
            text3: "#1a1a1a",
            text4: "#333333",
            text5: "#1A1A1A",
            text6: "#F5F5F5",
            text7: "#4F4F4F",
        },
        backdrop: {
            backdrop1: 'rgba(26, 26, 26, 0.9)'
        }
    },
    typography: {
        fontFamily:
            "Poppins",
        size1: "10px",
        size1_5: "11px",
        size2: "12px",
        size2_5: "13px",
        size3: "14px",
        size4: "16px",
        size5: "18px",
        size6: "20px",
        size7: "22px",
        size7_5:"23px",
        size8: "24px",
        size8_5: "25px",
        sizexl: "35px",
        sizexxl: "70px",
        size0_8em: "0.8em"
    },
    typographyCustom: {
        fontFamily: "Poppins, sans-serif"
    }
});

/** Main MUI theme config object */
let theme = createTheme(variables, {
    // overrides: {
    //     MuiCssBaseline: {
    //         '@global': {
    //             '@font-face': [Poppins],
    //         },
    //     },
    // },
    typography: {
        size1Medium: {
            fontSize: variables.typography.size1,
            fontWeight: 500,
        },
        size1MediumCommonWhite: {
            fontSize: variables.typography.size1,
            fontWeight: 500,
            color: variables.palette.common.white,
        },
        size1_5SemiboldCommonWhite: {
            fontSize: variables.typography.size1_5,
            fontWeight: 600,
            color: variables.palette.common.white,
        },

        size2Regular: {
            fontSize: variables.typography.size2,
            fontWeight: 400,
        },
        size2RegularTextText4: {
            fontSize: variables.typography.size2,
            fontWeight: 400,
            color: variables.palette.text.text4,
        },
        size0_8emRegularTextText4: {
            fontSize: variables.typography.size0_8em,
            fontWeight: 400,
            color: variables.palette.text.text4,
        },
        size2RegularTerTer9: {
            fontSize: variables.typography.size2,
            fontWeight: 400,
            color: variables.palette.teritiary.teritiary9,
        },
        sizeleadRegularTer: {
            fontSize: '12px',
            fontWeight: 500,
            color: variables.palette.text.text2,

        },
        sizeleadRegularTer2: {
            fontSize: '13px',
            fontWeight: 400,
            color: variables.palette.text.text7,

        },
        size2RegularTerTer8: {
            fontSize: variables.typography.size2,
            fontWeight: 400,
            color: variables.palette.teritiary.teritiary8,
        },
        size2MediumTextText1: {
            fontSize: variables.typography.size2,
            fontWeight: 500,
            color: variables.palette.text.text1,
        },
        size2MediumTextText2: {
            fontSize: variables.typography.size2,
            fontWeight: 500,
            color: variables.palette.text.text2,
        },
        size2MediumTextText3: {
            fontSize: variables.typography.size2,
            fontWeight: 500,
            color: variables.palette.teritiary.teritiary8,
        },
        size2MediumCommonWhite: {
            fontSize: variables.typography.size2,
            fontWeight: 500,
            color: variables.palette.common.white,
        },
        size2Semibold: {
            fontSize: variables.typography.size2,
            fontWeight: 600,
        },
        size2_5RegularTerTer20: {
            fontSize: variables.typography.size2_5,
            fontWeight: 400,
            color: variables.palette.teritiary.teritiary20,
        },
        size2_5MediumGradient1: {
            fontSize: variables.typography.size2_5,
            fontWeight: 500,
            background : `linear-gradient(to right, ${variables.palette.primary.main} , ${variables.palette.teritiary.teritiary28} , ${variables.palette.teritiary.teritiary29} )`,
            "-webkit-text-fill-color": "transparent",
            "-webkit-background-clip": "text"
        },
        size2BoldTerTer30: {
            fontSize: variables.typography.size2,
            fontWeight: 700,
            color: variables.palette.teritiary.teritiary30,
        },

        size3SemiboldMy: {
            fontSize: variables.typography.size2,
            fontWeight: 400,
            color: variables.palette.teritiary.teritiary9,
        },
        size3RegularTerTer9: {
            fontSize: variables.typography.size3,
            fontWeight: 400,
            color: variables.palette.teritiary.teritiary9,
        },
        size3RegularTerTer20: {
            fontSize: variables.typography.size3,
            fontWeight: 400,
            color: variables.palette.teritiary.teritiary20,
        },
        size3RegularCommonBlack: {
            fontSize: variables.typography.size3,
            fontWeight: 400,
            color: variables.palette.common.black,
        },
        size3RegularTextText3: {
            fontSize: variables.typography.size3,
            fontWeight: 400,
            color: variables.palette.text.text3,
        },
        size3RegularTerTer34: {
            fontSize: variables.typography.size3,
            fontWeight: 400,
            color: variables.palette.teritiary.teritiary34,
        },
        size3Medium: {
            fontSize: variables.typography.size3,
            fontWeight: 500,
        },
        size3MediumTerTer20: {
            fontSize: variables.typography.size3,
            fontWeight: 500,
            color: variables.palette.teritiary.teritiary20,
        },
        size3MediumCommonWhite: {
            fontSize: variables.typography.size3,
            fontWeight: 500,
            color: variables.palette.common.white,
        },
        size3MediumTextText1: {
            fontSize: variables.typography.size3,
            fontWeight: 500,
            color: variables.palette.text.text1,
        },
        size3MediumTerTer8: {
            fontSize: variables.typography.size3,
            fontWeight: 500,
            color: variables.palette.teritiary.teritiary8,
        },
        size3MediumTerTer9: {
            fontSize: variables.typography.size3,
            fontWeight: 500,
            color: variables.palette.teritiary.teritiary9,
        },
        size3MediumTextText5: {
            fontSize: variables.typography.size3,
            fontWeight: 500,
            color: variables.palette.text.text5,
        },
        size3MediumTerTer19: {
            fontSize: variables.typography.size3,
            fontWeight: 500,
            color: variables.palette.teritiary.teritiary19,
        },
        size3MediumTerTer36: {
            fontSize: variables.typography.size3,
            fontWeight: 500,
            color: variables.palette.teritiary.teritiary36,
        },
        size3Semibold: {
            fontSize: variables.typography.size3,
            fontWeight: 600,
        },
        size3SemiboldTextText1: {
            fontSize: variables.typography.size3,
            fontWeight: 600,
            color: variables.palette.text.text1,
        },
        size3SemiboldTextText2: {
            fontSize: variables.typography.size3,
            fontWeight: 600,
            color: variables.palette.text.text2,
        },
        size3SemiboldTextText3: {
            fontSize: variables.typography.size3,
            fontWeight: 600,
            color: variables.palette.text.text3,
        },
        size3SemiboldTextText5: {
            fontSize: variables.typography.size3,
            fontWeight: 600,
            color: variables.palette.text.text5,
        },
        size3SemiboldPrimMain: {
            fontSize: variables.typography.size3,
            fontWeight: 600,
            color: variables.palette.primary.main
        },
        size4SemiboldPrimMain: {
            fontSize: '12px',
            fontWeight: 600,
            color: variables.palette.primary.main

        },
        size3SemiboldTerTer33: {
            fontSize: variables.typography.size3,
            fontWeight: 600,
            color: variables.palette.teritiary.teritiary33
        },


        size4MediumTerTer8: {
            fontSize: variables.typography.size4,
            fontWeight: 500,
            color: variables.palette.teritiary.teritiary8,
        },
        size4MediumTextText5: {
            fontSize: variables.typography.size4,
            fontWeight: 500,
            color: variables.palette.text.text5,
        },
        size4MediumCommonWhite: {
            fontSize: variables.typography.size4,
            fontWeight: 500,
            color: variables.palette.common.white,
        },
        size4Semibold: {
            fontSize: variables.typography.size4,
            fontWeight: 600,
        },
        size4SemiboldTextText3: {
            fontSize: variables.typography.size4,
            fontWeight: 600,
            color: variables.palette.text.text3,
        },
        size4SemiboldCommonBlack: {
            fontSize: variables.typography.size4,
            fontWeight: 600,
            color: variables.palette.common.balck,
        },
        size4SemiboldTextText1: {
            fontSize: variables.typography.size4,
            fontWeight: 600,
            color: variables.palette.text.text1,
        },

        size5MediumTextText5: {
            fontSize: variables.typography.size5,
            fontWeight: 500,
            color: variables.palette.text.text5,
        },
        size5MediumTextText2: {
            fontSize: variables.typography.size5,
            fontWeight: 500,
            color: variables.palette.text.text2,
        },
        size5MediumCommonBlack: {
            fontSize: variables.typography.size5,
            fontWeight: 500,
            color: variables.palette.common.black,
        },
        size5SemiboldTextText5: {
            fontSize: variables.typography.size5,
            fontWeight: 600,
            color: variables.palette.text.text5,
        },
        size5SemiboldTextText1: {
            fontSize: variables.typography.size5,
            fontWeight: 600,
            color: variables.palette.text.text1,
        },
        size5SemiboldTerTer31: {
            fontSize: variables.typography.size5,
            fontWeight: 600,
            color: variables.palette.teritiary.teritiary31,
        },

        size6RegularTerTer37: {
            fontSize: variables.typography.size6,
            fontWeight: 400,
            color: variables.palette.teritiary.teritiary37,
        },

        size7MediumTerTer18: {
            fontSize: variables.typography.size7,
            fontWeight: 500,
            color: variables.palette.teritiary.teritiary18,
        },
        size7MediumTextText5: {
            fontSize: variables.typography.size7,
            fontWeight: 500,
            color: variables.palette.text.text5,
        },
        size7_5BoldCommonWhite: {
            fontSize: variables.typography.size7_5,
            fontWeight: 500,
            color: variables.palette.common.white,
        },

        size8BoldCommonWhite: {
            fontSize: variables.typography.size8,
            fontWeight: 700,
            color: variables.palette.common.white,
        },
        size8_5RegularTerTer20: {
            fontSize: variables.typography.size8_5,
            fontWeight: 400,
            color: variables.palette.teritiary.teritiary20,
        },
        size8_5RegularTextText4: {
            fontSize: variables.typography.size8_5,
            fontWeight: 400,
            color: variables.palette.text.text4,
        },

        sizexlRegularTextText4: {
            fontSize: variables.typography.sizexl,
            fontWeight: 400,
            color: variables.palette.text.text4,
        },

        sizexxlRegularTextText4: {
            fontSize: variables.typography.sizexxl,
            fontWeight: 400,
            color: variables.palette.text.text4,
        },
    },


    components: {
        MuiChip: {
            variants: [
                {
                    props: { variant: "talentInfoTooltipChip" },
                    style: {
                        color: "inherit",
                        backgroundColor: variables.palette.teritiary.teritiary7,
                        borderRadius: "9px"
                    }
                },
                {
                    props: { variant: "skillsChip" },
                    style: {
                        fontSize: '0.9em',
                        fontWeight: '500',
                        fontFamily: variables.typographyCustom.fontFamily,
                        paddingLeft: '13px',
                        paddingRight: '13px',
                        color: variables.palette.primary.main,
                        backgroundColor: variables.palette.primary.lighter
                    }
                },
                {
                    props: { variant: "teamListChip" },
                    style: {
                        marginRight: "4px",
                        marginBottom: "7px",
                        marginTop: "6px",
                        display: "inline-block",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        background: variables.palette.teritiary.teritiary27,
                        color: variables.palette.text.text1,
                        fontSize: "11px",
                        fontWeight: 500,
                        height: "auto"
                    }
                },
                {
                    props: { variant: "dateChip1" },
                    style: {
                        fontSize: '0.8em',
                        fontWeight: '600',
                        fontFamily: variables.typographyCustom.fontFamily,
                        color: variables.palette.teritiary.teritiary1,
                        backgroundColor: variables.palette.teritiary.teritiary2,
                        maxWidth: "fit-content"
                    }
                },
                {
                    props: { variant: "dateChip2" },
                    style: {
                        fontSize: '0.8em',
                        fontWeight: '600',
                        fontFamily: variables.typographyCustom.fontFamily,
                        color: variables.palette.teritiary.teritiary3,
                        backgroundColor: variables.palette.teritiary.teritiary4,
                        maxWidth: "fit-content"
                    }
                },
                {
                    props: { variant: "lockChip1" },
                    style: {
                        fontSize: '0.8em',
                        fontWeight: '500',
                        fontFamily: variables.typographyCustom.fontFamily,
                        color: variables.palette.common.white,
                        backgroundColor: variables.palette.teritiary.teritiary5,
                    }
                },
                {
                    props: { variant: "InterviewConfirmChip" },
                    style: {
                        fontSize: '0.8em',
                        fontWeight: '500',
                        fontFamily: variables.typographyCustom.fontFamily,
                        color: variables.palette.primary.main,
                        backgroundColor: variables.palette.primary.lighter
                    }
                },

            ]
        },
        MuiPaper: {
            variants: [
                {
                    props: { variant: "leadsCard" },
                    style: {
                        padding: '20px',
                        marginTop: '10px',
                        borderRadius: variables.shape.borderRadius * 2
                    }
                },
                {
                    props: { variant: "customCard1" },
                    style: {
                        padding: '16px',
                        borderRadius: variables.shape.borderRadius * 2,
                        border: `1px solid ${variables.palette.teritiary.teritiary6}`
                    }
                },
                {
                    props: { variant: "leadsCardMain" },
                    style: {
                        padding: '26px 20px ',
                        marginBottom: '10px',
                        borderRadius: variables.shape.borderRadius * 2,
                        border: `1px solid ${variables.palette.teritiary.teritiary6}`
                    }
                },
                {
                    props: { variant: "leadsEstimateCard" },
                    style: {
                        backgroundColor: variables.palette.primary.extremelighter,
                        padding: "12px",
                        borderRadius: variables.shape.borderRadius,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }
                },
                {
                    props: { variant: "customOtpInputs" },
                    style: {
                        marginTop: "1rem",
                        "& input": {
                            borderRadius: "5px",
                            border: `1px solid ${variables.palette.teritiary.teritiary15}`,
                            width: "44px",
                            height: "44px",
                            textAlign: "center"
                        },
                        "& span": {
                            color: variables.palette.text.text4
                        }
                    }
                },
            ]
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true, // No more ripple, on the whole application 💣!
            },
        },
        // MuiIconButton:{
        //     styleOverrides:{
        //         root:{   

        //         }
        //     }
        // },
        MuiButton: {
            variants: [
                {
                    props: { variant: "viewMoreButton" },
                    style: {
                        textTransform: "none",
                        textDecoration: "underline",
                        textDecorationStyle: "dashed",
                        textUnderlineOffset: "10px",
                        whiteSpace: "nowrap",
                        minWidth: "100%",
                        color: variables.palette.primary.main,
                        marginTop: "-6px",
                        "&:hover": {
                            backgroundColor: "transparent",
                            textDecoration: "underline",
                            textDecorationStyle: "dashed",
                            textUnderlineOffset: "10px",
                        }
                    }
                },
                {
                    props: { variant: "Selected1" },
                    style: {
                        backgroundColor: variables.palette.primary.main,
                        color: variables.palette.common.white,
                        borderRadius: '24px',
                        padding: "12px 24px",
                        lineHeight: "20px",
                        "&:hover": {
                            backgroundColor: variables.palette.primary.main,
                            color: variables.palette.common.white,
                        }
                    }
                },

                {
                    props: { variant: "TeamSearch" },
                    style: {
                        backgroundColor: variables.palette.primary.main,
                        color: variables.palette.common.white,
                        borderRadius: '8px',
                        padding: "8px 16px",
                        lineHeight: "15px",
                        "&:hover": {
                            backgroundColor: variables.palette.primary.main,
                            color: variables.palette.common.white,
                        }
                    }
                },


                {
                    props: { variant: "NotSelected1" },
                    style: {
                        border: `1px solid ${variables.palette.primary.main}`,
                        backgroundColor: variables.palette.primary.lighter,
                        color: variables.palette.primary.main,
                        lineHeight: "20px",
                        padding: "12px 24px",
                        borderRadius: '24px',
                    }
                },

                {
                    props: { variant: "NotSelected2" },
                    style: {
                        border: `1px solid ${variables.palette.primary.main}`,
                        backgroundColor: variables.palette.primary.extremelighter,
                        color: variables.palette.primary.main,
                        lineHeight: "20px",
                        padding: "12px 24px",
                        borderRadius: '24px',
                        minWidth:"119px"
                    }
                },

                {
                    props: { variant: "Selected2" },
                    style: {
                        backgroundColor: variables.palette.primary.main,
                        color: variables.palette.common.white,
                        borderRadius: '24px',
                        padding: "12px 24px",
                        lineHeight: "20px",
                        minWidth:"119px"
                    }
                },
                {
                    props: { variant: "Selected" },
                    style: {
                        backgroundColor: variables.palette.primary.main,
                        color: variables.palette.common.white,
                        borderRadius: '24px',
                        paddingLeft: variables.spacing(3.5),
                        paddingRight: variables.spacing(3.5),
                        "&:hover": {
                            backgroundColor: variables.palette.primary.main,
                            color: variables.palette.common.white,
                        }
                    }
                },

                {
                    props: { variant: "NotSelected" },
                    style: {
                        border: `1px solid ${variables.palette.primary.main}`,
                        backgroundColor: variables.palette.primary.lighter,
                        color: variables.palette.primary.main,
                        paddingLeft: variables.spacing(3.5),
                        paddingRight: variables.spacing(3.5),
                        borderRadius: '24px',
                    }
                },

                
                {
                    props: { variant: "Match" },
                    style: {
                        border: `1px solid transparent`,
                        borderRadius: '8px',
                        backgroundColor: variables.palette.teritiary.teritiary10,
                        color: variables.palette.common.white,
                        "&:hover": {
                            color: variables.palette.teritiary.teritiary10,
                            borderColor: variables.palette.teritiary.teritiary10,
                            borderRadius: '10px'
                        }
                    }
                },

                {
                    props: { variant: "LeadMatch" },
                    style: {
                        border: `1px solid transparent`,
                        backgroundColor: variables.palette.primary.main,
                        color: variables.palette.common.white,
                        borderRadius: '8px',
                        paddingLeft: variables.spacing(5.5),
                        paddingRight: variables.spacing(5.5),
                        "&:hover": {
                            backgroundColor: variables.palette.common.white,
                            color: variables.palette.primary.main,
                            borderColor:variables.palette.primary.main
                        }
                    }
                },

                {
                    props: { variant: "LeadMatch2" },
                    style: {
                        border: `1px solid transparent`,
                        backgroundColor: variables.palette.teritiary.teritiary5,
                        color: variables.palette.common.white,
                        borderRadius: '8px',
                        paddingLeft: variables.spacing(4.3),
                        paddingRight: variables.spacing(4.3),
                        "&:hover": {
                            backgroundColor: variables.palette.common.white,
                            color: variables.palette.teritiary.teritiary5,
                            borderColor:variables.palette.teritiary.teritiary5
                        }
                    }
                },




                {
                    props: { variant: "Disabled" },
                    style: {
                        border: `1px solid ${variables.palette.primary.main}`,
                        backgroundColor: variables.palette.primary.lighter,
                        color: variables.palette.primary.main,
                        lineHeight: "20px",
                        padding: "12px 24px",
                        borderRadius: '24px',
                        opacity:0.9
                    }
                },
                {
                    props: { variant: "Match2" },
                    style: {
                        border: `1px solid transparent`,
                        borderRadius: '8px',
                        backgroundColor: variables.palette.primary.main,
                        color: variables.palette.common.white,
                        "&:hover": {
                            color: variables.palette.primary.main,
                            borderColor: variables.palette.primary.main,
                            borderRadius: '10px'
                        }
                    }

                },

                {
                    props: { variant: "Interview1" },
                    style: {
                        border: `1px solid transparent`,
                        borderRadius: '8px',
                        width:"200px",
                        height:"50px",
                        backgroundColor: variables.palette.primary.main,
                        color: variables.palette.common.white,
                        "&:hover": {
                            color: variables.palette.primary.main,
                            borderColor: variables.palette.primary.main,
                            borderRadius: '8px'
                        }
                    }

                },

                {
                    props: { variant: "Interview2" },
                    style: {
                        border: `1px solid #898989`,
                        borderRadius: '8px',
                        width:"200px",
                        height:"50px",
                        backgroundColor: variables.palette.common.white,
                        color: variables.palette.text.text2,
                    }

                },



                {
                    props: { variant: "customButtonFilled1" },
                    style: {
                        textTransform: "capitalize",
                        borderRadius: variables.shape.borderRadius * 2,
                        backgroundColor: variables.palette.primary.main,
                        color: variables.palette.common.white,
                        lineHeight: "21px",
                        fontSize: variables.typography.size4,
                        fontWeight: 600,
                        padding: "14px 71px",
                        "&:hover": {
                            backgroundColor: variables.palette.primary.main,
                            color: variables.palette.common.white,
                        }
                    }
                },
                {
                    props: { variant: "customButtonOutlined1" },
                    style: {
                        textTransform: "capitalize",
                        borderRadius: variables.shape.borderRadius * 2,
                        backgroundColor: variables.palette.common.white,
                        color: variables.palette.text.text1,
                        border: `1px solid ${variables.palette.teritiary.teritiary6}`,
                        lineHeight: "21px",
                        fontSize: variables.typography.size4,
                        fontWeight: 600,
                        padding: "14px 71px",
                        "&:hover": {
                            backgroundColor: variables.palette.common.white,
                            color: variables.palette.text.text1,
                        }
                    }
                },
                {
                    props: { variant: "custErrBtnOutl" },
                    style: {
                        textTransform: "capitalize",
                        borderRadius: variables.shape.borderRadius * 2,
                        backgroundColor: variables.palette.common.white,
                        color: variables.palette.error.main,
                        border: `1px solid ${variables.palette.error.main}`,
                        lineHeight: "21px",
                        fontSize: variables.typography.size4,
                        fontWeight: 600,
                        padding: "14px",
                        width: "200px",
                        "&:hover": {
                            backgroundColor: variables.palette.common.white,
                            color: variables.palette.error.main,
                        }
                    }
                }
            ]

        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: variables.palette.backdrop.backdrop1,
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {

                },
                paper: {
                    borderRadius: variables.shape.borderRadius * 2,
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    color: variables.palette.text.text3,
                    fontSize: variables.typography.size5,
                    fontWeight: 600
                }
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                dividers: {
                    borderColor: variables.palette.teritiary.teritiary6,
                }
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    color: '#000',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    height: '56px',
                    width: '268px',
                    borderRadius: '8px',
                    borderColor: '#CED1DA',

                },
            },

        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    marginLeft: "-13px",
                    width: "100%"
                }
            }
        },
        // MuiOutlinedInput: {
        //     defaultProps: {
        //         sx: {
        //             borderColor: variables.palette.teritiary.teritiary6,
        //             "&.Mui-focused": {
        //                 "& .MuiOutlinedInput-notchedOutline": {
        //                     borderColor: "inherit",
        //                     borderWidth: "1px"
        //                 },
        //             },
        //             "&:hover": {
        //                 "& .MuiOutlinedInput-notchedOutline": {
        //                     borderColor: "inherit"
        //                 },
        //             }
        //         },
        //     }
        // },
        MuiTextField: {
            styleOverrides: {
                root: {
                    "&.customOutlinedTextFieldVariant": {
                        "&>.MuiOutlinedInput-root": {
                            borderColor: variables.palette.teritiary.teritiary6,
                            borderRadius: variables.shape.borderRadius * 2,
                            paddingLeft: 0,
                            "& .MuiInputAdornment-root": {
                                "& .MuiInputBase-root": {
                                    height: "48px",
                                    width: "105px",
                                    "& .MuiInputBase-input": {
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        height: "auto",
                                        "& .customIntlPhnNoInput-flagDialCode-par": {
                                            alignItems: "center",
                                            justifyContent: "center",
                                            "& .customIntlPhnNoInput-dialCode": {
                                                paddingLeft: "3px"
                                            }
                                        }
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderWidth: 0,
                                        borderRightWidth: "1px",
                                        borderRadius: 0
                                    }
                                }
                            },
                            "& .MuiIconButton-root": {
                                color: variables.palette.text.text2,
                            },
                            // "&.Mui-focused": {
                            //     "& .MuiOutlinedInput-notchedOutline": {
                            //         borderColor: "inherit",
                            //         borderWidth: "1px"
                            //     },
                            // },
                            // "&:hover": {
                            //     "& .MuiOutlinedInput-notchedOutline": {
                            //         borderColor: "inherit"
                            //     },
                            // },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "inherit",
                                borderWidth: "1px"
                            },
                            "&>.MuiInputBase-input": {
                                padding: "15px 14px",
                                fontSize: "14px",
                                height: "18px",
                                color: variables.palette.text.text5,
                                "-webkit-text-fill-color": variables.palette.text.text5,
                                "&::placeholder": {
                                    color: variables.palette.text.text2,
                                    "-webkit-text-fill-color": variables.palette.text.text2,
                                    opacity: 1
                                }
                            },
                            "&.Mui-disabled": {
                                backgroundColor: variables.palette.teritiary.teritiary11,
                            }
                        }
                    },
                    "&.customIntlPhnNoInput": {
                        backgroundColor: variables.palette.teritiary.teritiary14,
                        "& .MuiInputBase-root": {
                            paddingLeft: 0,
                            "&>.MuiInputBase-input": {
                                paddingLeft: "20px"
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            "& .MuiInputAdornment-root": {
                                marginRight: "0px",
                                "& .MuiInputBase-root": {
                                    width: "105px",
                                    borderRadius: 0,
                                    backgroundColor: variables.palette.teritiary.teritiary13,
                                    "& .MuiInputBase-input": {
                                        "& .customIntlPhnNoInput-flagDialCode-par": {
                                            alignItems: "center",
                                            justifyContent: "center",
                                            "& .customIntlPhnNoInput-dialCode": {
                                                paddingLeft: "3px"
                                            }
                                        }
                                    },
                                }
                            }
                        }
                    },
                    "&.AutocompletecustomOutlinedTextFieldVariant": {
                        '& .MuiInputBase-root': {
                            maxHeight: "44px",
                            borderRadius: variables.shape.borderRadius * 2,
                            "&:hover": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: variables.palette.teritiary.teritiary6,
                                }
                            },
                            "&.Mui-disabled": {
                                backgroundColor: variables.palette.teritiary.teritiary11,
                            },
                            "& .MuiIconButton-root": {
                                color: variables.palette.text.text2,
                            },
                            "&.Mui-focused": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: variables.palette.teritiary.teritiary6,
                                    borderWidth: "1px"
                                },
                            },
                        },
                    },

                }
            },
            defaultProps: {
                InputLabelProps: {
                    shrink: true,
                    sx: {
                        "&.MuiFormLabel-root":{
                            color: variables.palette.text.text2,
                            "& .MuiInputLabel-asterisk": {
                                color: variables.palette.error.main
                            },
                            "&.Mui-focused": {
                                color: variables.palette.text.text2
                            },
                            "&.Mui-disabled": {
                                color: variables.palette.text.text2
                            }
                        }
                    }
                },
            }
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                TransitionComponent: Fade,
                TransitionProps: {
                    timeout: 600
                }
            },
            styleOverrides: {
                // tooltip: {
                //     backgroundColor: variables.palette.primary.main,
                // },
                // arrow: {
                //     color: variables.palette.primary.main
                // }
                popper: {
                    "&.tooltipTerTer12": {
                        "& .MuiTooltip-tooltip": {
                            backgroundColor: variables.palette.teritiary.teritiary12
                        },
                        "& .MuiTooltip-arrow": {
                            color: variables.palette.teritiary.teritiary12
                        }
                    },
                    "&.tooltipPrimMain": {
                        "& .MuiTooltip-tooltip": {
                            backgroundColor: variables.palette.primary.main
                        },
                        "& .MuiTooltip-arrow": {
                            color: variables.palette.primary.main
                        }
                    },
                    "&.tooltipPrimMainLeft": {
                        "& .MuiTooltip-tooltip": {
                            backgroundColor: variables.palette.primary.main,
                            fontSize:"14px"
                        },
                        "& .MuiTooltip-arrow": {
                            color: variables.palette.primary.main
                        }
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        color: variables.palette.text.text4
                    },
                    fontSize: "1em",
                    minHeight: 0,
                    fontFamily: variables.typography.typographyCustom,
                    textTransform: "capitalize",
                    color: variables.palette.teritiary.teritiary9
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: variables.palette.text.text4,
                    height: '1px'
                },
            }
        },
        MuiAvatar: {
            styleOverrides: {
                img: {
                    width: "auto",
                    height: "auto"
                }
            }
        }
    },
});

export default theme;