import React, { useEffect, useState } from 'react'
import ProjectTeamMembers from '../../../Components/Projects/ProjectTeamMembers'
import AgencyModal from '../../../Components/Modals/AgencyModal';
import AddTeamMemberModal from '../../../Components/Projects/AddTeamMemberModal';
import AuthUser from '../../../Services/AuthUser';
import NoDataProjectTeam from '../../../Components/Myteams/NoDataProjectTeam';
import { useParams, NavLink, useNavigate, useLocation } from 'react-router-dom';
import AgencySmModal from '../../../Components/Modals/AgencySmModal';
import questionIcon from '../../../assets/agency/img/question-icon.svg'
import BtnSpinner from '../../../Components/Spinners/BtnSpinner'
import { toast, ToastContainer } from "react-toastify";
import JiraConnectModal from './JiraConnectModal';
import Skeleton from 'react-loading-skeleton'
import { PermissionProvider } from '../../../Contexts/PermissionContext';
import Leftsidebar from '../common/Leftsidebar';
import logo from "../../../assets/img/black-logo.png";
import Breadcrumb from '../common/Breadcrumb';
import HeaderAction from '../common/HeaderAction';
import ProjectsWidget from '../../../Components/Projects/ProjectsWidget';
import AddBillingCustomerDetails from '../../../Components/Projects/AddBillingCustomerDetails'
import EditBillingCustomerDetails from '../../../Components/Projects/EditBillingCustomerDetails'
import ProjectInvoiceType from '../../../Components/BillingsModule/ProjectInvoiceType';
import ProjectInvoiceSelect from '../../../Components/BillingsModule/ProjectInvoiceSelect';
import { ReactComponent as MenuIcon } from "../../../assets/agency/img/dp-menu-icon.svg";
import { Box, ClickAwayListener, IconButton, ListItemText, Menu, MenuItem, Stack, SvgIcon, Tooltip, Typography } from '@mui/material';
import { useApiService } from '../../../Services/ApiInstance';
import MyTalents from '../../../Components/Myteams/MyTalents';
import DeleteTeamMemberModal from '../../../Components/Myteams/DeleteTeamMemberModal';
import TeamDetails from '../../../Components/Myteams/TeamDetails';
import EditTeamMemberModal from '../../../Components/Myteams/EditTeamMemberModal';
import WeteamsTalents from '../../../Components/WeteamsTalents/WeteamsTalents';
import PauseMyTeam from '../../../Components/Myteams/PauseMyTeam';
import WeteamsTalentDetails from '../../../Components/WeteamsTalents/WeteamsTalentDetails';
import WeteamsTalentContractViewModal from '../../../Components/WeteamsTalents/WeteamsTalentContractViewModal';
import moment from 'moment';
import EditProjectTeamMemberModal from '../../../Components/Myteams/EditProjectTeamMemberModal';
import JiraStatusMap from '../../../Components/Projects/JiraStatusMap';
import EditTeamMemberModalNew from '../../../Components/Projects/EditTeamMemberModalNew';

const ProjectDetails = () => {
    const { http2 } = AuthUser();
    const { id } = useParams();
    let navigate = useNavigate();
    let {state:{toolType}} = useLocation();
    const { postRequest, SEND_PROJECT_CLIENT_INVITATION_EMAIL } = useApiService();

    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    console.log(http2,"http2.isLoadinghttp2.isLoadinghttp2.isLoadinghttp2.isLoadinghttp2.isLoading")
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [teamItem, setTeamItem] = useState();
    const [projectDashboard, setProjectDashboard] = useState();
    const [projectTeams, setProjectTeams] = useState([]);
    const [projectCurrency, setProjectCurrency] = useState("");
    const [assignedTeamMemberIds, setAssignedTeamMemberIds] = useState([]);
    const [isJira, setIsJira] = useState(false);
    const [menuData, setMenuData] = useState({
        open: false,
        anchorEl: null,
        submenuOpen: false,
    });

    
    const [nonJiraTeamList, setNonJiraTeamList] = useState([]);
    const [readyforupdate, setReadyForUpdate] = useState({});
    const [openHideShowEditModal, setHideShowEditModal] = useState(false);
    const [readyfordelete, setReadyForDelete] = useState({});
    const [openHideShowDeleteModal, setHideShowDeleteModal] = useState(false);
    const [openTeamDetail, setOpenTeamDetail] = useState(false);
    const [teamDetails, setTeamDetails] = useState("");
    const [openWeteamsTalentDetail, setOpenWeteamsTalentDetail] = useState(false);
    const [viewContract, setViewContract] = useState(false);
    const [contractViewData, setContractViewData] = useState("")
    const [activeTab, setActiveTab] = useState("");
    const [contractLoading, setContractLoading] = useState(false);
    const [expireStatus, setExpireStatus] = useState();
    const [viewHiring, setViewHiring] = useState("");
    const [openJiraStatus, setOpenJiraStatus] = useState(false);
    const [currentSprintProjectId, setCurrentSprintProjectId] = useState("");

    // const {dashboard, teamCount, is_jira_credentials_completed, teams} = teamItem;
    const [reload, setReload] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [projects, setProjects] = useState({});
    const [sprintList, setSprintList] = useState([]);

    const [loading_value, setLoadingResponse] = useState(false);

    // const filteredProject = projects.filter(project => project._id === id);
    const openAddTeamModal = () => {
        setModalOpen(true);
    }
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editItem, setEdiItem] = useState();
    const openEditTeamModal = (editItem) => {
        setEditModalOpen(true);
        setEdiItem(editItem)
    }
    const [projectBillingDtl, setProjectBillingDtl] = useState("")


    const getMyProjects = () => {
        http2.get(`/get-project-client-list`).then((res) => {
            let result = res.data.data;
            setProjects(result);
        });
    }

    const getAsanaHours = (string) => {
        // console.log("string.split",string);
        let hours = parseInt(string.split(" ")[0].slice(0,string.split(" ")[0].length-1));
        let minutes = parseInt(string.split(" ")[1].slice(0,string.split(" ")[1].length-1))/60;
        return hours + minutes;
    }

    const filterJiraData = (issueData, teamData) => {

        const rawAssigneeList = teamData.map((item) => {

            if(typeof item?.assignee == "object"){
                return item?.assignee?.displayName.toLowerCase()
            }
            return item?.assignee;
        }).filter((item) => {
            return item != null && item != undefined
        })

        const uniqueAssigneeList = [...new Set(rawAssigneeList)];

        let completed_sprint_stories = issueData.completed_sprint_stories;
        let consumed_hours_on_bugs = issueData.consumed_hours_on_bugs;
        let percentage_consumed_hours_bugs = issueData.percentage_consumed_hours_bugs;
        let consumed_hours_on_stories = issueData.consumed_hours_on_stories;
        let total_hours_on_stories = issueData.total_hours_on_stories;
        let total_sprint_stories = issueData.total_sprint_stories;
        let pending_sprint_stories = issueData.pending_sprint_stories;
        let in_progress_sprint_stories = issueData.in_progress_sprint_stories;

        console.log("uniqueAssigneeList", uniqueAssigneeList);
        const finalTeamData = uniqueAssigneeList.map((item) => {

            let totalDelayedStories = 0;
            let totalOntimeStories = 0;
            let totalPendingStories = 0;
            let totalInProgressStories = 0;
            let totalCompletedStories = 0;
            let inProgressDelayedStories = 0;
            let completedDelayedStories = 0;
            let pendingDelayedStories = 0;
            let inProgressOnTimeStories = 0;
            let completedOnTimeStories = 0;
            let pendingOnTimeStories = 0;
            let totalHoursConsumedOnStories = 0;
            let totalHoursConsumedOnBugs = 0;
            let totalHoursPlannedOnBugs = 0;
            let totalHoursPlanned = 0;

            for (let issue of teamData) {
                if (item == issue?.assignee?.displayName || item == issue?.assignee) {
                    if (issue?.issue?.name != "Bug") {
                        {console.log("moment()",moment(moment()).diff(moment()))}
                        if (moment(issue?.issue?.due_date).diff(moment()) < 0 || isNaN(moment(issue?.issue?.due_date).diff(moment()))) {
                            // console.log("due date", moment(issue?.issue?.due_date).diff(moment()), issue?.assignee,issue?.issue?.status);
                            totalDelayedStories = totalDelayedStories + 1;
                            if (issue?.issue?.status == "To Do") {
                                pendingDelayedStories = pendingDelayedStories + 1;
                                totalPendingStories = totalPendingStories + 1;
                            } else if (issue?.issue?.status == "In Progress" || issue?.issue?.status == "QA in progress" || issue?.issue?.status == "IN QA" || issue?.issue?.status == "Ready to staging" || issue?.issue?.status == "Ready for Prod" || issue?.issue?.status == "Ready for QA") {
                                inProgressDelayedStories = inProgressDelayedStories + 1;
                                totalInProgressStories = totalInProgressStories + 1;
                            } else if (issue?.issue?.status == "Done") {
                                completedDelayedStories = completedDelayedStories + 1;
                                totalCompletedStories = totalCompletedStories + 1;
                            } 
                            // else {
                            //     inProgressDelayedStories = inProgressDelayedStories + 1;
                            //     totalInProgressStories = totalInProgressStories + 1;
                            // }
                        } else {
                            // console.log("due date 2", moment(item?.issue?.due_date).diff(moment()), item?.assignee,item?.issue?.status);
                            totalOntimeStories = totalOntimeStories + 1;
                            if (issue?.issue?.status == "To Do") {
                                pendingOnTimeStories = pendingOnTimeStories + 1;
                                totalPendingStories = totalPendingStories + 1;
                            } else if (issue?.issue?.status == "In Progress") {
                                inProgressOnTimeStories = inProgressOnTimeStories + 1;
                                totalInProgressStories = totalInProgressStories + 1;
                            } else if (issue?.issue?.status == "Done") {
                                completedOnTimeStories = completedOnTimeStories + 1;
                                totalCompletedStories = totalCompletedStories + 1;
                            } 
                            // else {
                            //     inProgressOnTimeStories = inProgressOnTimeStories + 1;
                            //     totalInProgressStories = totalInProgressStories + 1;
                            // }
                        }
        
                        totalHoursConsumedOnStories = totalHoursConsumedOnStories + (issue?.issue?.timetracking?.time_spend || 0);
                        totalHoursPlanned = totalHoursPlanned + (issue?.issue?.timetracking?.total_estimate || 0);
                    } else if (issue?.issue?.name == "Bug") {
                        totalHoursConsumedOnBugs = totalHoursConsumedOnBugs + (issue?.issue?.timetracking?.time_spend || 0);
                        totalHoursPlannedOnBugs = totalHoursPlannedOnBugs + (issue?.issue?.timetracking?.total_estimate || 0);
                    }
                }
            }

            console.log("data 22222", completedDelayedStories);
            return {
                totalDelayedStories,
                totalOntimeStories,
                inProgressDelayedStories,
                completedDelayedStories,
                pendingDelayedStories,
                inProgressOnTimeStories,
                completedOnTimeStories,
                pendingOnTimeStories,
                totalHoursConsumedOnStories,
                totalHoursConsumedOnBugs,
                totalHoursPlanned,
                totalPendingStories,
                totalInProgressStories,
                totalCompletedStories,
                totalHoursPlannedOnBugs,
                item
            };
        })
        console.log("finalTeamData", finalTeamData,{
            completed_sprint_stories,
            consumed_hours_on_bugs,
            consumed_hours_on_stories,
            total_hours_on_stories,
            total_sprint_stories,
            pending_sprint_stories,
            in_progress_sprint_stories
        });
        return {
            teamData: finalTeamData,
            kpiData: {
                completed_sprint_stories,
                consumed_hours_on_bugs,
                consumed_hours_on_stories,
                total_hours_on_stories,
                total_sprint_stories,
                pending_sprint_stories,
                in_progress_sprint_stories,
                percentage_consumed_hours_bugs
            }
        };
    }

    const filterAsanaData = (issueData, teamData) => {

        const rawAssigneeList = teamData.map((item) => {
            return item?.assignee.toLowerCase();
        }).filter((item) => {
            return item != null && item != undefined
        })

        const uniqueAssigneeList = [...new Set(rawAssigneeList)];

        let completed_sprint_stories = issueData.completed_stories;
        let consumed_hours_on_bugs = issueData.totalBug_hours;
        let percentage_consumed_hours_bugs = issueData.bugHours_percentage;
        let consumed_hours_on_stories = issueData.totalConsumed_hours;
        let total_hours_on_stories = issueData.total_hours;
        let total_sprint_stories = issueData.total_stories;
        let pending_sprint_stories = issueData.pending_stories;
        let in_progress_sprint_stories = issueData.inprogress_stories;

        console.log("uniqueAssigneeList", uniqueAssigneeList);
        const finalTeamData = uniqueAssigneeList.map((item) => {

            let totalDelayedStories = 0;
            let totalOntimeStories = 0;
            let totalPendingStories = 0;
            let totalInProgressStories = 0;
            let totalCompletedStories = 0;
            let inProgressDelayedStories = 0;
            let completedDelayedStories = 0;
            let pendingDelayedStories = 0;
            let inProgressOnTimeStories = 0;
            let completedOnTimeStories = 0;
            let pendingOnTimeStories = 0;
            let totalHoursConsumedOnStories = 0;
            let totalHoursConsumedOnBugs = 0;
            let totalHoursPlannedOnBugs = 0;
            let totalHoursPlanned = 0;

            for (let issue of teamData) {
                console.log("teamData................",teamData,issue,item);
                if (item == issue?.assignee.toLowerCase()) {
                    if (issue?.fields.filter((v)=>v.display_value == "bug").length == 0) {
                        console.log("moment()",issue?.fields.filter((v)=>{v.name == "Status"})[0],issue?.fields.filter((v)=>v.name == "Status"),issue?.fields)
                        if (issue?.fields.filter((v)=>v.name == "Status")[0].display_value == "Off track") {
                            // console.log("due date", moment(issue?.issue?.due_date).diff(moment()), issue?.assignee,issue?.issue?.status);
                            totalDelayedStories = totalDelayedStories + 1;
                            if (issue?.section == "To do") {
                                pendingDelayedStories = pendingDelayedStories + 1;
                                totalPendingStories = totalPendingStories + 1;
                            } else if (issue?.section == "In Progress" || issue?.section == "QA in progress" || issue?.section == "IN QA" || issue?.section == "Ready to staging" || issue?.section == "Ready for Prod" || issue?.section == "Ready for QA") {
                                inProgressDelayedStories = inProgressDelayedStories + 1;
                                totalInProgressStories = totalInProgressStories + 1;
                            } else if (issue?.section == "Done") {
                                completedDelayedStories = completedDelayedStories + 1;
                                totalCompletedStories = totalCompletedStories + 1;
                            } 
                            // else {
                            //     inProgressDelayedStories = inProgressDelayedStories + 1;
                            //     totalInProgressStories = totalInProgressStories + 1;
                            // }
                        } else {
                            // console.log("due date 2", moment(item?.issue?.due_date).diff(moment()), item?.assignee,item?.issue?.status);
                            totalOntimeStories = totalOntimeStories + 1;
                            if (issue?.section == "To do") {
                                pendingOnTimeStories = pendingOnTimeStories + 1;
                                totalPendingStories = totalPendingStories + 1;
                            } else if (issue?.section == "In Progress" || issue?.section == "QA in progress" || issue?.section == "IN QA" || issue?.section == "Ready to staging" || issue?.section == "Ready for Prod" || issue?.section == "Ready for QA") {
                                inProgressOnTimeStories = inProgressOnTimeStories + 1;
                                totalInProgressStories = totalInProgressStories + 1;
                            } else if (issue?.section == "Done") {
                                completedOnTimeStories = completedOnTimeStories + 1;
                                totalCompletedStories = totalCompletedStories + 1;
                            } 
                            // else {
                            //     inProgressOnTimeStories = inProgressOnTimeStories + 1;
                            //     totalInProgressStories = totalInProgressStories + 1;
                            // }
                        }
        
                        totalHoursConsumedOnStories = totalHoursConsumedOnStories + (issue?.fields.filter((v)=>v.name == "Actual time")[0].display_value?getAsanaHours(issue?.fields.filter((v)=>v.name == "Actual time")[0].display_value) : 0);
                        totalHoursPlanned = totalHoursPlanned + (issue?.fields.filter((v)=>v.name == "Estimated time")[0].display_value ?getAsanaHours(issue?.fields.filter((v)=>v.name == "Estimated time")[0].display_value) : 0);
                    } else if (issue?.fields.filter((v)=>v.display_value == "bug").length != 0) {
                        totalHoursConsumedOnBugs = totalHoursConsumedOnBugs + (issue?.issue?.timetracking?.time_spend || 0);
                        totalHoursPlannedOnBugs = totalHoursPlannedOnBugs + (issue?.issue?.timetracking?.total_estimate || 0);
                    }
                }
            }

            console.log("data 22222", completedDelayedStories);
            return {
                totalDelayedStories,
                totalOntimeStories,
                inProgressDelayedStories,
                completedDelayedStories,
                pendingDelayedStories,
                inProgressOnTimeStories,
                completedOnTimeStories,
                pendingOnTimeStories,
                totalHoursConsumedOnStories,
                totalHoursConsumedOnBugs,
                totalHoursPlanned,
                totalPendingStories,
                totalInProgressStories,
                totalCompletedStories,
                totalHoursPlannedOnBugs,
                item
            };
        })
        console.log("finalTeamData", finalTeamData,{
            completed_sprint_stories,
            consumed_hours_on_bugs,
            consumed_hours_on_stories,
            total_hours_on_stories,
            total_sprint_stories,
            pending_sprint_stories,
            in_progress_sprint_stories
        });
        return {
            teamData: finalTeamData,
            kpiData: {
                completed_sprint_stories,
                consumed_hours_on_bugs,
                consumed_hours_on_stories,
                total_hours_on_stories,
                total_sprint_stories,
                pending_sprint_stories,
                in_progress_sprint_stories,
                percentage_consumed_hours_bugs
            }
        };
    }

    const combineJiraAndWeateamsData = (weteamsArr, jiraArr) => {
        console.log("jiraArr",jiraArr,weteamsArr);
        const combinedArr = weteamsArr.map((weteamMember) => {

            if(jiraArr.teamData){
                console.log(jiraArr.teamData.entries());
                for (let [index,member] of jiraArr.teamData.entries()) {
                    const { id:team_member_id, color_code, avatar, full_name, designation, short_name, experience, reserved_hours, rate_per_hour, project_details } = weteamMember;
                    console.log("weteamMember.email",weteamMember,member,member.item == weteamMember.full_name.toLowerCase());
                    // if(typeof member?.item?.assignee != "object"){
                        if (member.item == weteamMember.email || member.item == weteamMember.full_name.toLowerCase()) {
                            console.log("member------",member);
                            return {
                                ...member,
                                // color_code,
                                // avatar,
                                // full_name,
                                // designation,
                                // short_name,
                                isAddedOnJira: true,
                                ...weteamMember

                                // short_name,
                                // experience,
                                // reserved_hours,
                                // rate_per_hour,
                                // project_details,
                                // team_member_id,
                                // hide_avatar_toggle: false,
                                // add_asteam_lead: false
                            }
                        }
                    // }
                    // console.log("typeof member?.item?.assignee..................",typeof member?.item?.assignee,member);
                    // if(typeof member?.item?.assignee == "object"){
                    //     if (member.item.assignee.displayName.toUpperCase() == weteamMember.full_name.toUpperCase()) {
                    //         return {
                    //             ...member,
                    //             color_code,
                    //             avatar,
                    //             full_name,
                    //             designation,
                    //             short_name,
                    //             isAddedOnJira: true
                    //         }
                    //     }
                    // }
    
                    if(index == jiraArr.teamData.length-1){
                        return {
                            // color_code,
                            // avatar,
                            // full_name,
                            // designation,
                            isAddedOnJira: false,
                            ...weteamMember
                            // short_name,
                            // experience,
                            // reserved_hours,
                            // rate_per_hour,
                            // project_details,
                            // team_member_id,
                            // hide_avatar_toggle: false,
                            // add_asteam_lead: false
                        };
                    }
                }
            }

            const { color_code, avatar, full_name, designation, short_name, experience, reserved_hours, rate_per_hour } = weteamMember;
            return  {
                // color_code,
                // avatar,
                // full_name,
                // designation,
                isAddedOnJira: false,
                ...weteamMember

                // short_name,
                // experience,
                // reserved_hours,
                // rate_per_hour,
                // project_details,
                // team_member_id,
                // hide_avatar_toggle: false,
                // add_asteam_lead: false
            };
        }).filter((item)=>{
            return item!=undefined;
        });
        console.log("combinedArr",combinedArr);
        return {
            teamArr:combinedArr,
            kpiObj: jiraArr.kpiData
        };
    }

    const getTeamList = async (sprint_id,type) => {
        try {
            setIsLoading(true);

            const call1 = http2.post(`/get-team-list`,{project_id:id});
            // .then((res) => {
            //     let result = res.data.data;
            //     setNonJiraTeamList(result);
            // });

            // const call2 = http2.post(`/get-dashboard-data/${id}`,{sprint_id});
            // .then((response) => {
            //     let result = response.data;
            //     if (result.success) {
            //         setTeamItem(result.data);
            //         setProjectTeams(result.data.teams)
            //         setProjectCurrency(result.data.project_currency)
            //         setProjectDashboard(result.data.dashboard)
            //         setAssignedTeamMemberIds(result.data.assigned_team_member_ids)
            //         setProjectBillingDtl(result.data.project_billing_details)
            //     }
            //     if (result.success === false) {
            //         toast.error(result.message);
            //     }
            //     setIsLoading(false);
            // })
            // .catch((error) => {
            //     if (error.response) {
            //         toast.error(error.response.data.message);
            //     }
            //     setIsLoading(false);
            // });

            let call3 = "";
            console.log("toolType",toolType);
            if(toolType == "jira"){
                // if(type != "default"){
                    call3 = await http2.get(`/get-sprint-dropdown/${id}`);
                // }
            }else{
                call3 = await http2.post(`/get-asana-project-dropdown/${id}`);
            }
            
            console.log("call3",call3);
            var response3 = call3.data;
            if(toolType == "jira"){
                await http2.post(`/get-jira-board-data/${id}`, type != "default" && sprint_id ?{
                    sprint_id: sprint_id ?
                        sprint_id
                        :
                        response3?.sprints != "" ?
                            response3?.sprints?.values[0]?.id ?
                                response3.sprints.values[0].id
                                :
                                null
                            :
                            null
                }:{});
            }else if(toolType == "asana"){
                let projectId = sprint_id && type!="default"?
                sprint_id
                :
                response3?.data != "" && response3.data.length != 0?
                    response3?.data?.[0]?.gid ?
                        response3.data[0].gid
                        :
                        null
                    :
                    null;

                await http2.post(`/update-asana-data/${id}`, {
                    project_id: projectId
                });
                setCurrentSprintProjectId(projectId)
                
            }
            
            const call2 = await http2.post(`/get-dashboard-data/${id}`);
            // console.log("call2",call2);   
    
          
            const [teamData, combinedData] = await Promise.all([call1, call2]);

            let result = combinedData.data.data;

            if(toolType == "jira"){
                if (combinedData.data.success) {
                    setTeamItem(result);
                    console.log("response3",response3?.sprints["values"]);
                    setSprintList(Array.isArray(response3?.sprints) ? []:response3.sprints.values);
                    if (result.is_jira_credentials_completed) {
                        console.log("filterJiraData", response3);
                        console.log("teamData",teamData,combinedData);
                        if(!combinedData.data.data.assigned_issues.original.error){
                            const combinedTeamData = combineJiraAndWeateamsData(
                                teamData.data.data.teams,
                                filterJiraData(combinedData.data.data.assigned_issues.original.data.jira_calculate_data,combinedData.data.data.project_tool_data.sprint_detail.team_data));
                                console.log("combinedTeamData",combinedTeamData);
                                setProjectDashboard({...result.dashboard,kpiObj:combinedTeamData.kpiObj})
                                setProjectTeams(combinedTeamData);
                        }else{
                            const combinedTeamData = combineJiraAndWeateamsData(
                                teamData.data.data.teams,
                                []);
                            setProjectDashboard({});
                            setProjectTeams(combinedTeamData);
                        }
                        
                    } else {
                        setProjectTeams(teamData.data.data.teams);
                        setProjectDashboard(result.dashboard)
                    }
    
                    // setProjectTeams(result.teams)
                    setProjectCurrency(result.project_currency)
                    // setProjectDashboard(result.dashboard)
                    setAssignedTeamMemberIds(result.assigned_team_member_ids)
                    setProjectBillingDtl(result.project_billing_details)
                }
            }else if(toolType == "asana"){
                if (combinedData.data.success) {
                    setTeamItem(result);
                    console.log("response3",response3);
                    setSprintList(response3?.data.length != 0 ? response3.data:[]);
                    if (result.is_asana_credentials_completed) {
                        console.log("filterJiraData", response3);
                        console.log("teamData",teamData,combinedData);
                        if(Object.keys(result.dashboard.asana_data).length >0){
                            const combinedTeamData = combineJiraAndWeateamsData(
                                teamData.data.data.teams,
                                filterAsanaData(result.dashboard.asana_data.asana_calculation,result.dashboard.asana_data.asana_team_data.tasks));
                                console.log("combinedTeamData",combinedTeamData);
                                setProjectDashboard({...result.dashboard,kpiObj:combinedTeamData.kpiObj})
                                setProjectTeams(combinedTeamData);
                        }else{
                            const combinedTeamData = combineJiraAndWeateamsData(
                                teamData.data.data.teams,
                                []);
                            setProjectDashboard({});
                            setProjectTeams(combinedTeamData);
                        }
                        
                    } else {
                        setProjectTeams(teamData.data.data.teams);
                        setProjectDashboard(result.dashboard)
                    }
    
                    // setProjectTeams(result.teams)
                    setProjectCurrency(result.project_currency)
                    // setProjectDashboard(result.dashboard)
                    setAssignedTeamMemberIds(result.assigned_team_member_ids)
                    setProjectBillingDtl(result.project_billing_details)
                }
            }else{
                if (combinedData.data.success) {
                    setTeamItem(result);
                    console.log("response3",response3);
                    setProjectTeams(teamData.data.data.teams);
                    setProjectDashboard(result.dashboard);
    
                    // setProjectTeams(result.teams)
                    setProjectCurrency(result.project_currency)
                    // setProjectDashboard(result.dashboard)
                    setAssignedTeamMemberIds(result.assigned_team_member_ids)
                    setProjectBillingDtl(result.project_billing_details)
                }
            }
            
            if (combinedData.data.success === false) {
                toast.error(combinedData.data.message);
            }
            setIsLoading(false);

        } catch (error) {
            toast.error(error);
            console.log(error);
            setIsLoading(false);
        }


    };
    const getBarPercentage = async (sprint_id,type) => {
            const call3 = await http2.get(`/get-sprint-dropdown/${id}`);
            let response3 = call3.data;

            if(type != "default"){
                var call4 = await http2.post(`/get-percentage-delay/${id}`, {
                    sprint_id: sprint_id ?
                        sprint_id
                        :
                        response3?.sprints != "" ?
                            response3?.sprints?.values[0]?.id ?
                                response3.sprints.values[0].id
                                :
                                null
                            :
                            null
                });
    
                const [teamData] = await Promise.all([call4]);
                if(teamData.data.success ){
                    setLoadingResponse(true)
                }      
            }else{
                setLoadingResponse(true)
            }
          
        
    }

    

    // Delete Team Member in Project
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteItemDtl, setDeleteItemDtl] = useState();

    const deleteProjectTeamModal = (deleteItem) => {
        setDeleteModalOpen(true);
        setDeleteItemDtl(deleteItem);
    }
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    }

    const sendProjectDeleteRequest = () => {
        const { project_id } = deleteItemDtl;
        try {
            setIsDeleteLoading(true);
            http2
                .post(`/delete-team-member/${project_id}`, deleteItemDtl)
                .then((response) => {
                    let result = response.data;
                    console.log(result.success);
                    if (result.success === true) {
                        toast.success(result.message);
                        setDeleteModalOpen(false);
                        // handleRemove(id);
                        setReload(false);
                        setIsDeleteLoading(false)
                    }
                    setIsDeleteLoading(false);
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data.message);
                    }
                    setIsDeleteLoading(false);
                });
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
            setIsDeleteLoading(false);
        }
    }
    const [jiraConnectModal, setJiraConnectModal] = useState(false);
    const [jiraConnectDtl, setJiraConnectDtl] = useState("");

    const openJiraConnectModal = (jiraDtl) => {
        setJiraConnectModal(true);
        setJiraConnectDtl(id)
    }
    const [isMob, setisMob] = useState(false);
    const onChangeBodyClass = (value) => {
        setisMob(value);
        value
            ? document.body.classList.add("overflow-active")
            : document.body.removeAttribute("class");
    };
    useEffect(() => {
        getTeamList(1,"default");
        getMyProjects();
        getBarPercentage(1,"default");
        setReload();
    }, [reload]);
    const [clientCurrency, setClientCurrency] = useState(false);
    const handleCheckboxClick = () => {
        setClientCurrency(!clientCurrency)
    }
    const switchCurrency = () => {
        setClientCurrency(!clientCurrency)
    }
    // 
    const [billingCustomerInfo, setBillingCustomerInfo] = useState(false);
    const [editBillingCustomerInfo, setEditBillingCustomerInfo] = useState(false);
    const openBillingCustomerInfo = () => {
        setBillingCustomerInfo(true)
    }
    const openEditBillingCustomerInfo = () => {
        setEditBillingCustomerInfo(true)
    }

    const [projectType, setProjectType] = useState(false)
    const openCreateInvoiceType = () => {
        setProjectType(true)
    }
    const [selectInvoiceProject, setSelectInvoiceProject] = useState(false)
    const openSelectInvoiceProject = () => {
        setProjectType(false);
        setSelectInvoiceProject(true)
    }



    const handleClick = (event) => {
        setMenuData({
            ...menuData,
            open: !menuData.open,
            anchorEl: event.currentTarget
        });
    };

    const handleClose = () => {

        setMenuData({
            ...menuData,
            open: false
        });
    };

    const clientInvitation = async () => {

        const res = await postRequest(SEND_PROJECT_CLIENT_INVITATION_EMAIL, {
            client_email: teamItem?.dashboard?.client_email,
            client_name: teamItem?.dashboard?.client_name
        });
        toast.success(res?.data?.message || "The invitation have been sent successfully!");
    };

    const editTeamMemberItem = (item) => {
        setReadyForUpdate(item);
        setHideShowEditModal(true);
    };

    const deleteTeamMemberItem = (item) => {
        setReadyForDelete(item);
        setHideShowDeleteModal(true);
    };

    const viewWeTeamTalentDetails = (item) => {
        setOpenWeteamsTalentDetail(true)
        setTeamDetails(item)
    }

    const viewTeamDetails = (item) => {
        setOpenTeamDetail(true)
        setTeamDetails(item)
    }

    const closeTeamDtl = () => {
        setOpenTeamDetail(false)
    }

    const closeWeteamsTalentDtl = () => {
        setOpenWeteamsTalentDetail(false)
    }

    const destroyTeam = (project_id,item) => {
        console.log(project_id,item);
        try {
            setIsLoading(true);
            http2
                .post(`/delete-project-team-member/${project_id}`,{team_member_id:isJira?item.team_member_id:item.id})
                .then((response) => {
                    setIsLoading(true);
                    let result = response.data;
                    if (result.success) {
                        toast.success(result.message);
                        setReload(true);
                        setHideShowDeleteModal(false);
                    }
                })
                .catch((error) => {
                    setIsLoading(true);
                    if (error.response) {
                        toast.error(error.response.data.message);
                    }
                });
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    };

    const openViewContract = (talentData) => {
        setViewContract(true)
        setContractLoading(true);
        setActiveTab("");
        setContractViewData(talentData);
        let payload = {
            //   hiring_id: params.id || hireTalent.my_hiring_id,
            talent_id: talentData?.id,
        };
        http2.post(`/get-contract-details-of-talent`, payload).then((res) => {
            let result = res.data.data;
            setViewHiring(result);
            setExpireStatus(res.data.expireStatus)
            setActiveTab(res?.data?.contractLable);
            setContractLoading(false);
        });
    }

    console.log("Helllllllooo....",isJira)
    return (
        <>
            {/* <ToastContainer hideProgressBar={true} /> */}
            <div className="dashboard-wrap d-flex">
                <PermissionProvider>
                    <Leftsidebar isMob={isMob} setisMob={setisMob} />
                </PermissionProvider>
                <div className="dashboard-right">
                    <div className="dash-header-inner">
                        <div className="dash-header d-flex align-items-center">
                            <div className="d-flex align-items-center">
                                <div
                                    className="dash-mobile-inner-header"
                                    onClick={() => onChangeBodyClass(!isMob)}
                                >
                                    <div
                                        className={`dash-mobile-inner-header-icon dash-mobile-inner-header-icon-out ${isMob ? "active" : ""
                                            }`}
                                    >
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                                <div className="mob-logo d-lg-none">
                                    <NavLink to="/dashboard/overview">
                                        <img src={logo} alt="logo" />
                                    </NavLink>
                                </div>
                                <div className="brd-block brd-for-desktop">
                                    <Breadcrumb projectName={teamItem?.dashboard?.project_name} projectId={teamItem?.dashboard?.project_id} />
                                </div>
                            </div>
                            <div className="ml-auto d-inline-flex align-items-center">
                                <HeaderAction />
                            </div>
                        </div>
                        <div className="brd-block brd-for-mobile d-none">
                            <Breadcrumb projectName={teamItem?.dashboard?.project_name} projectId={teamItem?.dashboard?.project_id} />
                        </div>
                    </div>
                    <div className="page-scrollbar position-relative mt-20">
                        {/* {
                            teamItem?.is_jira_credentials_completed ? */}
                            {console.log("projectDashboard",projectDashboard)}
                        <div className="ad-dash-info-cards">
                            <ProjectsWidget
                                key={sprintList?.length || 0}
                                teamItem={teamItem}
                                setReload={setReload}
                                sprintList={sprintList}
                                syncRequest={getTeamList}
                                syncLoad={false}
                                handleSprintSelect={(id)=>{
                                    getTeamList(id);
                                    // setCurrentSprintProjectId(id)
                                }}
                                projectDashboard={projectDashboard}
                                projectCurrency={projectCurrency}
                                openJiraConnectModal={openJiraConnectModal}
                                handleCheckboxClick={handleCheckboxClick}
                                switchCurrency={switchCurrency}
                                clientCurrency={clientCurrency}
                                openBillingCustomerInfo={openBillingCustomerInfo}
                                openEditBillingCustomerInfo={openEditBillingCustomerInfo}
                                projectBillingDtl={projectBillingDtl}
                                clientInvitationCallback={clientInvitation}
                                setIsjira={(val)=>{}}
                                loading_value={loading_value}
                                toolType={teamItem?.is_jira_credentials_completed?"jira":teamItem?.is_asana_credentials_completed?"asana":""}
                            />
                            <div className={`ad-team-flex ${teamItem?.is_jira_credentials_completed ? "mt-4" : ""}`}>
                                        <div className="ad-team-summary">
                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                        {/* <div className="ad-md-title dash-font-1-1em">{teamItem?.teamCount} Team members</div> */}
                                        <Typography variant="size3SemiboldTextText5" lineHeight="18px">{teamItem?.is_jira_credentials_completed ? "Team performance" : "Team performance"}</Typography>
                                        {/* <div className='adt-right'>
                                            {projectBillingDtl === "" ?
                                                <span className="ad-info-box">
                                                    <button className="ad-theme-btn ad-sm-btn me-2" type="button" disabled={true}>+ Create Invoice</button>
                                                    <div className="ad-info-pos">You are required to input billing details to initiate the invoice creation process.</div>
                                                </span> :
                                                <button className="ad-theme-btn ad-sm-btn me-2" type="button" onClick={openCreateInvoiceType}>+ Create Invoice</button>
                                            }
                                            <button type="button" className="ad-theme-btn ad-sm-btn" onClick={openAddTeamModal}>
                                                Add Member
                                            </button>
                                        </div> */}
                                        
                                        {
                                            user.is_invite_client != "true" &&
                                            <>
                                            {(teamItem?.is_jira_credentials_completed || teamItem?.is_asana_credentials_completed) && (
                                                    <Box component="button" sx={{mr:"10px",ml:"auto"}} type="button" onClick={()=>{setOpenJiraStatus(!openJiraStatus)}} className="btn ad-dropdown-btn">
                                                        Map {teamItem?.is_asana_credentials_completed ? "asana":"jira"} status
                                                    </Box>
                                                )}
                                                {/* <div className="ad-custom-dropdown"> */}
                                                
                                                <button type="button" onClick={openAddTeamModal} className="btn ad-dropdown-btn">
                                                    <span className="add-team-icon"></span> Add team member
                                                </button>

                                            {/* </div> */}
                                            </>
                                            // <ClickAwayListener disableReactTree onClickAway={() => { handleClose() }}>
                                            //     <Box position="relative">

                                            //         <IconButton onClick={handleClick} sx={{ height: "24px", width: "24px" }}>
                                            //             <SvgIcon
                                            //                 component={MenuIcon}
                                            //                 inheritViewBox
                                            //             />
                                            //         </IconButton>

                                            //         <Menu
                                            //             anchorEl={menuData.anchorEl}
                                            //             open={menuData.open}
                                            //             onClose={handleClose}
                                            //             anchorReference="none"
                                            //             sx={{
                                            //                 position: "absolute",
                                            //                 width: "198px",
                                            //                 top: "40px",
                                            //                 left: "-163px"
                                            //             }}
                                            //             transformOrigin={{
                                            //                 vertical: "top",
                                            //                 horizontal: "right"
                                            //             }}
                                            //             slotProps={{
                                            //                 root: {
                                            //                     sx: {
                                            //                         "& .MuiMenu-list": {
                                            //                             p: 0
                                            //                         },
                                            //                         "& .MuiMenuItem-root": {
                                            //                             "&:hover": {
                                            //                                 borderRadius: "8px",
                                            //                                 backgroundColor: "primary.main",
                                            //                                 "&>.MuiListItemIcon-root": {
                                            //                                     "&>.MuiSvgIcon-root": {
                                            //                                         color: "common.white"
                                            //                                     },
                                            //                                 },
                                            //                                 "&>.MuiListItemText-root": {
                                            //                                     "&>.MuiTypography-root": {
                                            //                                         color: "common.white"
                                            //                                     },
                                            //                                 },
                                            //                                 "&>.MuiBox-root": {
                                            //                                     "&>.MuiListItemText-root": {
                                            //                                         "&>.MuiTypography-root": {
                                            //                                             color: "common.white"
                                            //                                         },
                                            //                                     }
                                            //                                 },
                                            //                                 "&>.MuiSvgIcon-root": {
                                            //                                     color: "common.white"
                                            //                                 },
                                            //                                 "&>.MuiTypography-root": {
                                            //                                     color: "common.white"
                                            //                                 }
                                            //                             }
                                            //                         }
                                            //                     }
                                            //                 },
                                            //                 paper: {
                                            //                     sx: {
                                            //                         width: "100%",
                                            //                         height: "auto",
                                            //                         maxHeight: "max-content",
                                            //                         overflow: "visible",
                                            //                         borderRadius: "8px",
                                            //                         border: "1px solid",
                                            //                         borderColor: "primary.main"
                                            //                     }
                                            //                 }
                                            //             }}
                                            //             disableScrollLock
                                            //             disablePortal
                                            //             hideBackdrop
                                            //             disableEnforceFocus
                                            //         >
                                            //             {/* <Tooltip
                                            //             slotProps={{
                                            //                 popper: { className: "tooltipPrimMain" },
                                            //             }} 
                                            //             title={projectBillingDtl === "" && "You are required to input billing details to initiate the invoice creation process"}
                                            //             >
                                            //         {/* <MenuItem sx={{ 
                                            //             p: "13px 14px 7px 14px", 
                                            //             borderTopRightRadius: "8px", 
                                            //             borderTopLeftRadius: "8px",
                                            //              }} >
                                            //                 <Box>
                                            //                 <ListItemText
                                            //                     primaryTypographyProps={{
                                            //                         variant: "size3MediumTextText1",
                                            //                         align: "left"
                                            //                     }}
                                            //                     sx={{...(
                                            //                         projectBillingDtl === "" &&
                                            //                         {opacity:0.5,pointerEvents:"none"}
                                            //                     )}}
                                            //                     onClick={openCreateInvoiceType}
                                            //                 >Create Invoice</ListItemText>
                                            //                 </Box>

                                            //         </MenuItem>
                                            //         </Tooltip> */}
                                            //             <MenuItem sx={{ p: "7px 14px" }} onClick={openAddTeamModal}>
                                            //                 <ListItemText
                                            //                     primaryTypographyProps={{
                                            //                         variant: "size3MediumTextText1",
                                            //                         align: "left"
                                            //                     }}
                                            //                 >Add team member</ListItemText>
                                            //             </MenuItem>
                                            //             <MenuItem sx={{ p: "7px 14px" }} onClick={()=>{setOpenJiraStatus(true)}}>
                                            //                 <ListItemText
                                            //                     primaryTypographyProps={{
                                            //                         variant: "size3MediumTextText1",
                                            //                         align: "left"
                                            //                     }}
                                            //                 >Set jira mapping</ListItemText>
                                            //             </MenuItem>
                                            //             {/* <MenuItem sx={{ p: "7px 14px 13px 14px",borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px"  }}>
                                            //             <ListItemText
                                            //                 primaryTypographyProps={{
                                            //                     variant: "size3MediumTextText1",
                                            //                     align: "left"
                                            //                 }}
                                            //             >Sprint Estimate</ListItemText>
                                            //         </MenuItem> */}
                                            //         </Menu>
                                            //     </Box>
                                            // </ClickAwayListener>
                                        }
                                    </Stack>
                                            <Box className="adts-body" sx={{ mt: "12px" }}>
                                                <div className='adp-list'>
                                                    {console.log("teamItem?.is_jira_credentials_completed",teamItem?.is_jira_credentials_completed)}
                                                    <div className='adp-list-width'>
                                                        {isLoading ? (
                                                            <Skeleton containerClassName="cs-skeleton" count={5} height={80} />
                                                        ) :
                                                            teamItem?.is_jira_credentials_completed || teamItem?.is_asana_credentials_completed ?
                                                                projectTeams.teamArr.length > 0 ?
                                                                    (projectTeams.teamArr.map((item,index) => (
                                                                        <ProjectTeamMembers
                                                                            jira_completed={teamItem?.is_jira_credentials_completed || teamItem?.is_asana_credentials_completed}
                                                                            key={index}
                                                                            item={item}
                                                                            toolType={teamItem?.is_jira_credentials_completed?"jira":"asana"}
                                                                            openEditTeamModal={editTeamMemberItem}
                                                                            deleteProjectTeamModal={(item)=>{
                                                                                setIsJira(true);
                                                                                deleteTeamMemberItem(item);
                                                                            }}
                                                                            clientCurrency={clientCurrency}
                                                                            openAddTeamModal={openAddTeamModal}
                                                                            viewTeamDetails={viewTeamDetails}
                                                                        />
                                                                    ))) :
                                                                    <NoDataProjectTeam addTeamCallback={openAddTeamModal} />
                                                                :
                                                                projectTeams.length > 0 ?
                                                                    (projectTeams.map((item, index) => (
                                                                        item.weteams_talent ?
                                                                            <WeteamsTalents
                                                                                forceEdit
                                                                                forceDelete
                                                                                key={index}
                                                                                item={item}
                                                                                viewWeTeamTalentDetails={viewWeTeamTalentDetails}
                                                                                deleteTeamMemberItem={(item)=>{
                                                                                    setIsJira(false);
                                                                                    deleteTeamMemberItem(item);}}
                                                                                editTeamMemberItem={editTeamMemberItem}
                                                                            /> :
                                                                            item.is_pause ?
                                                                                <PauseMyTeam
                                                                                    key={index}
                                                                                    item={item} /> :
                                                                                <MyTalents
                                                                                    forceEdit
                                                                                    forceDelete
                                                                                    key={index}
                                                                                    item={item}
                                                                                    isProjectPage={true}
                                                                                    deleteTeamMemberItem={(item)=>{
                                                                                        setIsJira(false);
                                                                                        deleteTeamMemberItem(item);}}
                                                                                    editTeamMemberItem={editTeamMemberItem}
                                                                                    viewTeamDetails={viewTeamDetails}
                                                                                // handleShow={handleShow}
                                                                                />
                                                                    ))) :
                                                                    <NoDataProjectTeam addTeamCallback={openAddTeamModal} />
                                                        }
                                                    </div>
                                                </div>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                                 {/* :
                                 <NoDataProjectTeam addTeamCallback={() => { navigate("/dashboard/projects") }} />
                        } */}
                    </div>
                </div>
            </div>
            {modalOpen &&
                <AgencyModal open={modalOpen}>
                    <AddTeamMemberModal
                        setReload={setReload}
                        setModalOpen={setModalOpen}
                        assignedTeamMemberIds={assignedTeamMemberIds}
                        projectCurrency={projectCurrency}
                    />
                </AgencyModal>
            }
            {/* {editItem &&
                <AgencyModal open={editModalOpen}>
                    <EditTeamMemberModal
                        editItem={editItem}
                        setReload={setReload}
                        setEditModalOpen={setEditModalOpen}
                    />
                </AgencyModal>
            } */}
            {editItem &&
                <AgencyModal open={editModalOpen}>
                    <EditTeamMemberModal
                        editItem={editItem}
                        setReload={setReload}
                        setEditModalOpen={setEditModalOpen}
                    />
                </AgencyModal>
            }
            {/* {editItem &&
                <AgencyModal open={editModalOpen}>
                    <EditProjectTeamMemberModal
                        editItem={editItem}
                        setReload={setReload}
                        setEditModalOpen={setEditModalOpen}
                    />
                </AgencyModal>
            } */}
            {deleteItemDtl &&
                <AgencySmModal open={deleteModalOpen} close={closeDeleteModal}>
                    <div className="text-center">
                        <img src={questionIcon} alt="question" />
                    </div>
                    <div className="mt-3 ad-md-title text-center">Are you sure?</div>
                    <div className="mt-2 content text-center">
                        <p className="ad-lgray-text">Do you really want to delete?</p>
                    </div>
                    <div className="mt-3 text-center row">
                        <div className="col-6">
                            <button type="button"
                                className="ad-gray-btn ad-sm-btn w-100" onClick={() => setDeleteModalOpen(false)}>Cancel</button>
                        </div>
                        <div className="col-6">
                            {isDeleteLoading
                                ? (
                                    <BtnSpinner cls="ad-theme-btn ad-sm-btn w-100" loader="true" />
                                ) : (
                                    <button type="button" className="ad-theme-btn ad-sm-btn w-100" onClick={sendProjectDeleteRequest}>Yes</button>
                                )}

                        </div>
                    </div>
                </AgencySmModal>
            }

            {jiraConnectModal &&
                <AgencyModal open={jiraConnectModal}>
                    <JiraConnectModal
                        setJiraConnectModal={setJiraConnectModal}
                        setReload={setReload}
                        projectId={jiraConnectDtl}
                    />
                </AgencyModal>
            }
            {billingCustomerInfo &&
                <AgencyModal open={billingCustomerInfo}>
                    <AddBillingCustomerDetails
                        setBillingCustomerInfo={setBillingCustomerInfo}
                        setReload={setReload}
                    />
                </AgencyModal>
            }
            {editBillingCustomerInfo &&
                <AgencyModal open={editBillingCustomerInfo}>
                    <EditBillingCustomerDetails
                        setEditBillingCustomerInfo={setEditBillingCustomerInfo}
                        projectBillingDtl={projectBillingDtl}
                        setReload={setReload}
                    />
                </AgencyModal>
            }


            <AgencyModal open={projectType} clsSm={true}>
                <ProjectInvoiceType
                    clientsObj={projects}
                    filteredProject={id}
                    setProjectType={setProjectType}
                    openSelectInvoiceProject={openSelectInvoiceProject}
                />
            </AgencyModal>
            <AgencyModal open={selectInvoiceProject}>
                <ProjectInvoiceSelect
                    setSelectInvoiceProject={setSelectInvoiceProject}
                />
            </AgencyModal>
            <AgencyModal open={openHideShowEditModal}>
                <EditTeamMemberModalNew
                    readyforupdate={readyforupdate}
                    setHideShowEditModal={setHideShowEditModal}
                    setReload={setReload}
                    projectCurrency={projectCurrency}
                    projectDashboard={projectDashboard}
                />
            </AgencyModal>
            
            {/* <EditTeamMemberModal
                readyforupdate={readyforupdate}
                openHideShowEditModal={openHideShowEditModal}
                setHideShowEditModal={setHideShowEditModal}
                setReload={setReload}
            /> */}
            {/* <EditProjectTeamMemberModal
                readyforupdate={readyforupdate}
                openHideShowEditModal={openHideShowEditModal}
                setHideShowEditModal={setHideShowEditModal}
                setReload={setReload}
            /> */}
            <DeleteTeamMemberModal
                readyfordelete={readyfordelete}
                isProjectPage={true}
                openHideShowDeleteModal={openHideShowDeleteModal}
                setHideShowDeleteModal={setHideShowDeleteModal}
                destroyTeam={destroyTeam}
            />
            <TeamDetails
                openTeamDetail={openTeamDetail}
                closeTeamDtl={closeTeamDtl}
                teamDetails={teamDetails}
            />
            <WeteamsTalentDetails
                openWeteamsTalentDetail={openWeteamsTalentDetail}
                closeWeteamsTalentDtl={closeWeteamsTalentDtl}
                teamDetails={teamDetails}
                openViewContract={openViewContract}
            />
            <AgencyModal open={viewContract}>
                <WeteamsTalentContractViewModal
                    setViewContract={setViewContract}
                    viewHiring={viewHiring}
                    activeTab={activeTab}
                    isLoading={contractLoading}
                    setActiveTab={setActiveTab}
                    contractViewData={contractViewData}
                    // renewBtn={renewBtn}
                    // resetviewContract={resetviewContract}
                    // renewContract={renewContract}
                    // endContractRequest={endContractRequest}
                    expireStatus={expireStatus}
                />
            </AgencyModal>
         
            <AgencyModal open={openJiraStatus}>
                <JiraStatusMap key={String(Math.random()+Math.random())} setModalOpen={(status)=>{setOpenJiraStatus(status)}} project={id} type={toolType} syncRequest={getTeamList}/>
            </AgencyModal>

            
        </>
    )
}

export default ProjectDetails