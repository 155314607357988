import { VictoryPie } from "victory";
import adDtlClose from "../../assets/agency/img/dtl-close-icon.svg";
import jiraLogo from "../../assets/agency/img/jira.png";
import wtEnvlopeIcon from "../../assets/agency/img/wt-envlope-icon.svg";
import wtPhoneIcon from "../../assets/agency/img/wt-phone-icon.svg";
import Currency from "../Currency/Currency";
import { useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import TalentPortfolio from "../Talent/TalentDetail/TalentProtfolio";
import PortfolioModal from "../Talent/TalentDetail/PortfolioModal";
import TalentCertificate from "../Talent/TalentDetail/TalentCertificate";
import moment from 'moment';
import 'moment-duration-format';
import {Avatar} from "@mui/material";

const TeamDetails = ({ openTeamDetail, closeTeamDtl, teamDetails, isHiringTalentDetail,talent_rate,isTeamDetail,final,myval,years,months, teamExp }) => {
  const {
    name,
    email,
    full_name,
    color_code,
    first_name,
    last_name,
    phone_number,
    avatar,
    short_name,
    role,
    profile_type,
    experience,
    salary_per_month_format,
    internal_rate_format,
    skills,
    other_skills,
    onboarding_date,
    jira_details,
    monthly_working_hours,
    payroll_type,
    is_outsource_talent,
    client_project_hour,
    portfolio,
    certification,
    rate_per_hour,
    contractDuration,
    talent_reserved_hours_details,
    outsourcing_rate,
    experience_in_year,
    experience_in_month,
    experience2
  } = teamDetails;

  

  const theme = useTheme();
  // Email Copy
  const [copiedEmail, setCopiedEmail] = useState(false)
  const emailRef = useRef(null);
  const [portfolioModalOpen, setPortfolioModalOpen] = useState(false);
  const [newval, setMyval] = useState('');
  const [portfolioDtl, setPortfolioDtl] = useState({});
  const copyEmail = () => {
    setCopiedEmail(true)
    const range = document.createRange();
    range.selectNode(emailRef.current);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
  };
  // Phone Copy
  const [copiedPhone, setCopiedPhone] = useState(false)
  const phoneRef = useRef(null);
  const copyPhone = () => {
    setCopiedPhone(true)
    const range = document.createRange();
    range.selectNode(phoneRef.current);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCopiedEmail(false);
      setCopiedPhone(false);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [copiedEmail, copiedPhone])


  function formatExperience(experience) {

    if (typeof experience !== 'string' || !experience) {
      return '';
    }
    const parts = experience.split(" ");
    let years = 0;
    let months = 0;
  
    if (parts.includes('years')) {
      years = parseInt(parts[0]);
    }
    if (parts.includes('months')) {
      months = parseInt(parts[2]);
    }
  
    const duration = moment.duration({ years: years, months: months });
    const formattedYears = duration.years();
    const formattedMonths = duration.months();

  console.log("duration>>>>>>>>>",formattedYears,formattedMonths,months);
  
    if (formattedYears === 0 && formattedMonths > 0) {
      return `${formattedMonths}+ months`;
    } else if (formattedYears === 1 && formattedMonths === 0) {
      return `${formattedYears} yr`;
    } else if (formattedYears === 1 && formattedMonths > 0) {
      return `${formattedYears}+ yrs`;
    } else if (formattedYears > 1 && formattedMonths === 0) {
      return `${formattedYears} yrs`;
    } else if (formattedYears > 1 || formattedMonths > 0) {
      return `${formattedYears}+ yrs`;
    }else {
      return "0+ yrs";
    }
  
 
  }
  

  let user = JSON.parse(localStorage.getItem("user"));

  const reqPortfolioModalClose = () => {
    setPortfolioModalOpen(false);
}

let result = null;

if (Array.isArray(talent_rate)) {
  result = talent_rate.find(item => item.talent_id.includes(teamDetails.id));
}

const talentNetworkCost = result ? result.talent_network_cost : null;


console.log(talentNetworkCost,"Otherotherskillllls........");

  return (
    <>
      <div className={`addtl-overlay-bg ${openTeamDetail ? "show" : ""}`} onClick={closeTeamDtl}></div>
      <Box sx={{overflow:"auto"}} className={`ad-detail-box ${openTeamDetail ? "show" : ""}`}>
        <div className="ad-teamr-box1">
          <button type="button" className="ad-dtl-close" onClick={closeTeamDtl}>
            <img src={adDtlClose} alt="close" />
          </button>
          <div className="d-flex align-items-center adts-col-info w-100 mb-3">
            <Box
              className="dash-hc-img ad-sm-hc"
              sx={{ background: avatar ? theme.palette.common.white : color_code, border: `1px solid ${color_code}`/*,boxShadow:`0px 0px 5px 0.5px ${theme.palette.text.text2}`*/ }}
            >
              {
              // avatar ? (
              //   <img src={avatar} alt={name} />
              // ) : 
              (
                // <Box className="talent-short-name" sx={{ display: "flex" }}><Box>{short_name?.split(" ")[0] || ""}</Box><Box>{short_name?.split(" ")[1] || ""}</Box></Box>
                <Avatar
                      sx={{
                        width: 45,
                        height: 45,
                        mr: "10px",
                        border: `1px solid ${color_code}`,
                        display: "flex",
                        color: "white",
                        backgroundColor: `${color_code}`,
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: 500,
                        fontSize:"18px"
                      }}
                      src={avatar}
                    >
                  {`${
                        first_name ? first_name.split("")[0] : short_name
                      } ${last_name ? last_name.split("")[0] : ""}`}

                    </Avatar>
              )}
              {/* <span className="dash-hc-play-icon"></span> */}
            </Box>
            <div className="dash-hc-info">
              <div className="dash-md-title dash-black-text fw*view-600 dash-font-1em">
                {full_name}
                {/* <span className="ad-exp">{experience}</span> */}
              </div>
              <div className="dash-md-title dash-font-0-8em text-wrap mt-1">
                {role}
              </div>
              <Box component="span" sx={{ position: "relative", ml: 0 }} className="ad-exp-custom">{experience}</Box>
              {/* <div className="dash-md-title dash-font-0-8em mt-1">
                {profile_type}
              </div> */}
            </div>
            <div className="pt-2 text-end">
              <div className="d-inline-flex">
                {isHiringTalentDetail ? <></> : <div className="position-relative wt-contact">
                  <span className="wt-icon"><img src={wtEnvlopeIcon} alt="wtEnvlopeIcon" /></span>
                  <div className="wt-conact-info">
                    <div className="d-flex align-items-center justify-content-between text-nowrap wtconact-info-box">
                      <div className="pe-2" ref={emailRef}>{email}</div>
                      {copiedEmail ?
                        <span className="wt-check-icon"></span> :
                        <button className="wt-copy-icon" type="button" onClick={copyEmail}></button>
                      }
                    </div>
                  </div>
                </div>}
                {/* {phone_number && 
                  <div className="position-relative ms-2 wt-contact">
                    <span className="wt-icon"><img src={wtPhoneIcon} alt="wtPhoneIcon"/></span>
                    <div className="wt-conact-info">
                      <div className="d-flex align-items-center justify-content-between text-nowrap wtconact-info-box">
                        <div className="pe-2" ref={phoneRef}>{phone_number}</div>
                      {copiedPhone ? 
                        <span className="wt-check-icon"></span> :
                        <button className="wt-copy-icon" type="button" onClick={copyPhone}></button>
                        }
                      </div>
                    </div>
                  </div>
                } */}
              </div>
            </div>
          </div>
        </div>
        <div className="ad-teamr-box2">
          <div className="row">
            {
              (payroll_type === 'full_time' && !is_outsource_talent)
                ? <>
                  <div className="col-md-6 mt-2 mb-2">
                    <div className="dash-md-title dash-font-0-9em ad-gray-text fw-500 ">
                      Resource cost
                    </div>
                    <div className="dash-md-title dash-font-0-9em ad-dgray-text fw-600">
                      <Currency />{salary_per_month_format}/month
                    </div>
                  </div>
                </>
                : ''
            }


            {onboarding_date && 
            <div className="col-md-6 mt-2 mb-2">
              <div className="dash-md-title dash-font-0-9em ad-gray-text fw-500 ">
                Onboarding date
              </div>
              <div className="dash-md-title dash-font-0-9em ad-dgray-text fw-600">
                {onboarding_date}
              </div>
            </div>
            }


            {internal_rate_format && !is_outsource_talent ? <div className="col-md-6 mt-2 mb-2">
              <div className="dash-md-title dash-font-0-9em ad-gray-text fw-500 ">
                Internal hourly rate
              </div>
              <div className="dash-md-title dash-font-0-9em ad-dgray-text fw-600">
                <Currency />
                {internal_rate_format}
              </div>
            </div> : <></>}
            {
              (client_project_hour || monthly_working_hours) ?
              <div className="col-md-6 mt-2 mb-2">
                <div className="dash-md-title dash-font-0-9em ad-gray-text fw-500">
                  Working hours
                </div>
                <div className="dash-md-title dash-font-0-9em ad-dgray-text fw-600">
                  {user.is_invite_client === "true" ? `${client_project_hour || '-'} hrs` :
                    `${monthly_working_hours || '-'} hrs`}
                </div>
              </div>
              :
              <></>
            }
            {
             
               (rate_per_hour || talentNetworkCost) &&
              <div className="col-md-6 mt-2 mb-2">
                <div className="dash-md-title dash-font-0-9em ad-gray-text fw-500">
                  External hourly rate
                </div>
                <div className="dash-md-title dash-font-0-9em ad-dgray-text fw-600">
                <Currency />{isTeamDetail && outsourcing_rate ?  outsourcing_rate  :  ((talentNetworkCost ? talentNetworkCost : outsourcing_rate) ? (talentNetworkCost ? talentNetworkCost :  outsourcing_rate) : rate_per_hour)}
                </div>
              </div>
            }
            {
              contractDuration &&
              <div className="col-md-6 mt-2 mb-2">
                <div className="dash-md-title dash-font-0-9em ad-gray-text fw-500">
                  Contract Duration
                </div>
                <div className="dash-md-title dash-font-0-9em ad-dgray-text fw-600">
                {parseInt(contractDuration)} {contractDuration ? contractDuration > 1 ? 'months' : 'month' : '-'}

                </div>
              </div>
            }
            {
              talent_reserved_hours_details && talent_reserved_hours_details.length != 0 && rate_per_hour &&
              <div className="col-md-6">
                {/* <div className="dash-md-title dash-font-0-9em ad-gray-text fw-500 mt-4">
                  Estimated Billing
                </div>
                <div className="dash-md-title dash-font-0-9em ad-dgray-text fw-600">
                <Currency />
                {talent_reserved_hours_details.filter((v)=>v.id == user.id)[0]?.reserved_hours ? 
                (talent_reserved_hours_details.filter((v)=>v.id == user.id)[0]?.reserved_hours * rate_per_hour).toFixed(2)
                :
                0}
                </div> */}
              </div>
            }
          </div>
        </div>

        
        <Box className="ad-teamr-box3" sx={{height:"auto"}}>
          {/* {jira_details && jira_details.length > 0 ? (
            <>
              <div className="dash-md-title dash-black-text fw-600 dash-font-1em">
                All projects
              </div>
              {jira_details.map((project) => (
                <ul className="ad-project-list mt-2" key={project.id}>
                 
                  <li>
                    <div className="ad-project-card">
                      <div className="ad-pc-top d-flex align-items-center justify-content-between">
                        <div className="adpct-left">
                          <div className="ad-project-logo position-relative">
                            {project.project_logo ?
                            
                              <div className="project-short-name">
                                <img src={project.project_logo} alt={project.project_name} />
                              </div>
                               :
                              <div className="project-short-name">{project.project_short_name}</div>
                            }
                          </div>
                          <div className="dash-md-title dash-black-text fw-600 dash-font-1em mt-1 pe-3">
                            {project.project_name || ''}
                          </div>
                        </div>
                        <div className="adpct-right d-flex align-items-center">
                          <div className="adpct-right-list">
                            <ul>
                              <li>
                                <span>Reserved</span>
                                <span className="adpct-hr">{project.reserved_hours || 0} hrs</span>
                              </li>
                              <li className="orange-dot">
                                <span>Planned</span>
                                <span className="adpct-hr">{project.planned_hours || 0} hrs</span>
                              </li>
                              <li className="green-dot">
                                <span>Used</span>
                                <span className="adpct-hr">{project.used_hours || 0} hrs</span>
                              </li>
                            </ul>
                          </div>
                          <div>
                            <VictoryPie
                              padAngle={0}
                              innerRadius={35}
                              radius={26}
                              height={70}
                              width={70}
                              colorScale={["#24BFA8", "#EDB62E", "#DCDCDC"]}
                              data={[
                                { x: 1, y: project.used_hours || 0 },
                                { x: project.used_hours || 0, y: project.planned_hours || 0 },
                                { x: project.planned_hours || 0, y: project.reserved_hours || 0 }
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                      {project.project_pm_tool ?
                        <div className="ad-pc-bottom">
                          <div className="d-flex align-items-center">
                            <div className="me-1">
                              <img src={jiraLogo} alt="timesheet" />
                            </div>
                          </div>
                        </div> : ""}
                    </div>
                  </li>
                </ul>
              ))}
            </>
          ) : (
            ""
          )} */}
          {skills && skills.length > 0 ?
            <div className="mt-3">
              <div className="dash-md-title dash-black-text fw-600 dash-font-1em">
                Top Skills
              </div>
              <div className="dash-skill-list mt-6">
                {skills &&
                  skills.map((val) => (
                    <div key={val.value} className="ds-tag">
                      {val.label}
                    </div>
                  ))}
              </div>
            </div> : ""
          }
          {other_skills && other_skills.length > 0 ?
            <div className="mt-3">
              <div className="dash-md-title dash-black-text fw-600 dash-font-1em">
                Other Skills
              </div>
              <div className="dash-skill-list mt-6">
                {other_skills &&
                  other_skills.map((val) => (
                    <div key={val.value} className="ds-tag">
                      {val.label}
                    </div>
                  ))}
              </div>
            </div> : ""
          }
        </Box>
        {
          portfolio && portfolio?.length !=0 &&
          <Box className="ad-teamr-box3" sx={{ height: "auto" }}>
            <div className="mt-3">
              <div className="dash-md-title dash-black-text fw-600 dash-font-1em">
              Project Portfolio
              </div>
              <div className="dash-skill-list mt-6">
                {portfolio && <TalentPortfolio portfolio={portfolio} setPortfolioDtl={setPortfolioDtl} setPortfolioModalOpen={setPortfolioModalOpen} />}
              </div>
            </div>
            <PortfolioModal portfolioModalOpen={portfolioModalOpen} reqPortfolioModalClose={reqPortfolioModalClose} portfolioDtl={portfolioDtl} />
          </Box>
        }
        {
          certification && certification?.length !=0 &&
          <Box className="ad-teamr-box3" sx={{ height: "auto" }}>
            <div className="mt-3">
              <div className="dash-md-title dash-black-text fw-600 dash-font-1em">
                Certification
              </div>
              <div className="dash-skill-list mt-6">
                {certification && <TalentCertificate certification={certification} />}
              </div>
            </div>
          </Box>
        }
      </Box>
    </>
  );
};

export default TeamDetails;
