import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import projectLogo from "../../assets/agency/img/adove.png";
import jiraLogo from "../../assets/dashboard/img/jira_inline_logo_svg.svg";
import { Box, SvgIcon, Tooltip, useTheme } from "@mui/material";
import { useApiService } from "../../Services/ApiInstance";
import { toast, ToastContainer } from "react-toastify";
import AgencySmModal from "../Modals/AgencySmModal";
import { ReactComponent as Disconnect } from "../../assets/dashboard/img/disconnect-dynamic.svg";
import asanaLogo from "../../assets/dashboard/img/asana_inline_logo_svg.svg";
import {Typography} from "@mui/material";

const ProjectItem = ({
  editProjectModal,
  item,
  deleteProjectModal,
  openJiraConnectModal,
  openBillingCustomerInfo,
  openEditBillingCustomerInfo,
  reload,
}) => {
  const {
    id,
    project_logo,
    team_size,
    project_name,
    project_status,
    teams,
    client_name,
    client_company_name,
    short_name,
    color_code,
    pm_tools,
  } = item;

  console.log("item-->", item);
  const theme = useTheme();
  const navigate = useNavigate();
  let user = JSON.parse(localStorage.getItem("user"));
  const { postRequest, DISCONNECT_JIRA, DISCONNECT_ASANA } = useApiService();
  const [applyModal, setApplyModal] = useState(false);

  const shortName = () => {
    if (project_name.includes(" ")) {
      let arr = project_name.split(" ");
      let first = arr[0].charAt(0).toUpperCase();
      let last = arr[arr.length - 1].charAt(0).toUpperCase();
      return `${first}${last}`;
    } else {
      let first = project_name.charAt(0).toUpperCase();
      let last = project_name.charAt(project_name.length - 1).toUpperCase();
      return `${first}${last}`;
    }
  };

  const [firstname, lastname] = client_name.split(" ");

  console.log(project_name);

  const disconnectJira = (projectId) => {
    postRequest(DISCONNECT_JIRA + projectId)
      .then((result) => {
        console.log(result);
        toast.success(result.data.message);
        reload();
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const disconnectAsana = (projectId) => {
    postRequest(DISCONNECT_ASANA + projectId)
      .then((result) => {
        console.log(result);
        toast.success(result.data.message);
        reload();
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const navigateToDetails = ()=>{
    navigate(`project-details/${id}`, { state: { toolType:  pm_tools ? pm_tools == "jira"?"jira":"asana":"none" } });
  };

  return (
    <>
      {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
      
      <Box
        sx={{cursor:"pointer"}}
        className="adts-box project-box d-flex align-items-center justify-content-between mb-10"
        key={id}
        onClick={(e)=>{
          navigateToDetails();
        }}
      >
        <Box className="adts-col-info col1" sx={{display:"flex",alignItems:"center"}}>
          {/* <Link
            className="d-flex align-items-center"
            to={`project-details/${id}`}
          > */}
            <Box
              className="dash-hc-img ad-sm-hc ad-project-circle"
              sx={{
                background: theme.palette.common.white,
                border: `1px solid ${theme.palette.primary.main}`,
              }}
            >
              {project_logo ? (
                <img src={project_logo} alt={project_name} />
              ) : (
                <span className="talent-short-name">{shortName() || "-"}</span>
              )}
              {/* <span className="dash-hc-play-icon"></span> */}
            </Box>
            <div className="dash-hc-info">
              <div className="dash-md-title dash-black-text fw-600 dash-font-0-9em pe-3">
                {project_name || "-"}
              </div>
            </div>
          {/* </Link> */}
        </Box>
        <div className="col3">
          {/* <Link className="d-block" to={`project-details/${id}`}> */}
            <div className="dash-md-title dash-font-0-8em text-wrap">
              Client name
            </div>
            <div className="common-label mt-1-5 text-type2">
              {client_company_name || <div className="common-label text-type2 ml-20">NA</div>}
            </div>
          {/* </Link> */}
        </div>
        <div className="col4">
          {/* <Link className="d-block" to={`project-details/${id}`}> */}
            <div className="dash-md-title dash-font-0-8em text-wrap">
              Status
            </div>
            <div className="mt-1">
              <span className={`employment-type-tag ${project_status}-tag`}>
                {project_status}
              </span>
            </div>
          {/* </Link> */}
        </div>
        <div className="col2">
          {/* <Link className="d-block" to={`project-details/${id}`}> */}
            <div className="dash-md-title dash-font-0-8em text-wrap">
              Team size
            </div>
            <div className="common-label mt-1-5 text-type2">
              {team_size || <Typography variant="size3SemiboldTextText1">N/A</Typography>}
            </div>
          {/* </Link> */}
        </div>
        <div className="col5">
          <div className="dash-md-title dash-font-0-8em text-wrap">
            Project management
          </div>

          {/* <div className="mt-1 pmt-icon">
            {pm_tools ? (
              <Box>
                <img src={jiraLogo} alt="JiraLogo" />
                <Tooltip
                  slotProps={{
                    popper: { className: "tooltipPrimMain" },
                  }}
                  title="Disconnect Jira"
                >
                  <SvgIcon
                    onClick={() => {
                      setApplyModal(true);
                    }}
                    sx={{ color: "text.text2", ml: "20px" }}
                    component={Disconnect}
                    inheritViewBox
                  />
                </Tooltip>
              </Box>
            ) : (
              <button
                className="ad-theme-btn ad-sm-btn ad-orange-btn connect-jira-btn"
                type="button"
                onClick={() => openJiraConnectModal(item.id)}
              >
                Configure Jira
              </button>
            )}
          </div> */}

          {pm_tools ? (
            <Box className="mt-1 pmt-icon" display="flex" alignItems="center">
              <Box>
                {
                  pm_tools == "jira" ?
                    <Box component="img" sx={{mb:"4px"}} src={jiraLogo} alt="Jira Logo" />
                    :
                    <img src={asanaLogo} alt="Asana Logo" />
                }
                
                {
                  user.is_invite_client != "true" &&
                <Tooltip
                  slotProps={{
                    popper: { className: "tooltipPrimMain" },
                  }}
                  title={`Disconnect ${pm_tools == "jira"?"Jira":"Asana"}`}
                >
                  <SvgIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setApplyModal(true);
                    }}
                    sx={{ color: "text.text2", ml: "20px" }}
                    component={Disconnect}
                    inheritViewBox
                  />
                </Tooltip>
                }
              </Box>
            </Box>
          ) : user.is_invite_client == "true" ? (
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",margin:"0px",fontSize:"14px",fontWeight:"600px",color:"#575757"}}>N/A</div>
          ) : (
            <div className="mt-1 pmt-icon">
              <button
                className="ad-theme-btn ad-sm-btn ad-orange-btn connect-jira-btn"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  openJiraConnectModal(item.id)
                }}
              >
                Configure
              </button>
            </div>
          )}
        </div>
        {/* {
                    pm_tools &&
                    <div className="col5">
                        <div className="mt-1 pmt-icon">
                            <button className='ad-theme-btn ad-sm-btn ad-orange-btn connect-jira-btn' type='button' onClick={() => { setApplyModal(true) }}>Disconnect Jira</button>
                        </div>
                    </div>
                } */}
        <AgencySmModal
          open={applyModal}
          close={(e) => {
            e.stopPropagation();
            setApplyModal(false);
          }}
        >
          {/* <div className="text-center">
                        <img src={upgradeIcon} alt="upgrade" />
                    </div> */}
          <div className="mt-3 ad-md-title text-center">Disconnect {pm_tools == "jira"? "Jira":"Asana"}</div>
          <div className="mt-2 content text-center">
            <p className="ad-lgray-text">
              Are you sure you want to disconnect {pm_tools == "jira"? "Jira":"Asana"} from this project?.
            </p>
          </div>
          <div className="mt-3 text-center row px-3">
            <div className="col-6">
              <button
                type="button"
                className="ad-gray-btn ad-sm-btn w-100"
                onClick={(e) => {
                  e.stopPropagation();
                  setApplyModal(false);
                }}
              >
                Cancel
              </button>
            </div>
            <div className="col-6">
              <button
                type="button"
                className="ad-gray-btn ad-sm-btn w-100"
                onClick={(e) => {
                  e.stopPropagation();
                  setApplyModal(false);
                  if(pm_tools == "jira"){
                    disconnectJira(item.id);
                  }else{
                    disconnectAsana(item.id);
                  }
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </AgencySmModal>
        <div className="col6 text-end">
        <div className="btn-group ad-custom-dropdown dropstart">
          <button
            type="button"
            onClick={(e) => e.stopPropagation()}
            className="btn ad-dropdown-menu dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="addp-menu-icon"></span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end">
            <li>
              <Link className="dropdown-item" to={`project-details/${id}`}>
                View Details
              </Link>
            </li>
            {user.is_invite_client !== "true" && !user.is_invite_team_member && (
              <>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      const dropdownMenu = e.target.closest('.dropdown-menu');
                      const dropdown = bootstrap.Dropdown.getInstance(dropdownMenu.previousElementSibling);
                      dropdown.hide(); 

                      editProjectModal(item);
                    }}
                  >
                    Edit
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      const dropdownMenu = e.target.closest('.dropdown-menu');
                      const dropdown = bootstrap.Dropdown.getInstance(dropdownMenu.previousElementSibling);
                      dropdown.hide(); 

                      deleteProjectModal(item);
                    }}
                  >
                    Delete
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>

      </Box>
    </>
  );
};

export default ProjectItem;
